import { Directive, ElementRef, Input, inject } from '@angular/core';
import { fromEvent } from 'rxjs';
import { UXService } from '../services/ux.service';

@Directive({ standalone: true, selector: '[copy]' })
export class CopyDirective {
  ux = inject(UXService);

  @Input()
  copy: string = '';

  constructor(private el: ElementRef<HTMLElement>) {}

  ngOnInit() {
    fromEvent(this.el.nativeElement, 'click').subscribe((e) => {
      if (!this.copy) return;

      e.stopImmediatePropagation();
      navigator?.clipboard?.writeText(this.copy);
      const title = 'Copiado';
      const message = 'El texto ha sido copiado al portapapeles';
      this.ux.notifier.info(message, title);
    });
  }
}
