import { Directionality } from '@angular/cdk/bidi';
import { CdkStepper, CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  imports: [CommonModule, CdkStepperModule],
  providers: [{ provide: CdkStepper, useExisting: StepperComponent }]
})
export class StepperComponent extends CdkStepper {
  constructor(
    protected dir: Directionality,
    protected detector: ChangeDetectorRef,
    protected el: ElementRef
  ) {
    super(dir, detector, el);
  }
}
