import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { UXService } from 'src/app/ux/services/ux.service';
import { Comment } from '../models/comment';
import { Attachment } from '../models/attachment';
import { toBase64 } from '../helpers/files-helper';

@Injectable({ providedIn: 'root' })
export class CommentsService {
  ux = inject(UXService);
  http = inject(HttpClient);

  create(payload: Comment) {
    let body = payload.toRequest();
    return this.http
      .post('//communications/comments', body)
      .pipe(map((data) => new Comment(data)));
  }

  one(id: number | string) {
    return this.http.get(`//communications/comments/${id}`).pipe(map((data) => new Comment(data)));
  }

  update(id: number | string, payload: Comment) {
    let body = payload.toRequest();
    return this.http
      .post(`//communications/comments/${id}`, body)
      .pipe(map((data) => new Comment(data)));
  }

  delete(id: number | string) {
    return this.ux.confirm(`¿Está seguro de eliminar el comentario?`).pipe(
      filter((result) => !!result),
      mergeMap(() => this.http.delete(`//communications/comments/${id}`)),
      tap({
        next: () => this.ux.notifier.success('¡Comentario eliminado!'),
        error: () => this.ux.notifier.error('¡Error al eliminar el comentario!')
      })
    );
  }

  list(search = '', ticketId?: string | number, parentId?: string | number) {
    const params: { search: string; ticketId?: string | number; parentId?: string | number } = { search, ticketId };
    if (parentId !== undefined) params.parentId = parentId;

    const filter = (comment: Comment) => {
      const { ticketId: oticket } = comment;
      let success = true;
      if (ticketId) success &&= ticketId == oticket;
      if (search) {
        const sanitized = search.replace(/[#-.]|[[-^]|[?|{}]/g, '\\$&');
        const regexp = RegExp(`.*${sanitized}.*`, 'igm');
        success &&= !!JSON.stringify(comment.message).match(regexp);
      }

      return success;
    };

    return this.http
      .get<Comment[]>('//communications/comments', { params })
      .pipe(map((data) => data?.map((c) => new Comment(c))?.filter(filter)));
  }

  propagate(id: number | string) {
    return this.http.post(`//communications/comments/${id}/propagate`, {});
  }
}
