import { formatDate } from '@angular/common';
import { Component, OnInit, Optional } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DialogRef } from '@mukhuve/ngx/dialog';

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss']
})
export class DateRangeComponent implements OnInit {
  range = new UntypedFormGroup({
    from: new UntypedFormControl(null, Validators.required),
    to: new UntypedFormControl(null, Validators.required),
  });
  constructor(
    @Optional() public ref?: DialogRef
  ) { }

  ngOnInit(): void {
  }

  selectDates = () => { if( this.range.valid ) {
    const { from, to } = this.range.value
    this.ref.close({
      from: formatDate( from, 'yyyy-MM-dd','en-US' ),
      to: formatDate( to, 'yyyy-MM-dd', 'en-US' ),
    })
  } }

} 
