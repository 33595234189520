import { Tenant } from '@admin/models/tenant';
import { TenantService } from '@admin/services/tenant.service';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DialogService } from '@mukhuve/ngx/dialog';
import { AccommodationsService } from '@pms/services/accommodations.service';
import { filter, map, mergeMap } from 'rxjs';
import { MultiSelectComponent } from 'src/app/shared/components/multi-select/multi-select.component';

@Component({
  selector: 'app-tenant-card',
  standalone: true,
  imports: [CommonModule, MatTooltipModule],
  templateUrl: './tenant-card.component.html',
  styleUrls: ['./tenant-card.component.scss']
})
export class TenantCardComponent {
  #service = inject(TenantService);
  #accommodations = inject(AccommodationsService);
  #dialog = inject(DialogService);

  @Input()
  data!: Tenant;

  @Output()
  changed = new EventEmitter();

  edit() {
    this.#service.form(this.data).subscribe();
  }

  seed() {
    this.#dialog
      .open(MultiSelectComponent, {
        classes: 'without-min',
        inputs: {
          options: [
            { label: 'Roles', value: 'roles' },
            { label: 'Usuarios', value: 'users' },
            { label: 'Stats de usuarios', value: 'user_stats' },
            { label: 'Packs', value: 'packs' },
            { label: 'Tags', value: 'tags' },
            { label: 'Acciones', value: 'actions' },
            { label: 'Checklists', value: 'checklists' }
          ],
          submitLabel: 'Sembrar',
          submitIcon: 'ri-seedling-line',
          controlType: 'checkbox'
        }
      })
      .events.pipe(
        filter((e) => e.type === 'beforeclose' && e.data),
        map((event) => {
          const tables = event.data as string[];
          if (tables?.includes('roles')) tables.push('base_stats');
          if (tables?.includes('base_stats') || tables?.includes('user_stats'))
            tables.push('stats');
          if (tables?.includes('checklists')) tables.push('tasks');
          if (tables?.includes('actions')) tables.push('actions_checklists');

          return tables;
        }),
        mergeMap((tables) => this.#service.seed(this.data.id, tables))
      )
      .subscribe((r) => this.changed.emit(r));
  }

  accommodations() {
    this.#accommodations.list(true).subscribe((accommodations) => {
      const config = this.data.parentConfig;
      const values = config?.accommodations || [];
      const options = accommodations.map(({ name, id }) => ({
        label: name,
        value: id
      }));

      this.#dialog
        .open(MultiSelectComponent, { inputs: { options, values } })
        .events.pipe(
          filter((e) => e.type === 'beforeclose' && e.data),
          mergeMap((e) =>
            this.#service.config(this.data?.id, { accommodations: e.data })
          )
        )
        .subscribe((r) => this.changed.emit(r));
    });
  }
}
