<form class="form" [formGroup]="form" *ngIf="form" autocomplete="off">
  <h4 class="text-title">Actualizar mis datos</h4>
  <div class="form__control cursor-pointer image" (click)="image.click()">
    <img
      class="rounded-full w-full h-full object-cover"
      onerror="this.src='/assets/images/default-profile.jpg';this.onerror=null;"
      [src]="preview"
    />
    <input class="input" #image type="file" hidden (change)="updateImage($event.target)" />
    <i class="ri-camera-2-line rounded-full w-8 h-8 flex items-center justify-center"></i>
  </div>
  <div>
    <div class="form__control">
      <label> Nombre </label>
      <input class="input" type="text" formControlName="name" />
    </div>
    <div class="form__control">
      <label> Apellido </label>
      <input class="input" type="text" formControlName="surname" />
    </div>
  </div>
  <div>
    <div class="form__control">
      <label> Identificación </label>
      <input class="input" type="text" formControlName="dni" />
    </div>
    <div class="form__control">
      <label> Telefono </label>
      <input class="input" type="text" formControlName="phone" />
    </div>
  </div>
  <div class="form__actions">
    <button type="button" class="color-neutral-200" (click)="ref?.close()">Cancelar</button>
    <button type="submit" class="color-primary" (click)="submit()">Guardar</button>
  </div>
</form>
