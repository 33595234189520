import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { JoinmeComponent } from './components/joinme/joinme.component';
import { AuthGuard } from './guards/auth.guard';
import { GateGuard } from './guards/gate.guard';
import { ApiInterceptor } from './interceptors/api.interceptor';
import { CachingInterceptor } from './interceptors/caching.interceptor';
import { HeadersInterceptor } from './interceptors/headers.interceptor';
import { BridgeComponent } from './pages/bridge/bridge.component';
import { AuthService } from './services/auth.service';
import { ParametersService } from './services/parameters.service';

const PROVIDERS = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ApiInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HeadersInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: CachingInterceptor,
    multi: true
  },
  AuthService,
  AuthGuard,
  GateGuard,
  ParametersService
];

@NgModule({
  declarations: [BridgeComponent, JoinmeComponent],
  providers: PROVIDERS,
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    SharedModule,
    HttpClientModule,
    RouterModule
  ],
  exports: [BridgeComponent]
})
export class CoreModule {}
