<section class="flex flex-row flex-wrap items-center justify-between gap-2">
  <ng-content select="[header]"></ng-content>
  <span class="text-force-ellipsis">
    <ng-content select="[title]"></ng-content>
  </span>
  <span class="">
    <ng-content select="[btns]"></ng-content>
  </span>
</section>
<section *ngIf="mapper" class="flex flex-col items-stretch justify-start gap-2">
  <div
    *ngFor="let map of mapper | order; trackBy: trackByFn"
    class="json-{{ map.key }} json-prop grid grid-cols-2 gap-2 text-sm whitespace-nowrap"
  >
    <strong class="text-primary flex flex-row items-center gap-2">
      <i class="{{ map.icon || 'ri-information-line' }} font-light" [style.color]="map.color"></i>
      {{ map.title }}
    </strong>
    <span class="text-neutral-400 flex items-center" [copy]="data[map.key]">
      {{ data[map.key] || '--' }}
    </span>
  </div>
</section>
