import {
  Component,
  ElementRef,
  forwardRef,
  inject,
  Input,
  OnInit
} from '@angular/core';
import {
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormGroup
} from '@angular/forms';
import { AppControl } from 'src/app/ux/models/control';

@Component({
  selector: 'app-list-input',
  templateUrl: './list-input.component.html',
  styleUrls: ['./list-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ListInputComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ListInputComponent),
      multi: true
    }
  ]
})
export class ListInputComponent extends AppControl<object[]> implements OnInit {
  fb = inject(UntypedFormBuilder);
  elRef = inject(ElementRef);

  @Input()
  config: any[] = [];

  index: number;
  inputs: UntypedFormGroup;
  _list: object[] = [];

  ngOnInit() {
    const controls = [];
    for (const field of this.config) {
      controls[field.key] = '';
    }

    this.inputs = this.fb.group(controls);
  }

  add() {
    if (this.inputs.invalid) return;
    const values = this.inputs.value;

    if (this.index) {
      const replacer = (_, i) => (i === this.index ? values : _);
      this._list = this._list.map(replacer);
    } else this._list.push(values);

    this.value = this._list;
    this.inputs.reset();
  }

  update(index: number) {
    this.index = index;
    const item = this._list.find((_, i) => i === index);
    this.inputs = this.fb.group(item);
  }

  remove(index: number | 'all') {
    if (typeof index === 'string' && index === 'all') this._list = [];
    else this._list = this._list.filter((item, i) => i !== index);
    this.writeValue(this._list);
  }
}
