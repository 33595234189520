export enum Colors {
  base = '#ffffff',
  neutral = '#282b30',
  neutral200 = '#f4f6f6',
  neutral400 = '#5a616c',
  primary = '#005154',
  secondary = '#4d8587',
  accent = '#e6b873',
  error = '#f8495a',
  warn = '#fcf282',
  info = '#0597e1',
  success = '#1fa88d'
}

export type ColorKey = keyof typeof Colors;

export function autoTextColor(bgColor = '#282b30') {
  if (!bgColor || bgColor === '') bgColor = '#282b30';
  if (bgColor === 'dead') return '#333333';
  const color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;
  const r = parseInt(color.substring(0, 2), 16); // hexToR
  const g = parseInt(color.substring(2, 4), 16); // hexToG
  const b = parseInt(color.substring(4, 6), 16); // hexToB

  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#282b30' : '#ffffff';
}
