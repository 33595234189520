import {
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2
} from '@angular/core';
import { createPopperLite, preventOverflow } from '@popperjs/core';
import flip from '@popperjs/core/lib/modifiers/flip';
import { createPopper, Instance } from '@popperjs/core/lib/popper-lite';

@Directive({ selector: '[popper]' })
export class PopperDirective implements OnInit, OnDestroy {
  @Input('popper') source: HTMLElement;
  @Input() popperConfig: string | any;

  triggers: Map<string, [string[], string[]]> = new Map();
  element: HTMLElement;
  popper: Instance;

  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.triggers.set('hover', [['mouseenter'], ['mouseleave', 'mouseover']]);
  }

  ngOnInit(): void {
    this.element = this.el.nativeElement;
    this.renderer.addClass(this.source, 'popper');

    this.create();
  }

  ngOnDestroy() {
    this.popper.destroy();
  }

  create() {
    this.popper = createPopperLite(this.element, this.source, {
      placement: 'auto',
      removeOnDestroy: true,
      modifiers: [flip, preventOverflow],
      strategy: 'fixed',
      ...(this.popperConfig || {})
    });
    this.triggers.forEach(([up, down]) => {
      up.forEach((e) => this.element.addEventListener(e, () => this.show()));
      down.forEach((e) => this.element.addEventListener(e, () => this.hide()));
    });
    this.hide();
  }

  show() {
    this.element.setAttribute('data-show', '');
    this.renderer.removeStyle(this.source, 'display');

    this.popper.update();
  }

  hide() {
    this.renderer.setStyle(this.source, 'display', 'none');
    this.element.removeAttribute('data-show');
  }
}
