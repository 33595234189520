import { uuidv4 } from '@core/helpers/crypto';

export class FormControl {
  readonly id: string;
  type: 'text' | 'number' | 'select' | 'file';
  title: string;
  placeholder?: string;
  default?: any;
  options?: string[];

  constructor(data?: Partial<FormControl | any>) {
    this.id = data?.id || uuidv4();
    this.type = data?.type || 'text';
    this.title = data?.title || 'Nombre del campo';
    this.placeholder = data?.placeholder;
  }

  addOption(option: string) {
    if (this.type !== 'select' || this.options?.includes(option)) {
      return;
    }

    this.options = this.options || [];
    this.options.push(option);
  }

  removeOption(option: string) {
    if (this.type !== 'select') {
      return;
    }

    this.options = this.options?.filter((item) => item !== option);
  }
}
