import { Component, Input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-static-map',
  templateUrl: './static-map.component.html',
  styleUrls: ['./static-map.component.scss']
})
export class StaticMapComponent {
  @Input() latlng: Record<'lat' | 'lng', number>;
  @Input() zoom: number = 12;
  @Input() size: string = '500x300';

  constructor() {}

  get src() {
    if (!this.latlng) return '';
    const url = 'https://maps.googleapis.com/maps/api/staticmap';
    const center = `${this.latlng.lat},${this.latlng.lng}`;
    const params = new URLSearchParams({
      center,
      size: this.size,
      zoom: `${this.zoom}`,
      key: 'AIzaSyCSTPzKoswAw5t9q5cRWE1RJoFyiHCN5QA',
      markers: `color:red|label:S|${center}`
    });
    return `${url}?${params.toString()}`;
  }

  get googleUrl() {
    if (!this.latlng) return '';
    const url = `https://www.google.com/maps/search/?api=1&query=${this.latlng.lat},${this.latlng.lng}`;
    return url;
  }
}
