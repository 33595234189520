@for (task of value; track task.id) {
  <div class="checklist-task">
    <label
      class="task flex flex-row items-center gap-2 decoration-primary decoration-double"
      [class.line-through]="task.done"
    >
      <input
        class="checkbox checkbox-sm checkbox-primary"
        type="checkbox"
        checked="checked"
        [disabled]="disabled || editable"
        [(ngModel)]="task.done"
        (ngModelChange)="check.emit(task)"
      />
    </label>
    <span
      class="flex-1"
      [attr.contenteditable]="editable && !(task.done && task.evidence)"
      [autofocus]="!task?.id"
      [innerText]="task.task_title"
      (keydown.arrowUp)="move($event, 'prev')"
      (keydown.arrowDown)="move($event, 'next')"
      (keydown.enter)="save($event, task)"
      (blur)="save($event, task)"
    >
      {{ task.task_title }}
    </span>
    @if (editable && !(task.done && task.evidence)) {
      <div class="inline-flex flex-row gap-2 ml-auto">
        @if (!task.done) {
          <label
            class="swap swap-flip !m-0 hover:text-primary"
            [matTooltip]="task.evidence ? 'Con evidencia' : 'Sin evidencia'"
          >
            <input
              type="checkbox"
              checked="checked"
              [(ngModel)]="task.evidence"
              (ngModelChange)="updated.emit(task)"
            />
            <div class="swap-on">
              <i class="ri-file-shield-2-line text-primary"></i>
            </div>
            <div class="swap-off">
              <i class="ri-file-warning-line"></i>
            </div>
          </label>
        }
        <i
          matTooltip="Eliminar"
          class="ri-delete-bin-2-line text-error cursor-pointer text-xl leading-none"
          (click)="remove(task)"
        ></i>
      </div>
    }
  </div>
}

<!-- @if (editable) {
  <div class="flex flex-row items-center justify-between my-2">
    <form #form class="flex flex-row items-stretch gap-2" (submit)="add(input.value); form.reset()">
      <input #input name="title" class="input input-sm flex-1" />
      <button type="submit" class="btn btn-icon bg-transparent">
        <i class="ri-add-line text-primary !font-normal !text-xl"></i>
      </button>
    </form>
  </div>
} -->
