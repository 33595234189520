import { CommonModule } from '@angular/common';
import { Component, HostListener, Renderer2 } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterModule } from '@angular/router';
import { SearchComponent } from '@core/components/search/search.component';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from './core/services/app.service';
import { AuthService } from './core/services/auth.service';
import { NavComponent } from './shared/components/nav/nav.component';

@Component({
  standalone: true,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [CommonModule, RouterModule, NavComponent, SearchComponent]
})
export class AppComponent {
  theme = 'default';
  search = false;
  synchronizing: boolean = false;

  constructor(
    public auth: AuthService,
    private render: Renderer2,
    private translate: TranslateService,
    private app: AppService
  ) {
    this.translate.setDefaultLang('es');
    this.render.addClass(document.body, `theme-${this.theme}`);
    this.app
      .on('app:search')
      .pipe(takeUntilDestroyed())
      .subscribe((e) => (this.search = e?.payload));
  }

  @HostListener('document:keydown', ['$event'])
  onSearch(event: KeyboardEvent) {
    const { key, ctrlKey } = event;
    const valid = (ctrlKey && key === 's') || (this.search && key === 'Escape');

    if (!valid) return;

    event.preventDefault();
    event.stopImmediatePropagation();
    if (key === 'Escape') this.app.emit('app:search', false);
    if (key === 's') this.app.emit('app:search', !this.search);
  }
}
