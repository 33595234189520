import { NgIf } from '@angular/common';
import {
  Component,
  DestroyRef,
  ElementRef,
  HostBinding,
  inject,
  input,
  OnInit,
  output,
  signal
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlContainer } from '@angular/forms';
import { filter, fromEvent } from 'rxjs';

@Component({
  standalone: true,
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  host: { class: 'btn' },
  imports: [NgIf]
})
export class ButtonComponent implements OnInit {
  #ref = inject(ElementRef);
  #destroyRef = inject(DestroyRef);
  #container = inject(ControlContainer);

  click = output<MouseEvent>();
  type = input<'button' | 'submit' | 'reset'>('button');
  block = input<boolean>(false);
  disabled = input<boolean>(false);
  pending = signal<boolean>(false);

  @HostBinding('attr.inert')
  @HostBinding('attr.disabled')
  get inert() {
    return this.disabled() ? '' : null;
  }

  constructor() {
    this.#ref.nativeElement.addEventListener('click', (e: MouseEvent) => this.onClick(e));
  }

  ngOnInit() {
    const form = this.#ref.nativeElement.closest('form') as HTMLFormElement;
    if (form) {
      fromEvent(form, 'keypress')
        .pipe(
          takeUntilDestroyed(this.#destroyRef),
          filter((e: KeyboardEvent) => e.key === 'Enter' && this.type() === 'submit')
        )
        .subscribe(() => this.onClick());
    }

    this.#container.control.statusChanges
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(() => this.pending.set(this.#container.control.pending));
  }

  onClick(event?: MouseEvent) {
    event?.preventDefault();
    event?.stopPropagation();
    event?.stopImmediatePropagation();

    if (this.pending() && this.block()) {
      return;
    }

    const form = this.#ref.nativeElement.closest('form') as HTMLFormElement;
    if (form) {
      if (this.type() === 'submit') form.requestSubmit();
      if (this.type() === 'reset') form.reset();
      form.checkValidity();
    }

    this.click.emit(event);
  }
}
