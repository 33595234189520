import { Validators } from '@angular/forms';
import { Resource } from '../objects/resource/resource';
import { ResourceRule } from '../objects/rule/rule';

export const Checklist = new Resource({
  key: 'checklist',
  endpoint: '/checklists',
  title: 'Checklists',
  icon: 'ri-checkbox-multiple-line',
  rules: {
    id: new ResourceRule({
      title: 'ID',
      control: { type: 'number' },
      sentences: ['no-form']
    }),
    name: new ResourceRule({
      title: 'Nombre',
      control: { validators: [Validators.required] },
      sentences: ['mandatory']
    }),
    description: new ResourceRule({
      title: 'Descripción',
      control: { type: 'textarea' }
    }),
    tasks: new ResourceRule({
      icon: 'playlist_add_check',
      title: 'Tareas',
      sentences: ['no-list'],
      control: { type: 'checklist' }
    })
  }
});
