import { FlexibleConnectedPositionStrategyOrigin } from '@angular/cdk/overlay';
import { ViewContainerRef } from '@angular/core';

export interface DropdownConfig {
  dir?: 'center' | 'left';
  panelClass?: string;
  container?: ViewContainerRef;
  connectedTo?: FlexibleConnectedPositionStrategyOrigin;
}

export const DropdownPositions = {
  center: [
    {
      originX: 'center',
      originY: 'bottom',
      overlayX: 'center',
      overlayY: 'top'
    },
    {
      originX: 'center',
      originY: 'top',
      overlayX: 'center',
      overlayY: 'bottom'
    }
  ],
  left: [
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top'
    },
    {
      originX: 'start',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'bottom'
    }
  ]
};
