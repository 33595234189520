<h4 *ngIf="title">{{ title }}</h4>
<p *ngIf="message" [innerHTML]="message"></p>
<section class="actions">
  <button
    *ngFor="let action of actions ?? []"
    class="color-{{ action.color || 'neutral-200' }}"
    (click)="submit(action)"
  >
    {{ action.label | titlecase }}
  </button>
</section>
