import { MultiSelectComponent } from '@/shared/components/multi-select/multi-select.component';
import { Option } from '@/ux/directives/option.directive';
import { AppControl } from '@/ux/models/control';
import { JsonPipe, KeyValuePipe } from '@angular/common';
import { Component, Input, OnInit, forwardRef, inject } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { DialogEvent, DialogRef, DialogService } from '@mukhuve/ngx/dialog';
import { filter } from 'rxjs';
import { MinputComponent } from '../minput/minput.component';
import { KeyValueSchema } from './keyvalue-input.types';

@Component({
  selector: 'app-keyvalue-input',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, KeyValuePipe, JsonPipe, MinputComponent, Option],
  templateUrl: './keyvalue-input.component.html',
  styleUrl: './keyvalue-input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => KeyvalueInputComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => KeyvalueInputComponent),
      multi: true
    }
  ]
})
export class KeyvalueInputComponent extends AppControl<Record<string, string>> implements OnInit {
  @Input()
  title?: string;

  @Input()
  schema?: KeyValueSchema;

  @Input()
  override set value(value: Record<string, string> | undefined) {
    super.value = value;
  }

  override get value() {
    return super.value;
  }

  #fb = inject(FormBuilder);
  #dialog = inject(DialogService);
  ref?: DialogRef = inject(DialogRef, { optional: true });

  form = this.#fb.group({
    items: this.#fb.array([], [Validators.required])
  });

  help(control: AbstractControl) {
    const options = Object.entries(this.schema).map(([key, value]) => ({
      label: value.label,
      value: key
    }));
    const inputs = { values: [control.value], multiple: false, options };

    this.#dialog
      .open(MultiSelectComponent, { inputs })
      .events.pipe(filter((e: DialogEvent) => e.type === 'beforeclose' && e.data))
      .subscribe(({ data }) => control.setValue(data));
  }

  get items() {
    return this.form.get('items') as FormArray;
  }

  get controls() {
    return this.items.controls as FormGroup[];
  }

  ngOnInit() {
    const values: Record<string, string> = this.value || {};
    for (const [key, value] of Object.entries(values)) {
      this.add(key, value);
    }
    if (this.items.length === 0) {
      this.add();
    }
  }

  add(key = '', value = '') {
    this.items.push(
      this.#fb.group({
        key: [key, [Validators.pattern(/^[a-z0-9_]+$/), Validators.required]],
        value: [value, [Validators.required]]
      })
    );
  }

  remove(index: number) {
    this.items.removeAt(index);
  }

  submit() {
    const values = this.items.value;
    const parsed = values.reduce((acc: any, item: any) => {
      const itemSchema = this.schema[item.key];
      if (itemSchema?.type === 'number') {
        acc[item.key] = parseFloat(item.value);
      } else {
        acc[item.key] = item.value;
      }

      return acc;
    }, {});

    this.ref?.close(parsed);
  }
}
