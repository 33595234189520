@if (label) {
  <label> {{ label }} </label>
}
@switch (control) {
  @case ('date') {
    <app-date
      [key]="key"
      [range]="false"
      [placeholder]="placeholder"
      [(ngModel)]="value"
    ></app-date>
  }
  @case ('daterange') {
    <app-date [key]="key" [range]="true" [placeholder]="placeholder" [(ngModel)]="value"></app-date>
  }
  @case ('select') {
    <app-select [(ngModel)]="value" [placeholder]="placeholder">
      <app-option
        *ngFor="let option of options || []"
        [value]="option?.value"
        [innerHtml]="option?.label | sanitize: 'html'"
      ></app-option>
    </app-select>
  }
  @case ('checkbox') {
    <label class="flex items-center gap-4 cursor-pointer">
      <input
        type="checkbox"
        checked="checked"
        class="checkbox checkbox-primary"
        [(ngModel)]="value"
      />
      <span class="label-text"> {{ value ? 'Si' : 'No' }}</span>
    </label>
  }
  @case ('textarea') {
    <textarea class="textarea" [placeholder]="placeholder" [(ngModel)]="value"></textarea>
  }
  @case ('file') {
    <input
      class="input file-input"
      [type]="type"
      [placeholder]="placeholder"
      [attr.multiple]="config.multiple"
      [attr.accept]="config.accept"
      (change)="fileChange($event)"
    />
  }
  @case ('switch') {
    <label class="flex items-center gap-4 cursor-pointer">
      <input type="checkbox" checked="checked" class="toggle toggle-primary" [(ngModel)]="value" />
      <span class="label-text"> {{ value ? 'Si' : 'No' }}</span>
    </label>
  }
  @default {
    <input
      class="input"
      [type]="type"
      [value]="value"
      [placeholder]="placeholder"
      [(ngModel)]="value"
    />
  }
}
@if (errors && touched) {
  <div class="errors">
    @if (errors?.required) {
      <span>
        <i class="ri-error-warning-line"></i>
        Este campo es requerido
      </span>
    }
    @if (errors?.minlength) {
      <span>
        <i class="ri-error-warning-line"></i>
        Debe tener al menos {{ errors?.minlength?.requiredLength }} caracteres
      </span>
    }
    @if (errors?.maxlength) {
      <span>
        <i class="ri-error-warning-line"></i>
        Debe tener como máximo {{ errors?.maxlength?.requiredLength }} caracteres
      </span>
    }
    @if (errors?.pattern) {
      <span>
        <i class="ri-error-warning-line"></i>
        El formato no es válido
      </span>
    }
  </div>
}
