import { OnAppInit } from '@/core/interfaces/init.interface';
import { Tenant } from '@admin/models/tenant';
import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { DialogEvent, DialogService } from '@mukhuve/ngx/dialog';
import { Observable, of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
  throwIfEmpty
} from 'rxjs/operators';
import { AppService } from 'src/app/core/services/app.service';
import { MultiSelectComponent } from 'src/app/shared/components/multi-select/multi-select.component';
import { environment } from 'src/environments/environment';
import { domainInfo, responseToInfo } from '../helpers/tenancy.helper';

@Injectable({ providedIn: 'root' })
export class TenancyService implements OnAppInit {
  #app = inject(AppService);
  #http = inject(HttpClient);
  #dialog = inject(DialogService);
  selected?: Tenant;
  config: any = null;

  get tenant() {
    return this.config?.selected ?? this.config?.tenant;
  }

  ngOnAppInit(): Observable<any> {
    return this.info().pipe(
      map((info) => (this.config = info)),
      filter((tenancy) => tenancy?.subdomain === 'admin' || tenancy?.central),
      throwIfEmpty(),
      switchMap(() => this.load()),
      catchError(() => of(this.clear())),
      map(() => this.#app.globals.patch({ tenancy: this.config }))
    );
  }

  list() {
    const headers = { 'app-cache': 'tenants', 'x-tenant-select': 'admin' };

    return this.#http
      .get<any[]>(`//api/tenants`, { headers })
      .pipe(map((tenants) => tenants?.map((t: any) => new Tenant(t))));
  }

  info() {
    const domainInf = domainInfo(environment.domains, environment?.subdomain);
    const headers = { 'x-tenant': domainInf.subdomain, auth: 'none' };

    return this.#http.get<any>('//api/tenant', { headers }).pipe(
      map((r) => responseToInfo(r, domainInf)),
      catchError((r) => of(responseToInfo(r.error, domainInf)))
    );
  }

  load() {
    try {
      const obj = JSON.parse(localStorage.getItem('app:selected')) ?? '-';
      if (obj?.id) {
        return this.select(new Tenant(obj));
      }
    } catch (error) {
      this.clear();
      return of(null);
    }
  }

  select(tenant?: Tenant | '-'): Observable<Tenant | null> {
    if (tenant instanceof Tenant) {
      this.selected = tenant;
      this.config.selected = tenant;
      localStorage.setItem('app:selected', JSON.stringify(tenant));
      this.#app.globals.patch({ tenancy: { select: tenant } });

      return of(tenant).pipe(tap(() => this.#app.emit('page:load')));
    }

    return this.list().pipe(
      map((tenants) =>
        tenants.map((value) => ({ value, label: value?.name || value?.id }))
      ),
      mergeMap((options) => {
        if (tenant === '-') {
          const result = options[0] ?? { value: '' };
          return of(result.value);
        }

        const inputs = { values: [tenant], multiple: false, options };
        return this.#dialog.open(MultiSelectComponent, { inputs }).events.pipe(
          filter((e: DialogEvent) => e.type === 'beforeclose' && e.data),
          map((e: DialogEvent) => e.data)
        );
      }),
      mergeMap((tenant) => this.select(tenant))
    );
  }

  clear() {
    this.selected = null;
    this.config.selected = null;
    localStorage.removeItem('app:selected');
    this.#app.globals.patch({ tenancy: { select: null } });
    this.#app.emit('page:load');
  }
}
