import { ComponentType } from '@angular/cdk/portal';
import { Injectable, Injector, inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class NotifierService {
  injector = inject(Injector);
  #toastr: ToastrService;

  get toastr() {
    if (!this.#toastr) this.#toastr = this.injector.get(ToastrService);
    return this.#toastr;
  }

  error(message: string, title?: string) {
    return this.toastr.error(message, title ?? 'Error');
  }

  info(message: string, title?: string) {
    return this.toastr.info(message, title ?? 'Información');
  }

  warning(message: string, title?: string) {
    return this.toastr.warning(message, title ?? 'Advertencia');
  }

  success(message: string, title?: string) {
    return this.toastr.success(message, title ?? 'Éxito');
  }

  custom(component: ComponentType<any>, inputs?: any) {}

  notify(message: string, config: any = {}) {
    const { type = 'info', title } = config;
    let toast = this[type](message, title);
  }
}
