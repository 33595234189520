@if (ref) {
  <section class="flex items-center gap-2 h-12">
    <button class="btn btn-icon shadow-none bg-transparent md:hidden" (click)="ref?.close()">
      <i class="ri-arrow-left-s-line text-primary !text-3xl"></i>
    </button>
    <h2 class="text-xl font-bold text-primary">{{ title }}</h2>
  </section>
}
<section [formGroup]="form">
  <div
    formArrayName="items"
    class="flex flex-col gap-2 mb-2 overflow-y-auto overflow-x-hidden max-h-[420px] py-2"
  >
    @for (item of controls; track i; let i = $index) {
      <div class="flex items-stretch gap-2" [formGroup]="item">
        <div class="flex flex-col gap-2 flex-1 sm:flex-row">
          <div class="input control">
            <input type="text" class="bg-transparent" formControlName="key" placeholder="Clave" />
            @if (this.schema) {
              <button
                type="button"
                (click)="help(item.get('key'))"
                class="btn btn-icon shadow-none"
              >
                <i class="ri-question-line text-primary"></i>
              </button>
            }
          </div>
          <!-- 
            TODO: use this after update 
            @let itemSchema = schema?.[item.get('key')?.value] || {};  
          -->
          <minput
            [type]="schema?.[item.get('key')?.value]?.type || 'text'"
            formControlName="value"
            placeholder="Valor"
            class="!flex-1 min-w-[12rem]"
          >
            @for (opt of schema?.[item.get('key')?.value]?.options || []; track opt.label) {
              <app-option [value]="opt.value">{{ opt.label }}</app-option>
            }
          </minput>
        </div>
        <button
          type="button"
          (click)="remove(i)"
          class="btn btn-error text-white w-16 h-[6.5rem] sm:w-12 sm:h-12"
        >
          <i class="ri-delete-bin-line"></i>
        </button>
      </div>
      @if (item.invalid && item.touched && item.dirty) {
        <small class="text-xs text-error px-2"> Verifica los campos </small>
      }
    } @empty {
      <div class="flex flex-col gap-2 flex-nowrap py-4">
        <span class="text-center text-base-200">No hay datos</span>
      </div>
    }
    <button type="button" (click)="add()" class="btn btn-primary w-full">
      <i class="ri-add-line"></i>
    </button>
  </div>
</section>
@if (ref) {
  <section class="flex items-center justify-end gap-2 mt-auto">
    <button type="button" (click)="ref.close()" class="btn bg-base-200">
      <i class="ri-close-line"></i>
      <span>Cancelar</span>
    </button>
    <button type="button" [disabled]="form.invalid" (click)="submit()" class="btn btn-primary">
      <i class="ri-save-line"></i>
      <span>Guardar</span>
    </button>
  </section>
}
