<div class="backdrop" (click)="app.emit('app:search', false)"></div>
<div class="search control px-4">
  <input class="input" #input type="text" placeholder="Buscar..." (input)="onInput(input.value)" />
  <i class="ri-search-line"></i>
</div>
<app-list-old class="results" [items]="results$" [hideSearch]="true">
  <ng-template let-ticket="item" let-i="index">
    <app-ticket-li [ticket]="ticket" [style.--index]="i"></app-ticket-li>
  </ng-template>
</app-list-old>
