<form [formGroup]="form" *ngIf="form" autocomplete="off" (keydown.enter)="$event.preventDefault()">
  <h4 class="form__title">
    <ng-container *ngIf="data?.id; else nnew"> Actualizar tenant </ng-container>
    <ng-template #nnew>Crear nuevo tenant</ng-template>
  </h4>
  <div class="form">
    <div
      class="form__control image"
      (click)="image.click()"
      (keyup.enter)="image.click()"
      tabindex="0"
    >
      <img
        alt="Imagen de perfil"
        class="rounded-full w-full h-full object-cover"
        onerror="this.src='/assets/images/tenant.jpg';this.onerror=null;"
        [src]="preview"
      />
      <input #image type="file" hidden (change)="updateImage($event.target)" />
      <i class="ri-camera-2-line rounded-full"></i>
    </div>
    <minput label="Subdominio" formControlName="id" class="w-full"> </minput>
    <div>
      <minput label="Nombre" formControlName="name" />
      <ng-container class="form__control" formGroupName="customization">
        <minput label="Eslogan" type="text" formControlName="slogan" />
        <ngx-colors ngx-colors-trigger formControlName="color" class="mt-auto"></ngx-colors>
      </ng-container>
    </div>
    <div class="form__control" *ngIf="host">
      <span>Host: {{ host.name }} {{ host.surname }} </span>
    </div>
    <div>
      <minput *ngIf="!data?.id" label="Demo" formControlName="demo" type="switch"> </minput>
    </div>
    <div class="form__actions">
      <app-button
        class="btn-success text-base-100"
        matTooltip="Enviar"
        [disabled]="form.invalid"
        (click)="submit()"
      >
        <i class="ri-save-2-fill text-lg"></i>
      </app-button>
    </div>
  </div>
</form>
