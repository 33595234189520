<div class="channels-header">
  <h4>Enlaces</h4>
  <span class="spacer"></span>
  <button class="channels-header__close icon color-neutral" tabindex="-1" (click)="ref?.close()">
    <i class="ri-close-line"></i>
  </button>
</div>
<div class="channels-content">
  <form [formGroup]="form" (submit)="add()">
    <div class="form__control">
      <label for="nombre"> Nombre </label>
      <input class="input" type="text" formControlName="name" />
    </div>
    <div class="form__control">
      <label for="url"> Url </label>
      <input class="input" type="text" formControlName="url" />
    </div>
    <button class="color-success" [disabled]="form.invalid">
      <i class="ri-add-line"></i>
    </button>
  </form>
  <ul class="list">
    <li class="channel" *ngFor="let channel of channels">
      <div class="channel__info">
        <strong> {{ channel.name }} </strong>
        <span class="message"> {{ channel.url }} </span>
      </div>
      <div class="channel__actions">
        <a [href]="channel.url" target="_blank" class="icon color-accent" matTooltip="Ver Channel">
          <i class="ri-external-link-line"></i>
        </a>
        <button (click)="remove(channel.id)" class="icon color-error" matTooltip="Eliminar Channel">
          <i class="ri-delete-bin-line"></i>
        </button>
      </div>
    </li>
  </ul>
</div>

