<div class="attachments-content attachments-content--{{ size() }}">
  @for (attachment of attachments(); track attachment.id) {
    <div
      class="attachment-item attachment-item--{{ size() }}"
      [preview]="attachment"
      [previewFiles]="attachments()"
    >
      @if (!(readonly())) {
        <i
          class="ri-delete-bin-2-line attachment-item__delete"
          [ngClass]="{
            'w-4 h-4 text-xs': size() === 'sm',
            'w-6 h-6 text-sm': size() === 'md'
          }"
          (click)="remove(attachment, $event)"
        >
        </i>
      }
      @switch (attachment?.type) {
        @case ('image') {
          <img
            [src]="attachment?.src"
            onError="this.onerror=null;this.src='/assets/images/noimage.jpg';"
          />
        }
        @case ('video') {
          <i class="ri-film-line"></i>
        }
        @default {
          <i class="ri-file-cloud-line"></i>
        }
      }
    </div>
  }
</div>
