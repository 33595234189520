import { HttpResponse } from '@angular/common/http';
import { Observable, catchError, forkJoin, map, mergeMap, of } from 'rxjs';

export function toBase64(file: File) {
  return new Observable<string>((observer) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      observer.next(String(reader.result));
      observer.complete();
    };
    reader.onerror = (error) => observer.error(error);
    reader.readAsDataURL(file);
  });
}

export function resizeBase64Img(base64, width, height) {
  if (!base64.match(/^data:image\/.*;base64/)) throw new Error('base64 is not image');

  const img: HTMLImageElement = document.createElement('img');
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = width;
  canvas.height = height;

  return new Observable<string>((observer) => {
    img.onerror = (e) => observer.error(e);
    img.onload = (e) => {
      ctx.scale(width / img.width, height / img.height);
      ctx.drawImage(img, 0, 0);
      observer.next(canvas.toDataURL());
      observer.complete();
    };
    img.crossOrigin = 'anonymous';
    img.setAttribute('src', base64);
  });
}

export function base64ToFile(base64: string, filename: string, sliceSize: number = 512): File {
  const block = base64.split(';');
  const contentType = block[0].split(':')[1]; // In this case 'image/gif'
  const data = block[1].split(',')[1]; // In this case 'iVBORw0KGg....'

  const byteCharacters = atob(data);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) byteNumbers[i] = slice.charCodeAt(i);

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: contentType });
  const file = new File([blob], filename, {
    type: contentType,
    lastModified: Date.now()
  });
  return file;
}

export function imageSize(image: File) {
  if (!fileIsImage(image)) {
    throw new Error('File is not image');
  }

  const img = document.createElement('img');
  const _URL = window.URL || window.webkitURL;

  return new Observable<{ width: number; height: number }>((observer) => {
    img.onerror = (e) => observer.error(e);
    img.onload = () => {
      observer.next({ width: +img.width, height: +img.height });
      observer.complete();
    };
    img.src = _URL.createObjectURL(image);
  });
}

export function optimizeImage(image: File, maxWH: number = 800) {
  return forkJoin([toBase64(image), imageSize(image)]).pipe(
    map(([base64, { height, width }]) => {
      const size = Math.max(width, height);
      const scale = size > maxWH ? maxWH / size : 1;

      return [base64, scale * width, scale * height] as const;
    }),
    mergeMap((args) => resizeBase64Img(...args)),
    map((base64) => base64ToFile(base64, image.name)),
    catchError(() => of(image))
  );
}

export function fileIsImage(file: File) {
  return !!file.type.match(/image\/.*/);
}

export function pathFileType(path: string) {
  const ext = path?.replace(/.*\./, '') || '';
  return ext.match(/^(png|jpe?g|svg|webp)$/i)
    ? 'image'
    : ext.match(/^(avi|mpg|rm|mov|wav|asf|3gp|mkv|rmvb|mp4|ogg|mp3|oga|aac|mpeg|webm)$/i)
      ? 'video'
      : 'file';
}

export function downLoadFile(data: any | Blob, filename: string, type?: string) {
  try {
    const blob: Blob = type ? new Blob([data], { type: type }) : data;
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();

    return blob;
  } catch (e) {
    console.error(e);
    return null;
  }
}

export function downloadFromResponse(
  response: HttpResponse<any>,
  config?: {
    forceDefaults?: boolean;
    defaults?: {
      name: string;
      type?: string;
    };
  }
) {
  const { body, headers } = response;
  const { defaults, forceDefaults } = config || {};
  const disposition = headers.get('Content-Disposition');
  const type = forceDefaults || !disposition ? defaults?.type : headers.get('Content-Type');
  const name =
    forceDefaults || !disposition
      ? defaults?.name
      : disposition?.split('filename=')[1]?.split(';')[0]?.replace(/"/g, '');

  return downLoadFile(body, name, type);
}
