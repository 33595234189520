<form class="form" [formGroup]="form" *ngIf="form">
  <ng-container *ngFor="let control of visibles">
    <!-- MINPUT -->
    <minput
      *ngIf="
        control.isType([
          'text',
          'number',
          'email',
          'password',
          'textarea',
          'datetime-local',
          'checkbox'
        ])
      "
      [label]="control.title"
      [type]="control.type"
      [formControlName]="control.key"
      (change)="emitChange(control)"
    ></minput>
    <!-- SELECT -->
    <div class="form__control" *ngIf="control.isType(['select'])">
      <label *ngIf="control.title">{{ control.title }}</label>
      <app-select [formControlName]="control.key" (ngModelChange)="emitChange(control)">
        <app-option [value]="null"> Seleccione su opción </app-option>
        <app-option [value]="option.value" *ngFor="let option of options[control.key]">
          <i *ngIf="option.icon" [class]="option.icon"></i>
          <span [innerHtml]="parseSafeHtml(option.text || option.value)"></span>
        </app-option>
      </app-select>
    </div>
    <!-- OBJECT -->
    <div class="form__control" *ngIf="control.isType(['object'])">
      <label *ngIf="control.title">{{ control.title }}</label>
      <app-list-input
        [config]="control.object"
        [formControlName]="control.key"
        (change)="emitChange(control)"
      ></app-list-input>
    </div>
    <!-- FILE, IMAGE -->
    <div class="form__control" *ngIf="control.isType(['file','image'])">
      <label *ngIf="control.title">{{ control.title }}</label>
      <app-file-input
        [accept]="control.accept"
        [formControlName]="control.key"
        [multiple]="control.multiple"
        (change)="emitChange(control)"
      ></app-file-input>
    </div>
    <!-- COLOR -->
    <div class="form__control" *ngIf="control.isType(['color'])">
      <label *ngIf="control.title">{{ control.title }}</label>
      <div class="control px-4 gap-2">
        <ngx-colors
          #colorPicker
          ngx-colors-trigger
          acceptLabel="Seleccionar"
          [formControlName]="control.key"
        ></ngx-colors>
        <span class="pr-2">{{ colorPicker.color || 'Seleccione un color' }}</span>
      </div>
    </div>
    <!-- TAGS -->
    <div class="form__control" *ngIf="control.isType(['tags'])">
      <label *ngIf="control.title">{{ control.title }}</label>
      <app-tags-input formControlName="actions" [placeholder]="'Ingrese acciones aquí...'">
        <app-option
          *ngFor="let option of options[control.key] || []"
          [color]="option.color"
          [value]="option.value"
        >
          {{ option.text }}
        </app-option>
      </app-tags-input>
    </div>
    <!-- HTML -->
    <div class="quill-field" *ngIf="control.isType(['html'])">
      <span class="quill-field-title">
        {{ control.title }}
      </span>
      <quill-editor
        [formControlName]="control.key"
        [format]="'html'"
        [styles]="{ height: '250px' }"
        [sanitize]="true"
      ></quill-editor>
    </div>
    <!-- CHECKLIST -->
    <!-- <app-checklist-input
      *ngIf="control.isType(['checklist'])"
      [formControlName]="control.key"
    ></app-checklist-input> -->
  </ng-container>
</form>
