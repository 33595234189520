import { ReportService } from '@/operations/services/report.service';
import { Form } from '@/pms/models/form';
import { AccommodationFormsService } from '@/pms/services/accommodation-forms.service';
import { AccommodationsService } from '@/pms/services/accommodations.service';
import { AccommodationsUIService } from '@/pms/services/accommodations.ui.service';
import { BookingsService } from '@/pms/services/bookings.service';
import { CanDirective } from '@/shared/directives/can.directive';
import { SharedModule } from '@/shared/shared.module';
import { ListOldComponent } from '@/ux/components/list/list-old.component';
import { UXService } from '@/ux/services/ux.service';
import { CdkMenu, CdkMenuItem, CdkMenuTrigger } from '@angular/cdk/menu';
import { CommonModule } from '@angular/common';
import { Component, Input, TemplateRef, ViewChild, ViewChildren, inject } from '@angular/core';
import { DialogService } from '@mukhuve/ngx/dialog';
import { filter, mergeMap } from 'rxjs';
import { FormAssignmentComponent } from '../form-assignment/form-assignment.component';
import { FormCardComponent } from '../form-card/form-card.component';
import { TenancyDirective } from '@/shared/directives/tenancy.directive';

@Component({
  selector: 'app-accommodation-menu',
  standalone: true,
  imports: [
    CommonModule,
    CdkMenu,
    CdkMenuItem,
    CdkMenuTrigger,
    FormCardComponent,
    CanDirective,
    TenancyDirective
  ],
  templateUrl: './accommodation-menu.component.html',
  styleUrl: './accommodation-menu.component.scss'
})
export class AccommodationMenuComponent {
  @ViewChild('formTemplate', { read: TemplateRef })
  formTemplate!: TemplateRef<any>;

  @ViewChildren(CdkMenuTrigger)
  triggers!: CdkMenuTrigger[];

  @Input()
  item!: any;

  ui = inject(AccommodationsUIService);
  service = inject(AccommodationsService);
  bookings = inject(BookingsService);
  forms = inject(AccommodationFormsService);
  dialog = inject(DialogService);
  #ux = inject(UXService);
  reportService = inject(ReportService);

  subOpened(trigger: any) {
    const menuId = trigger?.childMenu?.id;
    this.triggers
      .filter((t: any) => t.childMenu && t.childMenu?.id !== menuId)
      .forEach((t: CdkMenuTrigger) => t?.close());
  }

  toggleActive() {
    const { id, active } = this.item;
    const action = active ? 'Desctivar' : 'Activar';
    this.#ux
      .confirm(`Seguro deseas ${action} esta propiedad?`)
      .pipe(
        filter((result) => !!result),
        mergeMap(() => this.service.update(id, { active: !active }))
      )
      .subscribe();
  }

  generateExpenseReport() {
    return;

    // this.reportService.generateExpenseReport({
    //   from: format(from, 'yyyy-MM-dd'),
    //   to: format(to, 'yyyy-MM-dd'),
    //   accommodationId: this.item.id
    // });
  }

  generateReportByAccommodation() {
    return;
    // const [startDate, endDate] = this.dates;
    // const params: Params = {
    //   from: format(startDate ?? startOfMonth(new Date()), 'yyyy-MM-dd'),
    //   to: format(endDate ?? endOfMonth(new Date()), 'yyyy-MM-dd'),
    //   id: this.item.id
    // };
    // this.reportService.generateReportByAccommodation(params).subscribe();
  }

  formsDialog() {
    const items = this.forms.list(this.item.id);
    const config = {
      classes: 'page pb-4',
      inputs: {
        items,
        title: 'Formularios',
        btns: {
          load: 'ri-restart-line text-info',
          add: 'ri-add-line text-success'
        },
        template: this.formTemplate
      }
    };

    const ref = this.dialog.open(ListOldComponent, config);
    ref.events.pipe(filter((e) => e.type === 'custom')).subscribe((event) => {
      const { type, value, instance, data } = event.data ?? {};
      const { search } = instance;
      const { id } = this.item;

      if (type === 'search' || (type === 'exec' && value === 'load'))
        instance.items = this.forms.list(id, search);

      if (type === 'exec') {
        if (value === 'add') {
          const result = new Form({ accommodationId: id });
          const inputs = { result, disableAccommodation: true };
          const aref = this.dialog.open(FormAssignmentComponent, { inputs });
          aref.events
            .pipe(filter((e) => e.type === 'beforeclose' && e.data))
            .subscribe(() => (instance.items = this.forms.list(id, search)));
        }

        if (value === 'edit') {
          const inputs = {
            result: data,
            disableForm: true,
            disableAccommodation: true
          };
          const aref = this.dialog.open(FormAssignmentComponent, { inputs });
          aref.events
            .pipe(filter((e) => e.type === 'beforeclose' && e.data))
            .subscribe(() => (instance.items = this.forms.list(id, search)));
        }

        if (value === 'delete') {
          this.forms
            .delete(data.assignmentId)
            .pipe(filter((success) => !!success))
            .subscribe(() => (instance.items = this.forms.list(id, search)));
        }
      }
    });
  }
}
