import { Component, inject, Input, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { DialogRef } from '@mukhuve/ngx/dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { uuidv4 } from 'src/app/core/helpers/crypto';
import { UXService } from 'src/app/ux/services/ux.service';
import { AccommodationsService } from '../../services/accommodations.service';

@Component({
  selector: 'app-accommodation-links',
  templateUrl: './accommodation-links.component.html',
  styleUrls: ['./accommodation-links.component.scss']
})
export class AccommodationLinksComponent implements OnInit {
  service = inject(AccommodationsService);
  ux = inject(UXService);
  ref?: DialogRef = inject(DialogRef);

  @Input()
  accommodationId: number;
  channels$: Observable<any[]>;
  channels = [];
  form: UntypedFormGroup;

  constructor() {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(null, Validators.required),
      url: new UntypedFormControl(null, Validators.required)
    });
  }

  ngOnInit() {
    this.channels$ = this.service
      .one(this.accommodationId)
      .pipe(map((a) => a?.channels ?? []));
    this.channels$.subscribe((results) => (this.channels = results));
  }

  add() {
    this.channels.push({ id: uuidv4(), ...this.form.value });
    this.form.reset();
    this.update();
  }

  remove(id: number | string) {
    this.ux
      .confirm(`Seguro deseas eliminar este canal?`)
      .subscribe((result) => {
        if (result) {
          this.channels = this.channels.filter((c) => c.id !== id);
          this.update();
        }
      });
  }

  update() {
    const { channels } = this;
    this.service.update(this.accommodationId, { channels }).subscribe();
  }
}
