<h1 class="text-primary text-3xl px-8">{{ title || '' }}</h1>
<section class="flex flex-col flex-auto">
  <small class="text-neutral-400 font-normal text-sm px-8">{{ description || '' }}</small>
  <app-resource-form #form [controls]="controls" [values]="data"> </app-resource-form>
</section>
<section
  class="flex flex-row items-center justify-end gap-2 p-4 flex-auto flex-grow-0 flex-shrink-0"
>
  <button (click)="ref?.close()">
    <i class="ri-close-line"></i>
    <span> Cancelar </span>
  </button>
  <button cdkFocusInitial (click)="submit()" class="color-primary">
    <i class="ri-save-line"></i>
    <span> Guardar </span>
  </button>
</section>
