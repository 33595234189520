const CONFIG = {
  main: {
    spaceBetween: 24,
    slidesPerView: 1,
    navigation: false,
    freeMode: false,
    watchSlidesProgress: true,
    loop: true,
    pagination: {
      clickable: true
    }
  },
  thumb: {
    loop: true,
    spaceBetween: 5,
    slidesPerView: 5,
    freeMode: true,
    watchSlidesProgress: true
  }
};

export default CONFIG;
