import { DatePipe, NgIf } from '@angular/common';
import { Component, HostListener, Input, ViewChild, forwardRef } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  MatDateRangePicker,
  MatDatepicker,
  MatDatepickerModule
} from '@angular/material/datepicker';
import { addMonths, parse } from 'date-fns';
import { AppControl } from '../../models/control';

@Component({
  standalone: true,
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
  imports: [MatDatepickerModule, NgIf, DatePipe],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DateComponent),
      multi: true
    }
  ],
  host: { class: 'control' }
})
export class DateComponent extends AppControl<Date | Date[] | any> {
  @ViewChild('picker') picker!: MatDateRangePicker<Date> | MatDatepicker<Date>;
  @Input() range?: boolean;
  @Input() disable?: boolean = false;
  @Input() placeholder?: string = this.range ? 'Fechas' : 'Fecha';

  min: string = addMonths(new Date(), -1).toISOString();
  max: string = addMonths(new Date(), 1).toISOString();

  select(selected: any) {
    const value = this.range
      ? [selected.start, selected.end]
      : parse(selected, 'dd/MM/yyyy', new Date());

    if (JSON.stringify(this.value) === JSON.stringify(value)) return;
    if (value) this.value = value;
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    event.stopPropagation();
    this.picker?.open();
  }
}
