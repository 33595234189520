import { AppService } from '@/core/services/app.service';
import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject
} from '@angular/core';
import { environment } from '@environments/environment';
import { Form } from '@pms/models/form';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'app-form-card',
  templateUrl: './form-card.component.html',
  styleUrls: ['./form-card.component.scss']
})
export class FormCardComponent implements OnInit {
  #app = inject(AppService);

  @Input()
  data: Form;

  @Input()
  editable = true;

  @Input()
  removable = true;

  @Output()
  edit = new EventEmitter<Form>();

  @Output()
  remove = new EventEmitter<Form>();

  constructor() {}

  ngOnInit() {}

  toFile(url: string) {
    const tenant = this.#app.globals.deep('tenancy.tenant.id');
    if (!url?.startsWith('http'))
      url = environment.apiUrl + url + `?tenant=${tenant}`;

    window.open(url, '_blank');
  }
}
