import { UsersService } from '@admin/services/users.service';
import {
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  inject,
  signal
} from '@angular/core';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { Comment } from 'src/app/core/models/comment';
import { CommentsService } from 'src/app/core/services/comments.service';
import { ResourcesService } from 'src/app/legacy/services/resources.service';
import { CommentTrigger } from './comments.types';
import { Dialog } from '@angular/cdk/dialog';
import { FilesComponent } from '../files/files.component';
import { EventsService } from '@/core/services/events.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CommonModule } from '@angular/common';
import { CommentInputComponent } from './comment-input/comment-input.component';
import { FormsModule } from '@angular/forms';
import { CommentMessageComponent } from './comment-message/comment-message.component';
import { MatTooltip } from '@angular/material/tooltip';
import { LoadingComponent } from "../../../ux/components/loading/loading.component";

@Component({
  standalone: true,
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss'],
  imports: [
    CommonModule,
    CommentInputComponent,
    FormsModule,
    CommentMessageComponent,
    MatTooltip,
    LoadingComponent
  ],
})
export class CommentsComponent implements OnInit {
  @ViewChild('box', { static: false })
  box!: ElementRef<HTMLDivElement>;

  @Input()
  ticketId: any;

  @Input()
  accommodationId: any;

  @Input()
  bookingId: any;

  @Input()
  readonly: boolean = false;

  @Input()
  commentId?: string | number;

  @Input()
  showReply?: boolean = true;

  @Output()
  modified: EventEmitter<void> = new EventEmitter();

  @Output()
  reply: EventEmitter<string | number> = new EventEmitter();

  search: string = '';
  loading = signal(false);
  events = inject(EventsService);
  triggers$: Observable<CommentTrigger[]>;
  comments = new BehaviorSubject<Comment[]>([]);
  comments$ = this.comments.asObservable();
  dialog = inject(Dialog);
  destroyRef = inject(DestroyRef);
  users = inject(UsersService);
  service = inject(CommentsService);
  resources = inject(ResourcesService);

  constructor() { }

  ngOnInit() {
    const requests = [this.users.list(), this.resources.lite('/tags'), this.resources.lite('/actions')];
    this.triggers$ = combineLatest(requests).pipe(
      map(([users, tags, actions]) => {
        const tagsItems = tags.map(({ id, name }) => ({ id, value: name }));
        const usersItems = users.map((user) => ({
          id: user.id,
          value: `${user.name} ${user.surname ?? ''}`.trim()
        }));
        const commandsItems = actions.map(({ id, name }) => ({ id: `create:${id}`, value: name }));
        return [
          { key: 'users', char: '@', items: usersItems },
          { key: 'tags', char: '#', items: tagsItems },
          { key: 'commands', char: '/', items: commandsItems },
        ];
      })
    );

    this.events.on(`comments:${this.ticketId}:*`)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(event => event.data.created),
      )
      .subscribe(() => this.load());

    this.load();
  }

  add(comment: Comment) {
    if (!this.ticketId) return;
    comment.ticketId = this.ticketId;
    comment.bookingId = this.bookingId;
    comment.accommodationId = this.accommodationId;
    comment.parentId = this.commentId;

    this.service.create(comment).subscribe();
  }

  edit(comment: Comment) {
    if (!this.ticketId) return;
    comment.ticketId = this.ticketId;
    this.service.update(comment.id, comment).subscribe(() => this.load());
  }

  remove(comment: Comment) {
    this.service.delete(comment.id).subscribe(() => this.load());
  }

  load() {
    this.loading.set(true);
    this.service
      .list(this.search, this.ticketId, this.commentId)
      .pipe(
        tap(() => {
          this.loading.set(false);
          setTimeout(() => {
            const objDiv = this.box?.nativeElement;
            objDiv.scrollTop = objDiv.scrollHeight;
          }, 1000);
        })
      )
      .subscribe((d) => this.comments.next(d));
  }

  files() {
    const data = {
      id: this.ticketId,
    };

    this.dialog.open(FilesComponent, { data });
  }
}
