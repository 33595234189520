import { CommonModule } from '@angular/common';
import {
  Component,
  ContentChildren,
  ElementRef,
  forwardRef,
  Input,
  QueryList,
  ViewChild
} from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  MatAutocompleteModule,
  MatAutocompleteTrigger
} from '@angular/material/autocomplete';
import { secureStr } from 'src/app/core/helpers/object-helpers';
import { Option } from '../../../ux/directives/option.directive';
import { AppOptionControl } from '../../../ux/models/option-control';
import { SanitizePipe } from '@/ux/pipes/sanitize.pipe';
import { ContrastPipe } from '@/ux/pipes/contrast.pipe';
import { MatOptionModule } from '@angular/material/core';

@Component({
  selector: 'app-tags-input',
  standalone: true,
  imports: [
    CommonModule,
    MatAutocompleteModule,
    SanitizePipe,
    ContrastPipe,
    MatOptionModule
  ],
  templateUrl: './tags-input.component.html',
  styleUrls: ['./tags-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TagsInputComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TagsInputComponent),
      multi: true
    }
  ]
})
export class TagsInputComponent extends AppOptionControl<Array<any>> {
  @ContentChildren(Option)
  override options: QueryList<Option> = new QueryList<Option>();

  @ViewChild('input', { static: true })
  input: ElementRef;

  @ViewChild(MatAutocompleteTrigger, { static: true })
  trigger: MatAutocompleteTrigger;

  @Input() placeholder = '';
  @Input() unique = true;

  tags: Option[] = [];
  search: string = '';
  filtered: Option[] = [];

  contains(value: any) {
    return this.value?.some((v) => secureStr(v) === secureStr(value)) ?? false;
  }

  add(value: any) {
    if (value != '•') {
      const valid = !this.unique || !this.contains(value);
      if (valid) {
        this.value = [...(this.value ?? []), value];
      }
    } else {
      this.value = this.options.map((o) => o.value);
    }
  }

  remove(value: any) {
    this.value = this.value?.filter((v) => secureStr(v) !== secureStr(value));
  }

  filter(value?: string) {
    const search = value?.toLowerCase();
    const filter = (o: Option) => o.label?.toLowerCase().includes(search);
    this.filtered = search
      ? this.options?.filter(filter)
      : this.options?.toArray();
  }

  override refresh() {
    this.tags = this.options?.filter((o) => this.contains(o.value));
    if (this.input) this.input.nativeElement.value = '';
    this.filter();
  }

  clear() {
    this.value = [];
  }
}
