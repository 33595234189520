<section class="flex flex-row flex-nowrap items-center justify-between">
  <h2 class="text-xl text-primary">Asignación de formulario</h2>
  <i *ngIf="ref" class="btn-close text-2xl ri-close-line ml-auto" (click)="ref?.close()"></i>
</section>
<app-select
  *ngIf="accommodations$ | async as accommodations"
  [disabled]="disableAccommodation"
  [placeholder]="'Seleccione una propiedad'"
  [(ngModel)]="result.accommodationId"
>
  <app-option *ngFor="let item of accommodations" [value]="item.id">
    {{ item.name }}
  </app-option>
</app-select>
<app-select
  *ngIf="forms$ | async as forms"
  [disabled]="disableForm"
  [placeholder]="'Seleccione un formulario'"
  [(ngModel)]="result.id"
  (ngModelChange)="formChanged($event, forms)"
>
  <app-option *ngFor="let item of forms" [value]="item.id">
    {{ item.title }}
  </app-option>
</app-select>
<app-form
  #formComponent
  class="border border-neutral-200 rounded-md"
  [hideHeader]="true"
  [hideFooter]="true"
  [form]="result"
>
</app-form>
<section class="flex items-center justify-end gap-2">
  <button class="btn btn-success text-white" (click)="submit(formComponent.form)">
    <i class="ri-send-plane-line"></i>
  </button>
</section>
