import { UXService } from '@/ux/services/ux.service';
import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Params } from '@angular/router';
import { DialogService } from '@mukhuve/ngx/dialog';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { TenantFormComponent } from '../components/tenant-form/tenant-form.component';
import { Tenant } from '../models/tenant';

@Injectable({
  providedIn: 'root'
})
export class TenantService {
  #ux = inject(UXService);
  #http = inject(HttpClient);
  #dialog = inject(DialogService);

  form(data?: Partial<Tenant>) {
    const id = data?.id;

    return this.#dialog
      .open(TenantFormComponent, { inputs: { data } })
      .events.pipe(
        filter((e) => e.type === 'beforeclose' && e.data),
        mergeMap(({ data }) => (id ? this.update(id, data) : this.store(data)))
      );
  }

  store(data: Partial<Tenant>) {
    return this.#http.post<any>(`//api/tenants`, data);
  }

  update(id: number | string, data: Partial<Tenant>) {
    return this.#http.put<any>(`//api/tenants/${id}`, data);
  }

  config(id: number | string, configs: Record<string, any> = {}) {
    const msg = `¿Está seguro de continuar con la operación?`;

    return this.#ux.confirm(msg).pipe(
      filter((result) => !!result),
      mergeMap(() => this.#http.patch(`//api/tenants/${id}/config`, configs)),
      tap({
        next: () => this.#ux.notifier.success('¡Configuración actualizada!'),
        error: () => this.#ux.notifier.error('¡Error al actualizar!')
      })
    );
  }

  seed(id: number | string, tables: string[] = []) {
    const msg = `<span class="text-error"> ¡Esta acción no se puede deshacer! </span>`;
    const config = {
      title: '¿Está seguro de sembrar los datos seleccionados?'
    };

    return this.#ux.confirm(msg, config).pipe(
      filter((result) => !!result),
      mergeMap(() => this.#http.post(`//api/tenants/${id}/seed`, { tables })),
      tap({
        next: () => this.#ux.notifier.success('¡Datos sembrados!'),
        error: () => this.#ux.notifier.error('¡Error al sembrar datos!')
      })
    );
  }

  list(params?: Params) {
    return this.#http
      .get<any>(`//api/tenants`, { params, observe: 'response' })
      .pipe(
        map((r) => {
          const data = (r?.body || []).map((t) => new Tenant(t));
          data.total = +r?.headers?.get('x-total');
          return data;
        })
      );
  }
}
