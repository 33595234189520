import { AppService } from '@/core/services/app.service';
import { Component, OnInit, inject } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-joinme',
  templateUrl: './joinme.component.html',
  styleUrls: ['./joinme.component.scss'],
  host: { class: 'page' }
})
export class JoinmeComponent implements OnInit {
  #app = inject(AppService);
  tenant: string;
  domain: string;
  form: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder, private router: Router) {
    this.tenant = this.#app.globals.deep('tenancy.tenant.id');
    this.domain = this.#app.globals.deep('tenancy.domain');

    if (this.#app.globals.deep('tenancy.valid', false)) {
      // this.router.navigateByUrl('');
    }
    this.form = this.fb.group({
      email: this.fb.control('', [Validators.required, Validators.email])
    });
  }

  ngOnInit() {}
}
