import { Injectable } from '@angular/core';
import { Route, UrlSegment, UrlTree } from '@angular/router';
import { Observable, catchError, map, of } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthGuard {
  constructor(private auth: AuthService) {}

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> {
    return this.verify();
  }

  canActivate() {
    return this.verify();
  }

  canActivateChild() {
    return this.verify();
  }

  verify() {
    if (this.auth.authenticated) return of(true);

    return this.auth.validate().pipe(
      map((session) => !!session),
      catchError(() => of(false))
    );
  }
}
