<section *ngIf="this.value?.length">
  <div
    *ngFor="let tag of tags"
    class="badge px-2 h-8 gap-2 cursor-pointer flex items-center"
    [class.bg-primary]="!tag.color"
    [style.background-color]="tag.color || ''"
    [style.color]="tag.color | contrast"
  >
    <span class="option no-hover" [innerHTML]="tag.label | sanitize : 'html'"></span>
    <i class="ri-close-line" (click)="remove(tag?.value)"></i>
  </div>
</section>
<input
  #input
  class="input"
  [placeholder]="placeholder"
  [matAutocomplete]="auto"
  (focus)="trigger.openPanel()"
  (input)="filter(input.value)"
  (keypress.enter)="add(filtered[0])"
/>
<mat-autocomplete #auto="matAutocomplete" (optionSelected)="add($event?.option?.value)">
  <mat-option *ngFor="let tag of filtered || []" [value]="tag.value">
    <span class="option no-hover" [innerHTML]="tag.label | sanitize : 'html'"></span>
  </mat-option>
  <mat-option [value]="'•'">
    <span class="option no-hover"> Agregar todos/as </span>
  </mat-option>
</mat-autocomplete>
