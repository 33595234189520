import { Injectable } from '@angular/core';
import { AuthService } from '../../core/services/auth.service';

@Injectable({ providedIn: 'root' })
export class GateService {
  public get stats() {
    return this.auth.user?.stats;
  }

  public get role() {
    return this.auth.user?.role;
  }

  constructor(private auth: AuthService) {}

  can(sentence: string) {
    if (!sentence || this.role?.code === 'su') return true;

    const [roles, stats] = sentence.includes('|')
      ? sentence.split('|')
      : sentence.includes('+')
      ? ['*', sentence]
      : [sentence, '*'];

    return this.hasRole(roles) && this.hasStats(stats);
  }

  hasRole(sentence?: string) {
    if (!sentence || sentence === '*') return true;
    if (!this.role) return false;

    const all = sentence.split(',');
    const roles = all.filter((s) => !s.startsWith('!'));
    const not = all
      .filter((s) => s.startsWith('!'))
      .map((s) => s.replace('!', ''));

    if (roles?.length) return roles.includes(this.role?.code);
    if (not?.length) return !not.includes(this.role?.code);
    return true;
  }

  hasStats(sentence?: string) {
    if (!this.role || !this.stats) return false;
    if (!sentence || this.role?.code === 'su' || sentence == '*') return true;
    const stats = sentence?.split(',')?.map((s) => s?.split('+'));

    return stats?.every(([code, lvl]) => {
      const stat = this.stats?.find((s) => s.code === code);
      const ulvl = Number(stat?.pivot?.lvl || '0');
      return stat && ulvl >= +(lvl || 0);
    });
  }
}
