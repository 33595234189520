export function domainInfo(valids: string[] = [], forced: string = '') {
  const hostname = window?.location?.hostname;
  const domain = valids?.find((d) => !!hostname.match(new RegExp(`${d}$`)));

  if (!domain) throw new Error('Invalid domain: ' + domain);

  const subregx = RegExp(`\.${domain}$`);
  const subdomain = forced || hostname.replace(subregx, '');

  return { domain, subdomain, hostname };
}

export function responseToInfo(response: any, domainInf: any) {
  const { domain, subdomain, hostname } = domainInf;
  const central = response?.central;
  const valid = !!response?.id && !response?.available;
  const tenant = valid ? response : null;

  return { domain, subdomain, hostname, tenant, central, valid };
}
