import { Component, EventEmitter, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { endOfMonth, startOfMonth } from 'date-fns';
import { SelectComponent } from '../../controls/select/select.component';
import { Option } from '../../directives/option.directive';

@Component({
  standalone: true,
  selector: 'app-date-month',
  templateUrl: './date-month.component.html',
  styleUrls: ['./date-month.component.scss'],
  imports: [FormsModule, ReactiveFormsModule, SelectComponent, Option]
})
export class DateMonthComponent {
  @Output() dateRangeSelected = new EventEmitter<Date[]>();

  selecteds = {
    month: new Date().getMonth(),
    year: new Date().getFullYear()
  };

  year = new Date().getFullYear();

  submit() {
    const { month = 0, year = this.year } = this.selecteds;
    this.dateRangeSelected.emit([
      startOfMonth(new Date(year, month)),
      endOfMonth(new Date(year, month))
    ]);
  }
}
