import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { fix } from '@core/helpers/object-helpers';
import { map } from 'rxjs/operators';
import { Notification } from '../../system/models/notifications';
import { EventsService } from '@/core/services/events.service';

@Injectable({ providedIn: 'root' })
export class NotificationsService {
  #http = inject(HttpClient);
  #events = inject(EventsService);

  readonly unread$ = this.#events.ping$.pipe(map((data) => data?.unread ?? 0));

  list(params?: any) {
    params = fix(params || {});
    return this.#http
      .get<any>(`//communications/notifications`, {
        params,
        observe: 'response'
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          const data = response.body;
          const total = +(response.headers.get('X-Total') || 0);
          return { data, total };
        })
      );
  }

  read(id: string) {
    const url = `//communications/notifications/${id}/read`;
    return this.#http.get<Notification[]>(url);
  }

  readAll() {
    const url = `//communications/notifications/read-all`;
    return this.#http.get(url);
  }
}
