import { parse } from 'date-fns';

export abstract class Entity<T extends Entity = any> {
  id: number | string;
  createdAt?: Date;
  updatedAt?: Date;

  constructor(obj?: Partial<T>) {
    Object.assign(this, obj);
    const createdAt = (obj as any)?.createdAt;
    const updatedAt = (obj as any)?.updatedAt;
    if (createdAt) this.createdAt = parse(createdAt, 'yyyy-MM-dd', new Date());
    if (updatedAt) this.updatedAt = parse(updatedAt, 'yyyy-MM-dd', new Date());
  }
}
