import { uuidv4 } from '../helpers/crypto';
import { Entity } from './base';

export class Attachment extends Entity<Attachment> {
  path?: string;
  error?: boolean;

  // old
  src?: string;
  type?: string;
  name?: string;
  file?: File;

  constructor(obj?: Partial<Attachment>) {
    super(obj);
    this.id = obj.id;
    this.src = obj.src;
    this.type = obj.type;
    this.name = obj.name;
    this.file = obj.file;
  }

  static fromFile(file: File): Attachment {
    return new Attachment({
      id: uuidv4(),
      src: URL.createObjectURL(file),
      type: file.type.startsWith('image')
        ? 'image'
        : file.type.startsWith('video')
          ? 'video'
          : 'file',
      name: file.name,
      file: file
    });
  }
}
