import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DialogRef } from '@mukhuve/ngx/dialog';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-password-form',
  templateUrl: './password-form.component.html',
  styleUrls: ['./password-form.component.scss']
})
export class PasswordFormComponent {
  form: UntypedFormGroup;

  constructor(
    public ref: DialogRef,
    private fb: UntypedFormBuilder,
    private auth: AuthService
  ) {
    this.form = this.fb.group({
      password: ['', Validators.required],
      confirm: ['', Validators.required]
    });
    this.form.addValidators((f: UntypedFormGroup) => {
      const { password, confirm } = f.value;
      return password !== confirm ? { confirm: 'Invalid' } : null;
    });
  }

  submit() {
    const { password } = this.form.value;
    this.auth.updateme({ password }).subscribe((r) => this.ref.close(r));
  }
}
