import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AppService } from '../../services/app.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { UXModule } from 'src/app/ux/ux.module';

@Component({
  standalone: true,
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  imports: [SharedModule, UXModule]
})
export class SearchComponent implements AfterViewInit {
  @ViewChild('input') input: ElementRef;
  results$?: Observable<any[]>;
  timeout: any;

  constructor(public app: AppService, private http: HttpClient) {}

  ngAfterViewInit() {
    this.input?.nativeElement?.focus();
  }

  onInput(text?: string) {
    if (!text) return;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => this.search(text), 240);
  }

  search(search: string) {
    if (search?.length >= 3) {
      const options = { params: { search } };
      this.results$ = this.http.get<any[]>('//api/tickets/search', options);
    } else this.results$ = of([]);
  }
}
