import { NgFor, NgIf } from '@angular/common';
import { AfterContentInit, Component, Input } from '@angular/core';
import { OrderPipe } from '../../pipes/order.pipe';
import { JsonMap } from './json-card.map';
import { CopyDirective } from '../../directives/copy.directive';

@Component({
  standalone: true,
  selector: 'json-card',
  templateUrl: './json-card.component.html',
  styleUrls: ['./json-card.component.scss'],
  imports: [NgIf, NgFor, OrderPipe, CopyDirective]
})
export class JsonCardComponent implements AfterContentInit {
  @Input()
  data: Record<string, any> = {};

  @Input()
  mapper: JsonMap[] = [];

  constructor() {}

  ngAfterContentInit() {}

  trackByFn(index: number, item: any) {
    return index;
  }
}
