import { Component, HostListener, Input, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Notification } from 'src/app/system/models/notifications';
import { NotificationsService } from '../../services/notifications.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notification-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './notification-card.component.html',
  styleUrls: ['./notification-card.component.scss']
})
export class NotificationCardComponent {
  #service = inject(NotificationsService);
  #router = inject(Router);

  @Input()
  notification!: Notification;

  get data() {
    return this.notification.data;
  }

  ngOnInit() {
    this.notification.data.accommodation_name =
      'Un apartamente con vistas al norte!';
  }

  @HostListener('click')
  onClick() {
    const regex = /.*\\([^\\]*)$/;
    const { type, data } = this.notification;
    let id;

    switch (type.replace(regex, '$1')) {
      case 'TicketNotification':
      case 'AssingmentNotification':
        id = data?.data?.ticket?.id;
        if (id) {
          this.#router.navigate(['/bridge/tickets'], { queryParams: { id } });
        }
        break;
      case 'MentionNotification':
        id = data?.data?.comment?.ticket_id;
        if (id) {
          this.#router.navigate(['/bridge/tickets'], {
            queryParams: { id }
          });
        }
      default:
        console.debug('Unknown notification type: ', type);
        break;
    }
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    if (this.notification.read_at) {
      return;
    }

    this.notification.read_at = new Date().toISOString();
    this.#service.read(this.notification.id).subscribe();
  }
}
