import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { UXService } from 'src/app/ux/services/ux.service';
import { GlobalStore } from '../classes/globals';

export type AppEvent = {
  type: string;
  payload?: any;
};

@Injectable({ providedIn: 'root' })
export class AppService {
  readonly eventBus = new EventEmitter<AppEvent>();
  readonly globals = new GlobalStore();

  constructor(public ux: UXService) {}

  public emit(type: string, payload?: any) {
    this.eventBus.emit({ type, payload });
  }

  public on(pattern: string): Observable<AppEvent> {
    return this.eventBus
      .asObservable()
      .pipe(filter((event) => !!event.type.match(pattern)));
  }
}
