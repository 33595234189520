<!-- <div class="list-headers">
  <span
    *ngFor="let field of config"
    [class.flex-1]="field?.type !== 'checkbox'"
    [class.flex-initial]="field?.type === 'checkbox'"
  >
    {{ field.title }}
  </span>
</div>
<div class="list-container">
  <div *ngFor="let item of value || []; let i = index" class="list-item">
    <ng-container *ngFor="let field of config">
      <span *ngIf="field?.type !== 'checkbox'" class="flex-1"> {{ item[field.key] }} </span>
      <span *ngIf="field?.type === 'checkbox'" class="flex-initial">
        {{ item[field.key] ? 'si' : 'no' }}</span
      >
    </ng-container>
    <div class="flex-initial flex flex-row items-center gap-2">
      <button class="btn btn-ghost text-info" (click)="update(i)" [tabIndex]="-1">
        <i class="ri-pencil-line"></i>
      </button>
      <button class="btn btn-ghost text-error" (click)="remove(i)" [tabIndex]="-1">
        <i class="ri-delete-bin-line"></i>
      </button>
    </div>
  </div>
  <div class="no-items" *ngIf="!_list?.length">Nada para mostrar</div>
</div>
-->

<table>
  <thead>
    <tr>
      <th
        *ngFor="let field of config"
        class="text-start"
        [class.flex-1]="field?.type !== 'checkbox'"
        [class.flex-initial]="field?.type === 'checkbox'"
      >
        {{ field.title }}
      </th>
      <th class="actions-td flex-initial">
        <i class="ri-settings-3-line"></i>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of value || []; let i = index">
      <ng-container *ngFor="let field of config">
        <td *ngIf="field?.type !== 'checkbox'" class="flex-1">{{ item[field.key] }}</td>
        <td *ngIf="field?.type === 'checkbox'" class="flex-initial">
          {{ item[field.key] ? 'si' : 'no' }}
        </td>
      </ng-container>
      <td class="actions-td flex-initial">
        <button class="btn btn-ghost text-info" (click)="update(i)" [tabIndex]="-1">
          <i class="ri-pencil-line"></i>
        </button>
        <button class="btn btn-ghost text-error" (click)="remove(i)" [tabIndex]="-1">
          <i class="ri-delete-bin-line"></i>
        </button>
      </td>
    </tr>
    <tr *ngIf="!_list?.length">
      <td class="no-items" [attr.colspan]="config.length + 1">Nada para mostrar</td>
    </tr>
    <tr [formGroup]="inputs" (keyup.enter)="add()">
      <td *ngFor="let field of config">
        <minput
          [type]="field.type"
          [formControlName]="field.key"
          [placeholder]="field.title"
          [disabled]="disabled"
        >
        </minput>
      </td>
      <td class="actions-td flex-initial">
        <button class="btn btn-primary" (click)="add()">
          <i class="ri-add-line"></i>
        </button>
      </td>
    </tr>
  </tbody>
</table>
