import { Component, OnInit } from '@angular/core';
import { DialogService } from '@mukhuve/ngx/dialog';
import { AuthService } from 'src/app/core/services/auth.service';
import { PasswordFormComponent } from '../../components/password-form/password-form.component';
import { UpdatemeFormComponent } from '../../components/updateme-form/updateme-form.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  host: { class: 'page' }
})
export class ProfileComponent implements OnInit {
  constructor(public auth: AuthService, private dialog: DialogService) { }

  ngOnInit() { }

  edit() {
    const ref = this.dialog.open(UpdatemeFormComponent, {
      classes: 'without-min',
      inputs: { data: this.auth.user }
    });
    ref.events.subscribe((event) => {
      if (event.type === 'beforeclose') this.auth.validate().subscribe();
    });
  }

  changePassword() {
    this.dialog.open(PasswordFormComponent, { classes: 'without-min' });
  }
}
