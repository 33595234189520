import { FormGroup } from '@angular/forms';
import { finalize, OperatorFunction } from 'rxjs';

export function markForm<T>(form: FormGroup): OperatorFunction<T, T> {
  return (source$) => {
    form.markAsPending();
    return source$.pipe(
      finalize(() => {
        console.log('Updating form validity');
        form.updateValueAndValidity();
      })
    );
  };
}
