import { ResourcesService } from '../../services/resources.service';
import { RuleControl, RuleParams, RuleSentence } from './rule.types';

export class ResourceRule {
  key!: string;
  icon: string;
  title: string;
  params: RuleParams;
  sentences: RuleSentence[];

  constructor(params: RuleParams | string, key?: string) {
    if (typeof params === 'string')
      params = { title: params, icon: 'ri-hashtag' };
    this.key = key;
    this.params = params;
    this.icon = params.icon || 'ri-information-line';
    this.title = params.title;
    this.sentences = params.sentences || [];
  }

  getControl(service: ResourcesService) {
    return new RuleControl(this.key, this.title, service, this.params.control);
  }
}
