import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DialogRef } from '@mukhuve/ngx/dialog';
import { User } from 'src/app/core/models/user';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-updateme-form',
  templateUrl: './updateme-form.component.html',
  styleUrls: ['./updateme-form.component.scss']
})
export class UpdatemeFormComponent implements OnInit {
  @Input() data?: User;
  form: UntypedFormGroup;
  preview: string;

  constructor(
    public ref: DialogRef,
    private fb: UntypedFormBuilder,
    private auth: AuthService
  ) { }

  ngOnInit() {
    const controls = User.formObject(this.data);
    delete controls.password, controls.role_id, controls.email;
    this.form = this.fb.group(controls);
    this.form.get('image').valueChanges.subscribe((v) => { });
    this.preview = this.data?.image;
  }

  updateImage(input: any) {
    var reader = new FileReader();
    reader.onload = () => {
      const image = (this.preview = String(reader.result));
      this.form.patchValue({ image });
    };
    reader.readAsDataURL((input.files || [])[0]);
  }

  submit() {
    const data = this.form.value;
    data.phones = [data.phone];
    delete data.phone;
    this.auth.updateme(data).subscribe((r) => this.ref.close(r));
  }
}
