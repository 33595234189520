<section>
  <p>
    Genial!
    <b class="inline-flex flex-row gap-0">
      <span class="text-primary">{{ tenant }} </span>
      <span> .{{ domain }} </span>
    </b>
    esta disponible para ti! 🥳
  </p>
  <form [formGroup]="form" class="join join-horizontal !gap-0">
    <input
      class="input join-item"
      type="email"
      formControlName="email"
      placeholder="Dejanos tu correo..."
    />
    <button class="btn btn-primary join-item" [disabled]="form.invalid">Contáctame!</button>
  </form>
</section>
