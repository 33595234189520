import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormBuilder,
  NgControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors
} from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';

export const FILE_INPUT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FileInputComponent),
  multi: true
};

@Component({
  selector: 'app-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
  providers: [
    FILE_INPUT_VALUE_ACCESSOR,
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FileInputComponent),
      multi: true
    },
    { provide: MatFormFieldControl, useExisting: FileInputComponent }
  ],
  host: { class: 'input' }
})
export class FileInputComponent
  implements
    MatFormFieldControl<File | FileList>,
    ControlValueAccessor,
    OnDestroy,
    OnInit
{
  static nextId = 0;
  private _placeholder: string = '';
  public stateChanges = new Subject<void>();
  @HostBinding() id = `file-input-${FileInputComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedBy = '';
  public focused = false;
  private _required = false;
  private _disabled = false;
  errorState = false; // errors check
  controlType = 'file-input';
  autofilled = false;
  onChange: any;
  readonly ngControl: NgControl | null;

  @Input() accept: string;
  @Input() multiple: boolean;
  @Input() readonly: boolean;
  _value: File | FileList | any;

  constructor(
    private fb: UntypedFormBuilder,
    private fm: FocusMonitor,
    private elRef: ElementRef<HTMLElement> // @Optional() @Self() public ngControl: NgControl
  ) {
    fm.monitor(elRef.nativeElement, true).subscribe((origin) => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }

  ngOnInit() {}

  changeValue(event) {
    let input: HTMLInputElement = event.target as HTMLInputElement;
    let file: File = input.files.item(0);
    this.value = this.multiple ? input.files : file;
  }

  get value(): File | FileList {
    return this._value;
  }

  @Input()
  set value(f: File | FileList) {
    if (f instanceof File || f instanceof FileList) {
      this._value = f;
      if (this.onChange) this.onChange(this._value);
      this.stateChanges.next();
    }
  }

  get empty() {
    return !this._value;
  }

  get placeholder() {
    return this._placeholder;
  }

  @Input()
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get required() {
    return this._required;
  }

  @Input()
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  get disabled() {
    return this._disabled;
  }

  @Input()
  set disabled(dis) {
    this._disabled = coerceBooleanProperty(dis);
    this.stateChanges.next();
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      this.elRef.nativeElement.querySelector('input').focus();
    }
  }

  ngOnDestroy() {
    this.fm.stopMonitoring(this.elRef.nativeElement);
    this.stateChanges.complete();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(file: File): void {
    this.value = file;
  }

  validate(c: AbstractControl): ValidationErrors | null {
    if (!this._value) return {};
    return null;
  }

  get viewValue(): File[] {
    let array: File[] = [];
    if (this._value instanceof File) array = [this._value];
    else if (this._value instanceof FileList)
      for (let i = 0; i < this._value.length; i++)
        array.push(this._value.item(i));
    return array;
  }

  get totalSize(): number {
    let size: number = 0;
    if (this._value instanceof File) size = this._value.size;
    else if (this._value instanceof FileList)
      for (let i = 0; i < this._value.length; i++)
        size += this._value.item(i).size;
    return size;
  }
}
