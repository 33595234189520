import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Parameter } from '../models/parameter';

@Injectable({ providedIn: 'root' })
export class ParametersService {
  private parameters: Parameter[] = [];

  constructor(private http: HttpClient) {
    this.load();
  }

  load() {
    this.http.get<any>('//api/parameters').subscribe((response) => {
      this.parameters = response || [];
    });
  }

  all(): Parameter[] {
    return this.parameters;
  }

  one(code: string): Parameter {
    return this.parameters.find((param) => param.code === code);
  }

  oneValue(code: string): string {
    const param = this.parameters.find((p: Parameter) => p.code === code);
    return param ? param.value : null;
  }
}
