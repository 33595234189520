<div class="preview-swiper">
  <swiper
    #swiper
    class="preview-swiper__main"
    [config]="config?.main"
    [thumbs]="{ swiper: thumbs.swiperRef }"
  >
    @for (attachment of files(); track attachment.id) {
      <ng-template swiperSlide>
        @if (attachment.error) {
          <div class="default-view">
            <i class="ri-file-damage-line"></i>
            <span> Archivo dañado o no soportado </span>
          </div>
        } @else {
          @switch (attachment.type) {
            @case ('image') {
              <img [src]="attachment?.src" (error)="attachment.error = true" />
            }
            @case ('video') {
              <video [src]="attachment.src" controls></video>
            }
            @default {
              <div class="default-view">
                <i class="ri-file-cloud-fill"></i>
              </div>
            }
          }
        }
      </ng-template>
    }
  </swiper>
  <swiper
    #thumbs
    class="preview-swiper__thumbs"
    [spaceBetween]="5"
    [slidesPerView]="5"
    [freeMode]="true"
  >
    @for (attachment of files(); track attachment.id) {
      <ng-template swiperSlide>
        @switch (attachment.type) {
          @case ('image') {
            <img
              [src]="attachment?.src"
              onError="this.onerror=null;this.src='/assets/images/noimage.jpg';"
            />
          }
          @case ('video') {
            <div class="default-view">
              <i class="ri-film-line"></i>
            </div>
          }
          @default {
            <div class="default-view">
              <i class="ri-file-cloud-fill"></i>
            </div>
          }
        }
      </ng-template>
    }
  </swiper>
</div>
<div class="preview-actions">
  <button class="btn btn-primary" (click)="open($event)">
    <i class="ri-external-link-line"></i>
  </button>
  <button class="btn btn-error" (click)="ref?.close()">
    <i class="ri-close-line"></i>
  </button>
</div>
