import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router } from '@angular/router';
import { GateService } from 'src/app/shared/services/gate.service';

@Injectable()
export class GateGuard  {
  constructor(private gate: GateService, private router: Router) {}

  canLoad(route: Route) {
    return this.verify(route.data.sentence);
  }

  canActivate(route: ActivatedRouteSnapshot) {
    return this.verify(route.data.sentence);
  }

  canActivateChild(route: ActivatedRouteSnapshot) {
    return this.verify(route.data.sentence);
  }

  verify(sentence: string) {
    return this.gate.can(sentence);
  }
}
