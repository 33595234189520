import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2
} from '@angular/core';

@Component({
  selector: 'tab',
  templateUrl: './tab.component.html',
  host: { class: 'tab' }
})
export class TabComponent implements OnInit {
  @Output('activated') activatedEvent = new EventEmitter();

  @Input() icon?: string;
  @Input() title?: string;
  @Input() link?: string;
  @Input() activeClass = 'active';
  @Input() activated = false;
  classes?: string;

  constructor(public el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    this.classes = this.el?.nativeElement?.classList.toString();
    if (this.activated) {
      this.active(true, this.activeClass);
    }
  }

  active(value = true, extraClass = '') {
    this.activated = value;

    const el = this.el.nativeElement as HTMLElement;
    if (value) {
      this.renderer.addClass(el, this.activeClass || 'active');
      this.renderer.addClass(el, extraClass || '');
      this.activatedEvent.emit();
    } else {
      if (el.classList.contains('active')) {
        this.renderer.removeClass(el, this.activeClass || 'active');
      }
      if (el.classList.contains(extraClass)) {
        this.renderer.removeClass(el, extraClass || '');
      }
    }
  }
}
