import { Ticket } from '@/operations/models/ticket';
import { TicketsService } from '@/operations/services/tickets.service';
import { DialogRef, DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, inject, input, OnInit, signal } from '@angular/core';
import { TicketCardComponent } from '../ticket-card/ticket-card.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DialogService } from '@mukhuve/ngx/dialog';
import { TicketFormComponent } from '@/operations/components/ticket-form/ticket-form.component';
import { TicketsOptions } from '@/operations/models/tickets-options';
import { Observable } from 'rxjs';
import { CommonModule } from '@angular/common';
import { UXModule } from '@/ux/ux.module';
import { FormsModule } from '@angular/forms';

@Component({
  standalone: true,
  selector: 'app-related',
  templateUrl: './related.component.html',
  styleUrls: ['./related.component.scss'],
  imports: [CommonModule, TicketCardComponent, MatTooltipModule, UXModule, FormsModule],
})
export class RelatedComponent implements OnInit {
  ref = inject(DialogRef);
  data = inject(DIALOG_DATA);
  id = input<number | string>(this.data.ticket.id);
  bookingId = input<number | string>(this.data.ticket.booking_id);
  private service = inject(TicketsService);
  dialog = inject(DialogService);
  tickets$: Observable<Ticket[]>;
  private timeout: any;
  public search: string = '';

  loading = signal(false);

  tickets: Ticket[] = [];

  constructor() {}

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.loading.set(true);
    const options = new TicketsOptions({
      bookings: [this.bookingId()],
      mode: 'lg',
      date: null,
      search: this.search 
    });

    this.tickets$ = this.service.list(options);
  }

  onSearch() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => this.load(), 420);
  }

  identify(index, item) {
    return item?.id || -1;
  }

  create() {
    const accommodation_id = this.data.ticket?.accommodation_id;
    const booking_id = this.data.ticket?.booking_id;

    const ref = this.dialog.open(TicketFormComponent, {
      classes: 'without-min',
      inputs: {
        data: { accommodation_id, booking_id },
        hidden: {
          accommodation_id: !!accommodation_id,
          booking_id: !!booking_id
        }
      }
    });

    ref.events.subscribe((event) => {
      if (event.type === 'beforeclose' && event.data) this.load();
    });
  }
}