import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output
} from '@angular/core';
import { UXService } from '../services/ux.service';
import { MinputType } from '../components/minput/minput.component';
import { Option } from './option.directive';

@Directive({
  standalone: true,
  selector: '[quickedit]'
})
export class QuickeditDirective {
  @Input()
  quickedit: MinputType = 'text';

  @Input()
  quickeditValue?: string;

  @Input()
  quickeditTitle?: string;

  @Input()
  quickeditPlaceholder?: string;

  @Input()
  quickeditOptions?: Partial<Option & { label?: string }>[];

  @Output()
  edited = new EventEmitter<any>();

  constructor(private ux: UXService) {}

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();

    const config = {
      type: this.quickedit,
      title: this.quickeditTitle,
      value: this.quickeditValue,
      options: this.quickeditOptions,
      placeholder: this.quickeditPlaceholder
    };

    this.ux.quickedit(config).subscribe((value) => {
      if (!value) return;
      this.edited.emit(value);
    });
  }
}
