import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  AfterContentInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { Triggerable } from '../../directives/triggerfor.directive';
import { DropdownConfig, DropdownPositions } from './dropdown.configs';

@Component({
  standalone: true,
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent
  implements OnDestroy, Triggerable, AfterContentInit
{
  @ViewChild(TemplateRef, { static: true })
  private templateRef: TemplateRef<any>;
  private overlayRef: OverlayRef;
  isOpen = false;

  @Input()
  hasBackdrop = true;

  @Output()
  inited: EventEmitter<DropdownComponent> = new EventEmitter();

  @Output()
  closed: EventEmitter<DropdownComponent> = new EventEmitter();

  constructor(
    private el: ElementRef,
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngAfterContentInit() {
    this.inited.emit(this);
  }

  handleTrigger(name: string, container?: ViewContainerRef): void {
    if (name === 'default') {
      if (this.isOpen) this.close();
      else this.open({ container });
    }
  }

  open(config?: DropdownConfig): void {
    const { dir, connectedTo, container, panelClass } = config || {};
    const postitions: any[] = DropdownPositions[dir || 'left'];
    this.close();
    this.overlayRef = this.overlay.create({
      panelClass,
      hasBackdrop: this.hasBackdrop,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(connectedTo || this.el)
        .withPositions(postitions)
    });

    const templatePortal = new TemplatePortal(
      this.templateRef,
      container || this.viewContainerRef
    );
    this.overlayRef.backdropClick().subscribe(() => this.close());
    this.isOpen = true;
    this.overlayRef.attach(templatePortal);
  }

  close() {
    if (!this.overlayRef) return;
    this.isOpen = false;
    this.overlayRef?.detach();
    this.closed.emit(this);
  }

  @HostListener('keydown.escape', ['$event'])
  esc(e: KeyboardEvent) {
    e.stopPropagation();
    e.preventDefault();
    this.close();
  }

  ngOnDestroy() {
    if (this.overlayRef) this.overlayRef.dispose();
  }
}
