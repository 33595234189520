import { Attachment } from '@/core/models/attachment';
import { FilesDialogComponent } from '@/shared/components/files-dialog/files-dialog.component';
import { Dialog } from '@angular/cdk/dialog';
import { Directive, HostListener, inject, input } from '@angular/core';

@Directive({ standalone: true, selector: '[preview]' })
export class PreviewDirective {
  preview = input<Attachment>(null);
  files = input<Attachment[]>([], { alias: 'previewFiles' });
  dialog = inject(Dialog);

  @HostListener('click')
  onClick() {
    const data = {
      files: this.files(),
      selected: this.preview()
    };

    this.dialog.open(FilesDialogComponent, { data });
  }
}
