import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { CommentInputComponent } from '../comments/comment-input/comment-input.component';
import { CommentsService } from '@/core/services/comments.service';
import { Comment } from '@/core/models/comment';
import { Ticket } from '@/operations/models/ticket';
import { CommentsComponent } from '../comments/comments.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    CommentInputComponent,
    CommentsComponent,
    MatTooltipModule
  ],
  selector: 'app-comment-reply',
  templateUrl: './comment-reply.component.html',
  styleUrls: ['./comment-reply.component.scss'],
})
export class CommentReplyComponent implements OnInit {
  @Input() id: number | string = null;
  @Input() readonly: boolean = true;
  @Input() ticket: Ticket;
  @Output() comeback = new EventEmitter<void>();
  service = inject(CommentsService);
  comment = new BehaviorSubject<Comment>(null);
  comment$ = this.comment.asObservable();
  see: 'comments' | 'detail' = 'detail';

  constructor() { }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.service.one(this.id).subscribe((comment: Comment) => this.comment.next(comment));
  }

  loadComments(force?: boolean, comments?: boolean) {
    console.log('loadComments');
  }

  back() {
    this.comeback.emit();
  }

  reply(id: string | number) {
    this.id = id;
  }
}