import { Directive, ElementRef, Input, QueryList } from '@angular/core';

@Directive({ standalone: true, selector: 'app-option' })
export class Option {
  static waitOption() {
    const option = new Option();
    option.icon = 'ri-loader-2-line animate-spin';
    option.value = undefined;

    return option;
  }

  @Input()
  icon?: string;

  @Input()
  value?: any;

  @Input()
  color?: string;

  @Input()
  classes: string = '';

  meta?: any;

  get label() {
    return this.el?.nativeElement?.innerHTML || '';
  }

  constructor(private el?: ElementRef) {}

}

export interface Optionable {
  options: QueryList<Option>;
}
