<div class="flex items-center justify-between gap-1 text-neutral text-opacity-60">
  <h2 class="text-base text-force-ellipsis">{{ data.name }}</h2>
  <div class="flex items-start self-stretch justify-end">
    <button class="flex items-center p-0 w-6 h-6 min-h-0" [matTooltip]="'Editar'" (click)="edit()">
      <i class="ri-edit-line"></i>
    </button>
  </div>
</div>
<p class="text-sm">{{ data.description ?? 'Sin descripción' }}</p>
<div class="flex items-center gap-2 mt-auto border-t border-neutral border-opacity-20 pt-4">
  <span class="inline-flex items-center gap-1 text-primary ml-auto">
    <i class="ri-file-shield-2-line"></i>
    <span>: {{ withEvidence ?? 0 }}</span>
  </span>
  <span class="inline-flex items-center gap-1 badge badge-lg badge-primary">
    <i class="ri-list-check-3"></i>
    <span>: {{ data.tasks.length ?? 0 }}</span>
  </span>
</div>
