import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { UXService } from 'src/app/ux/services/ux.service';
import { Form } from '../models/form';

@Injectable({ providedIn: 'root' })
export class FormsService {
  constructor(private http: HttpClient, private ux: UXService) {}

  list(params?: any) {
    return this.http
      .get<any>(`//api/sys/forms`, { params, observe: 'response' })
      .pipe(
        map((r) => {
          const data = (r?.body || []).map((o) => new Form(o));
          data.total = +r?.headers?.get('x-total');
          return data;
        })
      );
  }

  create(data: any) {
    return this.http.post<any>(`//api/sys/forms`, data).pipe(
      tap({
        next: () => this.ux.notifier.success('¡Formulario creado!'),
        error: () => this.ux.notifier.error('¡Error al crear formulario!')
      })
    );
  }

  update(id: number | string, data: Partial<any>) {
    return this.http.put<any>(`//api/sys/forms/${id}`, data).pipe(
      tap({
        next: () => this.ux.notifier.success('¡Formulario actualizado!'),
        error: () => this.ux.notifier.error('¡Error al actualizar formulario!')
      })
    );
  }

  store(form: Partial<Form>) {
    return form.id ? this.update(form.id, form) : this.create(form);
  }

  delete(form: Form) {
    const { id, title } = form;

    return this.ux
      .confirm(`¿Está seguro eliminar el formulario '${title}'?`)
      .pipe(
        filter((result) => !!result),
        mergeMap(() => this.http.delete<any>(`//api/sys/forms/${id}`)),
        tap({
          next: () => this.ux.notifier.success('¡Formulario eliminado!'),
          error: () => this.ux.notifier.error('¡Error al eliminar formulario!')
        })
      );
  }
}
