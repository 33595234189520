import { TenancyService } from '@/admin/services/tenancy.service';
import { AppService } from '@/core/services/app.service';
import { Tenant } from '@admin/models/tenant';
import {
  AfterContentInit,
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
  inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Directive({ standalone: true, selector: '[tenancy]' })
export class TenancyDirective implements AfterContentInit {
  private sentence = 'tenant';
  private central: boolean;
  private hiden: boolean;

  #app = inject(AppService);
  #tenancy = inject(TenancyService);
  #tenant = this.#tenancy.selected;
  #tenant$ = this.#app.globals.deepSubscribe<Tenant>('tenancy.select:tenancy.tenant');

  @Input() tenancyElse?: TemplateRef<any>;
  @Input() tenancyOn?: string;
  @Input()
  set tenancy(value: string) {
    this.sentence = value || 'tenant';
  }

  constructor(
    private tref: TemplateRef<any>,
    private vref: ViewContainerRef
  ) {
    this.central = this.#app.globals.deep('tenancy.central', false);
    this.#tenant$.pipe(takeUntilDestroyed()).subscribe((tenant) => {
      this.#tenant = new Tenant(tenant);
      this.checkContent();
    });
  }

  ngAfterContentInit() {
    this.checkContent();
  }

  checkContent() {
    const [type, args] = this.sentence.split(':');
    const flags = args?.split(',') || [];

    switch (type) {
      case 'tenant':
        this.hiden = this.central;
        if (this.tenancyOn) this.hiden ||= this.tenancyOn !== this.#tenant.id;
        break;
      case 'central':
        this.hiden = !this.central;
        break;
      case 'universal':
        this.hiden = false;
        break;
    }

    if (flags.includes('root')) {
      this.hiden ||= !this.#tenant.isRoot;
    }

    if (flags.includes('demo')) {
      this.hiden ||= !this.#tenant.demo;
    }

    if (flags.includes('active')) {
      this.hiden ||= !this.#tenant.active;
    }

    this.vref.clear();
    if (!this.hiden) this.vref.createEmbeddedView(this.tref);
    else {
      if (this.tenancyElse) this.vref.createEmbeddedView(this.tenancyElse);
      else this.vref.clear();
    }
  }
}
