import {
  Component,
  contentChildren,
  EventEmitter,
  HostBinding,
  input,
  Output,
  TemplateRef
} from '@angular/core';
import { TabComponent } from '../tab/tab.component';

@Component({
  selector: 'tabs',
  templateUrl: './tabs.component.html',
  host: { class: 'flex flex-wrap items-center justify-start' }
})
export class TabsComponent {
  tabs = contentChildren(TabComponent);
  bar = input<TemplateRef<any>>();
  activeClass = input('tab-active');

  @HostBinding('class.vertical')
  vertical = input(false);

  @HostBinding('class.reverse')
  reverse = input(false);

  @Output()
  selected?: EventEmitter<number> = new EventEmitter<number>();

  active(tab: TabComponent, index: number) {
    this.tabs()?.forEach((t) => t.active(false));
    tab.active(true, this.activeClass() || '');
    this.selected.emit(index);
  }
}
