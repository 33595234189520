import { Entity } from 'src/app/core/models/base';
import { User } from 'src/app/core/models/user';

export class Tenant extends Entity<Tenant> {
  name: string;
  host?: User;
  demo?: boolean;
  root?: boolean;
  active?: boolean;
  seeded?: boolean;
  parent?: string;
  extras?: { [key: string]: any };
  customization?: { [key: string]: any };
  parentConfig?: { [key: string]: any };

  get isRoot(): boolean {
    return !!(this.root && !this.parent);
  }

  constructor(data: Partial<Tenant> = {}) {
    super(data);
    this.name = data.name || '';
    this.host = data.host ? new User(data.host) : null;
    this.demo = data.demo || false;
    this.active = data.active || true;
    this.seeded = data.seeded || false;
    this.parent = data.parent || null;
    this.customization = data.customization || {};
    this.parentConfig = (data as any).parent_config || {};
    this.root = data.root || false;
  }
}
