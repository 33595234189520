<section *ngIf="title" class="list-header">
  <h2 class="text-xl text-primary">{{ title }}</h2>
  <i *ngIf="ref" class="btn-close text-2xl ri-close-line" (click)="ref?.close()"></i>
</section>
<section class="list-topbar">
  <div class="control !px-4 w-full md:w-[unset]" *ngIf="!hideSearch">
    <input
      #input
      class="input"
      placeholder="Buscar..."
      [(ngModel)]="search"
      (input)="emitSearch(input.value)"
    />
    <i class="ri-search-line"></i>
  </div>
  <div class="flex items-center gap-2 ml-auto" *ngIf="btns; else topbar">
    <i
      *ngFor="let btn of btns | keyvalue"
      class="{{ btn.value }} text-xl cursor-pointer p-2"
      (click)="emitExec(btn.key)"
    ></i>
  </div>
  <ng-template #topbar>
    <ng-content select="[topbar]"> </ng-content>
  </ng-template>
</section>
<section class="list-items" *wait="items; empty: empty; value as items">
  <ng-container *ngFor="let item of items ?? []; index as index; trackBy: track">
    <ng-container *ngIf="template; else contentTemplate">
      <ng-container
        [ngTemplateOutlet]="template"
        [ngTemplateOutletContext]="{ item, index, instance: this }"
      ></ng-container>
    </ng-container>
    <ng-template #contentTemplate>
      <ng-container
        [ngTemplateOutlet]="templateContent"
        [ngTemplateOutletContext]="{ item, index, instance: this }"
      ></ng-container>
    </ng-template>
  </ng-container>
</section>
<ng-template #empty>
  <div class="placeholder">
    <ng-content select="[placeholder]"></ng-content>
  </div>
  <span class="placeholder-default p-4 pt-0 text-neutral text-opacity-60"> Nada para mostrar </span>
</ng-template>
