import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Entity } from 'src/app/core/models/base';

export class Booking extends Entity<Booking> {
  accommodation_id: number;
  client: any;
  arrival_date: string;
  departure_date: string;

  code: string;
  date: string;
  type: string;
  localizator: string;
  status: string;
  occupants: any;
  amounts: any;
  origin_id: string;
  custom_data: string;
  checkin_online: string;
  checkin_schedule: string;
  checkout_schedule: string;
  provider_data: string;

  constructor(data?: Partial<Booking>) {
    super(data);
    Object.assign(this, data);
  }

  static formObject(data?: Partial<Booking>) {
    const { client, occupants, amounts } = data || {};
    return {
      accommodation_id: [data?.accommodation_id, [Validators.required]],
      arrival_date: [data?.arrival_date, [Validators.required]],
      departure_date: [data?.departure_date, [Validators.required]],
      localizator: [data?.localizator, [Validators.required]],
      type: [data?.type, [Validators.required]],
      status: [data?.status, [Validators.required]],
      client: new UntypedFormGroup({
        name: new UntypedFormControl(client?.name),
        surname: new UntypedFormControl(client?.surname),
        dni: new UntypedFormControl(client?.dni),
        email: new UntypedFormControl(client?.email),
        phone: new UntypedFormControl(client?.phone)
      }),
      occupants: new UntypedFormGroup({
        adults: new UntypedFormControl(occupants?.adults),
        children: new UntypedFormControl(occupants?.children)
      }),
      amounts: new UntypedFormGroup({
        RentalPrice: new UntypedFormControl(amounts?.RentalPrice)
      })
    };
  }
}
