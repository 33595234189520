import { BehaviorSubject, map } from 'rxjs';

export class GlobalStore extends Object {
  #data = new Map<string, any>();
  #subject = new BehaviorSubject<Map<string, any>>(this.#data);

  patch(data: Record<string, any>) {
    Object.entries(data).forEach(([key, value]) => {
      let current = this.#data.get(key);
      if (typeof value === 'object' && !Array.isArray(value)) {
        if (typeof current !== 'object') current = {};
        value = { ...(current ?? {}), ...value };
      }
      this.#data.set(key, value);
    });
    this.#subject.next(this.#data);
  }

  deep<T = any>(deepkey: string, defaultValue: T = null): T {
    const elses = deepkey.split(':');
    let result = defaultValue;
    for (const path of elses) {
      const keys = path.split('.');
      const base = this.#data.get(keys.shift());
      const value = keys.reduce((p, k) => (p || {})[k], base);
      result = value ?? defaultValue;
      if (result !== defaultValue) break;
    }
    return result;
  }

  deepSubscribe<T = any>(deepkey: string, defaultValue: T = null) {
    return this.#subject
      .asObservable()
      .pipe(map(() => this.deep<T>(deepkey, defaultValue)));
  }
}
