<input
  #input
  [id]="id + '-control'"
  type="file"
  [accept]="accept"
  hidden
  [readonly]="readonly"
  [multiple]="multiple"
  (change)="changeValue($event)"
/>
<div class="files-details flex flex-col justify-stretch items-start">
  <div class="flex flex-row items-center justify-center gap-1" *ngIf="!multiple">
    <span> {{ _value?.name || '' }}</span>
    <span *ngIf="_value?.size"> ({{ ((_value?.size || 0) / 1000).toFixed(2) }}&nbsp;kB) </span>
  </div>
  <div class="flex flex-row items-center justify-center gap-1" *ngIf="multiple">
    <span> {{ viewValue?.length ?? 0 }} </span>
    <span> Archivos{{ viewValue?.length ? 's' : '' }} </span>
    <span>({{ ((totalSize || 0) / 1000).toFixed(2) }}&nbsp;kB)</span>
  </div>
</div>
<label [for]="id + '-control'" class="file-button gap-2" *ngIf="!readonly">
  <i class="ri-attachment-2">
    <i class="ri-add-line font-extrabold text-xs"></i>
  </i>
</label>
