@if (!loading()) {
  <div class="ticket-header">
    <h2>Archivos ({{ files?.length }})</h2>
    <button class="btn btn-icon btn-close ml-auto" (click)="ref.close()">
      <i class="ri-close-line !text-white !font-light"></i>
    </button>
  </div>

  <div class="flex flex-col overflow-y-auto">
    <app-attachments [readonly]="readonly" [attachments]="files" size="lg"></app-attachments>
  </div>
} @else {
<div class="flex justify-center items-center flex-1">
  <i class="ri-loader-2-line animate-spin text-2xl text-primary"></i>
</div>
}