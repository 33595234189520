import { Directive, HostBinding, Injector, Input, inject } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor as CVA,
  NgControl,
  ValidationErrors,
  Validator
} from '@angular/forms';

@Directive()
export abstract class AppControl<T = any> implements CVA, Validator {
  protected onChange = (...args: any[]) => {};
  protected onTouched = (...args: any[]) => {};
  protected onValidationChange = (...args: any[]) => {};

  #injector = inject(Injector);

  #value: T;

  get errors() {
    const control = this.#injector.get(NgControl, null, { optional: true });
    return control?.errors;
  }

  get touched() {
    const control = this.#injector.get(NgControl, null, { optional: true });
    return control?.touched;
  }

  @Input()
  @HostBinding('attr.disabled')
  protected disabled?: boolean;

  @Input()
  key?: string;

  set value(val: T) {
    this.writeValue(val);
  }

  get value(): T {
    return this.#value;
  }

  get empty() {
    if (Array.isArray(this.#value)) return !this.#value?.length;
    return !(this.value ?? false);
  }

  constructor() {
    const onInit = this.constructor.prototype.ngOnInit as Function;
    this.constructor.prototype.ngOnInit = function (...args: any[]) {
      if (this.key) {
        const value = localStorage.getItem(this.key);
        if (value) this.value = JSON.parse(value);
      }
      onInit?.apply(this, args);
    };
    const afterViewInit = this.constructor.prototype.ngAfterViewInit;
    this.constructor.prototype.ngAfterViewInit = function (...args: any[]) {
      this.refresh();
      afterViewInit?.apply(this, args);
    };
  }

  refresh() {}

  writeValue(val: T) {
    this.#value = val;
    this.onTouched();
    this.onChange(this.#value);
    if (this.key) {
      localStorage.setItem(this.key, JSON.stringify(this.value));
      if (this.value === null || this.value === undefined)
        localStorage.removeItem(this.key);
    }
    this.refresh();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn || (() => {});
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn || (() => {});
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return null;
  }

  registerOnValidatorChange(fn: () => void) {
    this.onValidationChange = fn;
  }
}
