import { HttpClient } from '@angular/common/http';
import { DestroyRef, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { environment } from '@environments/environment';
import {
  Observable,
  Subject,
  filter,
  map,
  of,
  takeUntil,
  tap
} from 'rxjs';
import { eventSource } from '../helpers/events.helpers';
import { OnAppInit } from '../interfaces/init.interface';
import { AppService } from './app.service';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class EventsService implements OnAppInit {
  #app = inject(AppService);
  #http = inject(HttpClient);
  #auth = inject(AuthService);
  #destroyRef = inject(DestroyRef);
  #main = new Subject<any>();
  listening = false;

  get ping$() {
    return this.#main.asObservable().pipe(
      filter((e) => e.type === 'ping'),
      map((e) => e.data)
    );
  }

  get event$() {
    return this.#main.asObservable().pipe(
      filter((e) => e.type === 'event'),
      map((e) => e.data)
    );
  }

  ngOnAppInit(): Observable<any> {
    this.#app
      .on('app:login')
      .pipe(
        filter(() => !this.listening),
        takeUntilDestroyed(this.#destroyRef)
      )
      .subscribe(({ payload }) => this.listen(payload));

    return of(true);
  }

  listen(payload: any) {
    const url = `${environment.apiUrl}/communications/events`;
    const events = ['ping', 'event'];
    const params = {
      tenant: this.#app.globals.deep('tenancy.tenant.id'),
      access_token: payload?.token
    };

    eventSource(url, { params, events })
      .pipe(
        takeUntil(this.#app.on('app:logout')),
        takeUntilDestroyed(this.#destroyRef),
        tap({
          next: () => (this.listening = true),
          error: () => (this.listening = false),
          complete: () => (this.listening = false)
        })
      )
      .subscribe((event) => this.#main.next(event));
  }

  emit(message: any) {
    const url = `${environment.apiUrl}/communications/events`;
    return this.#http.post(url, { message });
  }

  on(...patterns: string[]) {
    const params = {
      patterns: JSON.stringify(patterns),
      access_token: this.#auth.token,
      tenant: this.#app.globals.deep('tenancy.tenant.id')
    };
    const url = `${environment.apiUrl}/communications/events/subscribe`;
    const config = { params, events: ['ping', 'event'] };

    return eventSource(url, config);
  }
}
