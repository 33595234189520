import { Component, Input, OnInit, Optional } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DialogRef } from '@mukhuve/ngx/dialog';
import { ResourcesService } from 'src/app/legacy/services/resources.service';
import { Accommodation } from '../../models/accommodation';

@Component({
  selector: 'app-accommodation-form',
  templateUrl: './accommodation-form.component.html',
  styleUrls: ['./accommodation-form.component.scss']
})
export class AccommodationFormComponent implements OnInit {
  @Input() data?: Accommodation;

  form: UntypedFormGroup;
  packs: any[] = [];
  address: any;

  constructor(
    private resources: ResourcesService,
    private fb: UntypedFormBuilder,
    @Optional() public ref?: DialogRef
  ) {}

  ngOnInit() {
    const controls = Accommodation.formObject(this.data);
    this.form = this.fb.group(controls);

    this.resources
      .lite('/inventory/packs')
      .subscribe((resp) => (this.packs = resp as any));
  }

  placeChanged(place: google.maps.places.PlaceResult) {
    const component = place.address_components.find((c) =>
      c.types.includes('locality')
    );
    const city = component?.short_name || 'Madrid';
    this.address = place.formatted_address;
    const coords = place.geometry.location.toJSON();
    this.form.patchValue({ coords, city });
  }

  submit() {
    const address_detail = this.form.value.address_detail;
    const address = `${this.address}, ${address_detail}`;
    this.form.patchValue({ address });
    this.ref?.emit(this.form.value);
  }
}
