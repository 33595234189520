import { AppControl } from '@/ux/models/control';
import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  forwardRef
} from '@angular/core';
import {
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-checklist-action',
  standalone: true,
  imports: [CommonModule, MatTooltipModule, FormsModule],
  templateUrl: './checklist-action.component.html',
  styleUrls: ['./checklist-action.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ChecklistActionComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ChecklistActionComponent),
      multi: true
    }
  ]
})

export class ChecklistActionComponent
  extends AppControl<any[]>
  implements OnInit {

  @ViewChild('container') container: ElementRef;
  @Input() tasks: any[] = [];
  @Output() tasksChange = new EventEmitter<any[]>();

  ngOnInit() { }

  onTaskTitleChange(event: Event, task: any) {
    const inputElement = event.target as HTMLElement;
    task.title = inputElement.innerText.trim();
    this.tasksChange.emit(this.tasks);
  }

  add(title: string) {
    const task = { title };
    const el = this.container.nativeElement;

    if (!this.tasks) this.tasks = [];
    if (this.tasks.find((t) => t.title === title)) return;
    this.tasks.push(task);
    this.tasksChange.emit(this.tasks);

    setTimeout(() => (el.scrollTop = el.scrollHeight));
  }

  remove(task: any) {
    const index = this.tasks.findIndex((t) => t.title === task.title);
    if (index !== -1) {
      this.tasks.splice(index, 1);
      this.tasksChange.emit(this.tasks);
    }
  }
}
