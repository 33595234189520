import { UntypedFormGroup, ValidatorFn } from '@angular/forms';

import { Observable, catchError, map, of } from 'rxjs';
import { ResourcesService } from '../../services/resources.service';
import { Resource } from '../resource/resource';

export type RuleSentence =
  | 'read-only'
  | 'no-list'
  | 'no-form'
  | 'no-show'
  | 'mandatory'
  | 'no-sort'
  | 'no-click'
  | 'no-modal'
  | 'no-search';

export interface RuleControlOption {
  text: string;
  value: any;
  icon?: string;
  object?: any;
  color?: string;
}

export type RuleOptionsFn = (
  control: RuleControl,
  service: ResourcesService
) => Observable<RuleControlOption[]>;

export interface RuleControlDef {
  type?: string;
  validators?: ValidatorFn[];
  options?:
    | RuleControlOption[]
    | Resource
    | Observable<RuleControlOption[]>
    | RuleOptionsFn;
  lite?: boolean;
  multiple?: boolean;
  accept?: string;
  optimize?: boolean;
  visible?: (form: UntypedFormGroup) => boolean;
  onChangeRule?: (control: RuleControl, form: UntypedFormGroup) => void;
  object?: any[];
  listParams?: { [param: string]: number | boolean | string | any[] };
  valueReader?: (item: any) => any;
}

export class RuleControl {
  type?: string;
  validators?: ValidatorFn[];
  options?: Observable<RuleControlOption[]>;
  lite?: boolean;
  multiple?: boolean;
  accept?: string;
  optimize?: boolean;
  visible?: (form: UntypedFormGroup) => boolean;
  onChangeRule?: (control: RuleControl, form: UntypedFormGroup) => void;
  object?: any[];
  listParams?: { [param: string]: number | boolean | string | any[] };
  valueReader?: (item: any) => any;

  constructor(
    public key: string,
    public title: string,
    public service: ResourcesService,
    def: RuleControlDef = {}
  ) {
    this.type = def.type || 'text';
    this.validators = def.validators || [];
    this.lite = def.lite ?? true;
    this.multiple = def.multiple ?? false;
    this.accept = def.accept || '*';
    this.optimize = def.optimize ?? true;
    this.visible = def.visible || (() => true);
    this.valueReader = def.valueReader || ((item: any) => item[key] ?? null);
    this.onChangeRule = def.onChangeRule || (() => {});
    this.object = def.object ?? [];
    this.listParams = def.listParams || {};

    this.options = new Observable<RuleControlOption[]>((observer) => {
      const optionsDef = def.options;

      let obs$: Observable<RuleControlOption[]>;
      if (optionsDef instanceof Resource) {
        const request$ = service?.lite(optionsDef.endpoint, {
          params: this.listParams
        });

        obs$ = request$.pipe(
          map((d) => d?.map(optionsDef.toOption).filter((o) => !!o) ?? [])
        );
      } else if (optionsDef instanceof Observable) {
        obs$ = optionsDef;
      } else if (typeof optionsDef === 'function') {
        obs$ = optionsDef(this, service);
      } else {
        obs$ = of(optionsDef);
      }

      obs$
        .pipe(
          catchError((err) => {
            observer.error(err);
            return [];
          })
        )
        .subscribe((opts) => {
          observer.next(opts);
          observer.complete();
        });
    });
  }

  isType(type: string | string[]): boolean {
    if (Array.isArray(type)) return type.includes(this.type);
    return this.type === type;
  }
}

export interface RuleParams {
  title: string;
  icon?: string;
  sentences?: RuleSentence[];
  control?: RuleControlDef;
}
