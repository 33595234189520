<div class="related-header">
  <div class="flex items-center gap-3">
    <div class="input flex items-center min-w-full flex-1 flex-wrap sm:min-w-0 sm:flex-initial">
      <input name="filter" class="bg-transparent flex-1 min-w-0" placeholder="Filtrar..." (input)="onSearch()"
        [(ngModel)]="search" />
      <i class="ri-search-2-line"></i>
    </div>
    <button matTooltip="Nuevo ticket relacionado" class="btn btn-icon bg-transparent shadow-none">
      <i class="ri-add-line text-info !text-2xl" (click)="create()"></i>
    </button>
  </div>
  <button class="btn btn-icon  ml-auto btn-close bg-transparent shadow-none" (click)="ref.close()">
    <i class="ri-close-line !text-2xl"></i>
  </button>
</div>

<div class="related" *wait="tickets$; value as tickets">
  <div class="items">
    @for (ticket of tickets; track ticket.id) {
    <app-ticket-card [ticketId]="ticket.id" [ticket]="ticket"></app-ticket-card>
    }
  </div>
</div>