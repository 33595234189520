import localforage from 'localforage';
import { from } from 'rxjs';

export class Session<U = any> {
  constructor(public readonly token: string, public readonly user: U) {}

  toString() {
    return JSON.stringify(this);
  }

  toLocalStore() {
    localStorage.setItem('app:session', this.toString());
  }

  toLocalForage() {
    return from(localforage.setItem('session', this));
  }
}
