<h4 class="title"> Asignar host para <b>{{ data?.name || 'Alojamiento' }}</b></h4>

<form [formGroup]="form">
    <div class="form">
        <div class="form__control">
            <div>
                <app-select formControlName="host_id">
                    <app-option [value]="null">
                        <span> Sin asignar </span>
                    </app-option>
                    <app-option *ngFor="let assignment of assignments || []" [value]="assignment.id">
                        {{ assignment?.name }} {{ assignment?.surname}}
                    </app-option>
                </app-select>
            </div>
        </div>
    </div>

    <div class="form__actions">
        <button class="color-neutral-200" (click)="ref?.close()">Cancelar</button>
        <button [disabled]="form.invalid" class="color-primary" (click)="submit()">Guardar</button>
    </div>
</form>