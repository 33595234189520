import { Component, input } from '@angular/core';

@Component({
  selector: 'form-control',
  standalone: true,
  imports: [],
  templateUrl: './form-control.component.html',
  styleUrl: './form-control.component.scss'
})
export class FormControlComponent {
  name = input.required<string>();
  label = input<string>();
}
