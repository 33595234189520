import {
  AfterContentInit,
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { GateService } from '../services/gate.service';

@Directive({ standalone: true, selector: '[can]' })
export class CanDirective implements AfterContentInit {
  private result?: boolean;

  @Input() canElse?: TemplateRef<any>;
  @Input()
  set can(sentence: string) { 
    this.result = this.gate.can(sentence);
    if (this.result) this.vref.createEmbeddedView(this.tref);
    else this.vref.clear();
  }

  constructor(
    private tref: TemplateRef<any>,
    private vref: ViewContainerRef,
    private gate: GateService
  ) {}

  ngAfterContentInit() {
    if (!this.result && this.canElse) {
      this.vref.createEmbeddedView(this.canElse);
    }
  }
}
