import { Entity } from '@core/models/base';
import { Task } from './task';

export class Checklist extends Entity {
  name: string;
  description: string;
  tasks: Task[];

  constructor(data?: Partial<Checklist>) {
    super(data);
    Object.assign(this, data);

    if (!this.tasks) {
      this.tasks = [];
    }
  }
}
