<form [formGroup]="form" *ngIf="form" autocomplete="off" (keydown.enter)="$event.preventDefault()">
  <h4 class="form__title">
    <ng-container *ngIf="data?.id; else create"> Actualizar alojamiento </ng-container>
    <ng-template #create>Crear nuevo alojamiento</ng-template>
  </h4>
  <app-stepper #stepper>
    <cdk-step label="Informacion basica">
      <div class="form">
        <div class="form__control">
          <label> Nombre* </label>
          <input class="input" type="text" formControlName="name" />
        </div>
        <div class="form__control">
          <label> Direccion* </label>
          <input
            class="input"
            type="text"
            formControlName="address"
            placeholder="Calle 123, Ciudad, Pais"
            [defaultValue]="''"
            placesAutocomplete
            (place)="placeChanged($event)"
          />
        </div>
        <div class="form__control">
          <label> Direccion (+) </label>
          <input
            class="input"
            type="text"
            placeholder="Apartamento, piso, etc."
            formControlName="address_detail"
            [defaultValue]="''"
          />
        </div>
        <div class="form__control">
          <label> Nota </label>
          <textarea class="textarea" formControlName="tricks"></textarea>
        </div>
      </div>
    </cdk-step>
    <cdk-step label="Distribucion">
      <div class="form" formGroupName="distribution">
        <div class="form__control">
          <label> Capacidad </label>
          <input class="input" type="number" formControlName="capacity" />
        </div>
        <div>
          <div class="form__control">
            <label> Dormitorios </label>
            <input class="input" type="number" formControlName="bedrooms" />
          </div>
          <div class="form__control">
            <label> Baños </label>
            <input class="input" type="number" formControlName="toilets" />
          </div>
        </div>
        <div class="form__control">
          <label> Camas matrimoniales </label>
          <input class="input" type="number" formControlName="doublebeds" />
        </div>
        <div class="form__control">
          <label> Camas individuales </label>
          <input class="input" type="number" formControlName="singlebeds" />
        </div>
        <div class="form__control">
          <label> Sofacamas </label>
          <input class="input" type="number" formControlName="sofabeds" />
        </div>
      </div>
    </cdk-step>
    <cdk-step>
      <div class="form__control">
        <label> Pack </label>
        <app-select formControlName="pack_id">
          <app-option [value]="null"> Sin Pack </app-option>
          <app-option *ngFor="let pack of packs || []" [value]="pack.id">
            {{ pack?.name }}
          </app-option>
        </app-select>
      </div>
      <!-- TODO: pictures -->
    </cdk-step>
  </app-stepper>

  <div class="form__actions">
    <button class="color-neutral-200" (click)="ref?.close()">Cancelar</button>
    <span class="spacer"></span>
    <button *ngIf="stepper.selectedIndex > 0" class="color-neutral-200" (click)="stepper.previous()">
      Atrás
    </button>
    <button
      *ngIf="stepper.selectedIndex < stepper.steps?.length - 1"
      class="color-info"
      [disabled]="form.invalid"
      (click)="stepper.next()"
    >
      Siguiente
    </button>
    <button [disabled]="form.invalid" (click)="submit()" matTooltip="Enviar" class="color-success">
      <i class="ri-send-plane-fill"></i>
    </button>
  </div>
</form>
