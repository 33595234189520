@if (title) {
  <h2 class="text-2xl text-primary">{{ title }}</h2>
}
@if (type === 'daterange' || type === 'date') {
  <app-date [range]="type === 'daterange'" [(ngModel)]="value" [placeholder]="placeholder">
  </app-date>
} @else {
  <minput [type]="type" [(ngModel)]="value">
    @if (type === 'select' && options?.length) {
      <app-option
        *ngFor="let option of options"
        [icon]="option.icon"
        [value]="option.value"
        [color]="option.color"
        [classes]="option.classes"
      >
        {{ option.label ?? option.value }}
      </app-option>
    }
  </minput>
}
<div class="flex flex-row items-center justify-end gap-2">
  <button class="btn btn-neutral-200" (click)="ref?.close()">
    <i class="ri-close-line"></i>
  </button>
  <button class="btn btn-success text-white" (click)="ref?.close(value)">
    <i class="ri-check-line"></i>
  </button>
</div>
