import { QuillModules } from 'ngx-quill';
import { AutocompleteField } from './autocomplete-field';

export const autocompleteFields: AutocompleteField[] = [
  { name: 'Cliente: DNI', value: '{client.dni}' },
  { name: 'Cliente: Email', value: '{client.email}' },
  { name: 'Cliente: Nombre', value: '{client.name}' },
  { name: 'Cliente: Apellido', value: '{client.surname}' },
  { name: 'Cliente: Ciudad', value: '{client.city}' },
  { name: 'Cliente: País', value: '{client.country}' },
  { name: 'Cliente: Teléfono', value: '{client.phone}' },
  { name: 'Alojamiento: Nombre', value: '{accommodation.name}' },
  { name: 'Alojamiento: Ciudad', value: '{accommodation.city}' },
  { name: 'Alojamiento: Dirección', value: '{accommodation.address}' },
  { name: 'Alojamiento: Trucos', value: '{accommodation.tricks}' },
  { name: 'Reserva: Localizador', value: '{booking.localizator}' },
  { name: 'Reserva: Fecha', value: '{booking.date}' },
  { name: 'Reserva: Fecha de llegada', value: '{booking.arrival_date}' },
  { name: 'Reserva: Fecha de salida', value: '{booking.departure_date}' },
  { name: 'Reserva: Enlace del canal', value: '{booking.channel_link}' }
];

export const editorModules: QuillModules = {
  magicUrl: true,
  toolbar: [
    [/* { font: [] },  */ { size: [] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ color: [] }, { background: [] }],
    // [{ script: 'super' }, { script: 'sub' }],
    [{ header: 1 }, { header: 2 }, 'blockquote', 'code-block'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' }
    ],
    ['direction', { align: [] }],
    ['link', 'image', 'video' /* 'formula' */],
    ['clean']
  ],
  mention: {
    positioningStrategy: 'fixed',
    defaultMenuOrientation: 'top',
    mentionDenotationChars: ['{'],
    allowedChars: /^[A-Za-z\sáéíóúñ]*$/,
    showDenotationChar: false,
    source: (term: string, render: Function) => {
      let items = [...(autocompleteFields || [])];
      if (term && items) {
        const search = term.toLowerCase();
        items = items.filter(({ name }: AutocompleteField) => {
          return name.toLowerCase().search(search) != -1;
        });
      }
      render(items.slice(0, 20), term);
    },
    renderItem: (item: AutocompleteField) => item.name
  }
};
