<div class="bg-success icon rounded-full">
  <i [class]="ticket?.icon"></i>
</div>
<div class="info">
  <h4 class="title">{{ ticket.action }} #{{ ticket.id }}</h4>
  <span class="accommodation">
    {{ ticket.accommodation }}
  </span>
  <small class="date">{{ ticket.date | date: 'MMM d, H:mm' }}</small>
</div>
