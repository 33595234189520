import { pathFileType } from "@/core/helpers/files-helper";
import { Attachment } from "@/core/models/attachment";
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { AppService } from "@/core/services/app.service";
import { CommonModule } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Component, inject, input, OnInit, signal } from "@angular/core";
import { environment } from "@environments/environment";
import { map, Subject } from "rxjs";
import { AttachmentsComponent } from "../attachments/attachments.component";

@Component({
  standalone: true,
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss'],
  imports: [CommonModule, AttachmentsComponent],
})
export class FilesComponent implements OnInit {
  #http = inject(HttpClient);
  #app = inject(AppService);
  ref = inject(DialogRef);
  data = inject(DIALOG_DATA);
  id = input<number | string>(this.data.id);

  loading = signal(false);
  readonly: boolean = true;

  files: Attachment[] = [];

  constructor() {}

  ngOnInit() {
    this.loading.set(true);
    this.#http
      .get(`//api/tickets/${this.id()}`)
      .pipe(
        map((res) => this.getFiles(res)) 
      )
      .subscribe({
        next: (files) => {
          this.files = files; 
          this.loading.set(false);
        },
      });
  }

  getFiles(ticket: any): any[] {
    return this.parseAttachments(ticket?.observations || []);
  }

  parseAttachments(observations: any[] = []) {
    const uri = environment.apiUrl;
    const globals = this.#app.globals;
    const tenant = globals.deep('tenancy.select.id:tenancy.tenant.id');

    return observations?.reduce((p, c) => {
      const { id, name, file, files } = c;
      if (file) {
        const type = pathFileType(file);
        p.push({ id, name, src: `${uri}${file}?tenant=${tenant}`, type });
      } else if (files) {
        const attachs = files?.map((p) => ({
          id,
          name,
          src: `${uri}${p}?tenant=${tenant}`,
          type: pathFileType(p)
        }));
        p = p.concat(attachs || []);
      }
      return p;
    }, []);
  }
}