<video
  src="/assets/videos/background.mp4"
  autoplay
  loop
  muted
  class="w-full h-full fixed z-0 pointer-events-none object-cover"
></video>
<div class="fixed top-0 left-0 w-full h-full bg-neutral-300/40 z-[1]"></div>
<span class="flex flex-col animate-fade-down fixed top-4 left-4 z-[10]">
  <small class="text-white/60 font-semibold -mb-2 text-xs">Powered by</small>
  <img class="w-40" src="/assets/images/logo-full.png" alt="logo" />
</span>
<section class="login-content">
  <h2 class="font-semibold text-6xl text-primary self-center md:self-start">Kairós</h2>
  <form #formEl class="flex-initial" [formGroup]="form" (ngSubmit)="login()">
    <form-control label="Correo" name="email">
      <input class="input !bg-white/50" formControlName="email" type="email" />
    </form-control>
    <form-control label="Contraseña" name="password">
      <input class="input !bg-white/50" formControlName="password" type="password" />
    </form-control>
    <div class="form__actions">
      <app-button
        type="submit"
        class="btn-primary rounded-full w-full md:w-48 self-center"
        [block]="true"
        [disabled]="form.invalid"
      >
        <span class="font-semibold">Iniciar Sesión</span>
      </app-button>
    </div>
  </form>
</section>
