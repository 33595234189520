<app-select class="flex-1" [(ngModel)]="selecteds.month" (ngModelChange)="submit()">
  <app-option [value]="0"> Enero </app-option>
  <app-option [value]="1"> Febrero </app-option>
  <app-option [value]="2"> Marzo </app-option>
  <app-option [value]="3"> Abril </app-option>
  <app-option [value]="4"> Mayo </app-option>
  <app-option [value]="5"> Junio </app-option>
  <app-option [value]="6"> Julio </app-option>
  <app-option [value]="7"> Agosto </app-option>
  <app-option [value]="8"> Septiembre </app-option>
  <app-option [value]="9"> Octubre </app-option>
  <app-option [value]="10"> Noviembre </app-option>
  <app-option [value]="11"> Diciembre </app-option>
</app-select>
<app-select class="flex-1" [(ngModel)]="selecteds.year" (ngModelChange)="submit()">
  <app-option [value]="year - 1"> {{ year - 1 }} </app-option>
  <app-option [value]="year"> {{ year }} </app-option>
  <app-option [value]="year + 1"> {{ year + 1 }} </app-option>
</app-select>
