import {
  Component,
  ContentChildren,
  ElementRef,
  forwardRef,
  OnChanges,
  QueryList,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Option } from '../../directives/option.directive';
import { AppOptionControl } from '../../models/option-control';
import { NgClass } from '@angular/common';

@Component({
  standalone: true,
  selector: 'app-toggler',
  templateUrl: './toggler.component.html',
  styleUrls: ['./toggler.component.scss'],
  imports: [NgClass],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TogglerComponent),
      multi: true
    }
  ]
})
export class TogglerComponent
  extends AppOptionControl<any>
  implements OnChanges
{
  @ContentChildren(Option)
  override options: QueryList<Option> = new QueryList<Option>();

  @ViewChild('btn', { static: false })
  btn!: ElementRef<HTMLElement>;

  constructor(private rederer: Renderer2) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const el = this.btn?.nativeElement;
    el.classList.value = '';
    this.rederer.addClass(el, this.current.classes);
  }

  next() {
    if (this.index >= this.options?.length - 1) this.index = -1;
    this.value = this.options?.get(++this.index)?.value;
  }
}
