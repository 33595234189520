import { Validators } from '@angular/forms';
import { environment } from '@environments/environment';
import { Entity } from 'src/app/core/models/base';

export class Ticket extends Entity {
  date: string;
  priority: number;
  action_id: number;
  accommodation_id: number;
  accommodation: string;
  assigned_id: number;
  booking_id : number;
  assigned_image: string;
  assigned: string;
  description: string;
  title: string;
  address: string;
  checkin_schedule: string;
  checkout_schedule: string;
  action: string;
  status: string;

  constructor(data?: Partial<Ticket>) {
    super(data);
    Object.assign(this, data);

    if (this.assigned_image && !this.assigned_image.startsWith('http'))
      this.assigned_image = `${environment.apiUrl}${this.assigned_image}`;
  }

  static formObject(data?: Partial<Ticket | any>) {
    return {
      date: [data?.date, [Validators.required]],
      title: [data?.title || ''],
      priority: [data?.priority || 2],
      address: [data?.address || ''],
      action: [data?.action || ''],
      action_id: [data?.action_id, [Validators.required]],
      accommodation_id: [data?.accommodation_id],
      booking_id: [data?.booking_id],
      assigned_id: [data?.assigned_id],
      assigned: [data?.assigned],
      status: [data?.status],
      description: [data?.description],
      range_automatic: [data?.range_automatic],
      door_automatic: [data?.door_automatic],
      ticket_automatic: [data?.ticket_automatic],
      date_init: [data?.date_init],
      date_finish: [data?.date_finish]
    };
  }
}
