import { CommentsService } from '@/core/services/comments.service';
import { CanDirective } from '@/shared/directives/can.directive';
import { CdkMenu, CdkMenuItem, CdkMenuTrigger } from '@angular/cdk/menu';
import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
  inject
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { filter } from 'rxjs';
import { Comment } from 'src/app/core/models/comment';
import { AuthService } from 'src/app/core/services/auth.service';
import { UXService } from 'src/app/ux/services/ux.service';
import { CommentInputComponent } from '../comment-input/comment-input.component';
import { CommentTrigger } from '../comments.types';
import { AttachmentsComponent } from '../../attachments/attachments.component';
import { Attachment } from '@/core/models/attachment';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    CanDirective,
    CdkMenu,
    CdkMenuItem,
    CdkMenuTrigger,
    CommentInputComponent,
    FormsModule,
    AttachmentsComponent,
    MatTooltipModule
  ],
  selector: 'app-comment-message',
  templateUrl: './comment-message.component.html',
  styleUrls: ['./comment-message.component.scss']
})
export class CommentMessageComponent implements OnInit {
  #service = inject(CommentsService);
  #auth = inject(AuthService);
  #ux = inject(UXService);

  @HostBinding('class.editable')
  editable = false;

  @Input()
  readonly?:boolean = false;

  @Input()
  comment: Comment;

  @Input()
  triggers: CommentTrigger[] = [];

  @Input()
  showReply?: boolean = true;

  @Output()
  edited: EventEmitter<any> = new EventEmitter();

  @Output()
  removed: EventEmitter<any> = new EventEmitter();

  @Output() 
  reply: EventEmitter<string | number> = new EventEmitter();

  data: any;
  content: string;
  private files: Attachment[] = [];

  @HostBinding('class.itsme')
  get itsme() {
    if (this.#auth.user?.id === undefined || this.#auth.user?.id === null)
      return false;
    return this.#auth?.user?.id == this.comment?.userId;
  }

  get isEditable() {
    return this.editable && this.comment.data;
  }

  ngOnInit() {
    this.data = Object.assign({}, this.comment.data);
    this.content = this.comment.content;
    this.files = [...(this.comment.attachments || [])];
  }

  @HostListener('keyup.Escape')
  @HostListener('blur')
  restore() {
    const defaultData = { ops: [{ insert: 'Error en el mensaje' }] };
    this.data = Object.assign({}, this.comment.data || defaultData);
    this.content = this.comment.content;
    this.editable = false;
    this.comment.attachments = [...this.files]; 
  }

  edit(event?: any) {
    this.editable = false;
    event.id = this.comment.id;
    this.edited.emit(event);
  }

  removeAttachment(id: string | number) {
    const index = this.comment.attachments.findIndex(
      (attachment) => attachment.id === id
    );
    if (index !== -1) this.comment.attachments.splice(index, 1);
  }

  remove() {
    this.#ux
      .confirmUpdateOrDelete()
      .pipe(filter((confirm) => !!confirm))
      .subscribe(() => this.removed.emit(this.comment));
  }

  propagate() {
    this.#service
      .propagate(this.comment?.id)
      .subscribe(() =>
        this.#ux.notifier.success('¡Comentario propagado al entorno!')
      );
  }

  replyComment() {
    this.reply.emit(this.comment.id);
  }
}
