import {
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2
} from '@angular/core';

import {
  animationFrameScheduler,
  BehaviorSubject,
  combineLatest,
  interval,
  ReplaySubject
} from 'rxjs';

import {
  switchMap,
  map,
  takeWhile,
  endWith,
  distinctUntilChanged,
  takeUntil
} from 'rxjs/operators';

const easeOutQuad = (x: number): number => x * (2 - x);

@Directive({
  selector: '[count]'
})
export class CountUpDirective implements OnInit, OnDestroy {
  private readonly count$ = new BehaviorSubject(0);
  private readonly duration$ = new BehaviorSubject(2000);
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  private readonly currentCount$ = combineLatest([
    this.count$,
    this.duration$
  ]).pipe(
    switchMap(([count, duration]) => {
      const startTime = animationFrameScheduler.now();
      return interval(0, animationFrameScheduler).pipe(
        map(() => animationFrameScheduler.now() - startTime),
        map((elapsedTime) => elapsedTime / duration),
        takeWhile((progress) => progress <= 1),
        map(easeOutQuad),
        map((progress) => Math.round(progress * count)),
        endWith(count),
        distinctUntilChanged()
      );
    })
  );

  @Input('count')
  set count(count: number) {
    this.count$.next(count);
  }

  @Input()
  set duration(duration: number) {
    this.duration$.next(duration);
  }

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.displayCurrentCount();
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private displayCurrentCount(): void {
    this.currentCount$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((currentCount) => {
        this.renderer.setProperty(
          this.el.nativeElement,
          'innerHTML',
          currentCount
        );
      });
  }
}
