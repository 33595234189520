import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[loading]'
})
export class LoadingDirective {
  private element: HTMLElement;
  private loader: HTMLElement;
  private oposition: string;

  @Input()
  set loading(value: boolean) {
    if (value) this.lock();
    else this.unlock();
  }

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {
    this.element = this.el.nativeElement;
    this.oposition = window.getComputedStyle(this.element).position;
    this.loader = document.createElement('div');
    this.loader.innerHTML = `<i class="ri-loader-2-line animate-spin"></i>`;
    this.renderer.addClass(this.loader, 'loader');
  }

  lock() {
    this.renderer.setStyle(this.element, 'position', 'relative');
    this.element.appendChild(this.loader);
  }

  unlock() {
    this.renderer.setStyle(this.element, 'position', this.oposition);
    if (this.element.contains(this.loader)) this.element.removeChild(this.loader);
  }
}
