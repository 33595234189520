<div class="search control px-4 h-12">
  <input class="input" #input type="text" placeholder="Buscar..." [(ngModel)]="search" />
  <i
    *ngIf="multiple"
    class="ri-focus-2-line cursor-pointer"
    [class.text-info]="focus"
    (click)="focus = !focus"
  ></i>
</div>
<div class="flex flex-col overflow-auto gap-2">
  <ng-container *ngFor="let option of options | filter : search : 'label'; let i = index">
    <label
      *ngIf="!focus || option.checked"
      class="option"
      [class.active]="!multiple && option.checked"
      (click)="clickOption(option)"
    >
      <span> {{ option.label || option.value }} </span>
      <small *ngIf="option.description"> {{ option.description }} </small>
      <minput *ngIf="multiple" class="ml-auto z-50" [type]="controlType" 
        [(ngModel)]="option.checked"
        (ngModelChange)="emitChange()"
        ></minput>
      <!-- [checked]="option.checked"
        (change)="option.checked = input.checked" -->
    </label>
  </ng-container>
</div>
<div class="flex items-center justify-end gap-2" *ngIf="multiple && type() === 'modal'">
  <button class="btn btn-neutral-200" (click)="ref?.close()">Cancelar</button>
  <button class="btn btn-success text-white" (click)="submit()">
    {{ submitLabel || 'Enviar' }}
    <i class="{{ submitIcon || 'ri-check-line' }}"></i>
  </button>
</div>

