import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from './app.service';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class ErrorHandlerService implements ErrorHandler {
  #app = inject(AppService);
  auth = inject(AuthService);
  router = inject(Router);

  httpError(error: HttpErrorResponse) {
    const { status } = error;
    const valid = this.#app.globals.deep('tenancy.valid');

    if (status === 401) this.auth.cleanSession(); //.subscribe(() => location.reload());

    if (status === 402 || !valid) {
      const url = valid ? '/error/402' : '/joinme';

      this.router.navigateByUrl(url);
    }
  }

  handleError(error: any, type: 'http' | 'app' = 'app') {
    switch (type) {
      case 'http':
        this.httpError(error);
        break;
      default:
        break;
    }

    throw error;
  }
}
