<div class="stepper-header">
  <div class="stepper-header__label" *ngIf="selected?.stepLabel?.template">
    <ng-container [ngTemplateOutlet]="selected.stepLabel.template"> </ng-container>
  </div>
  <div class="stepper-header__indicators">
    <span
      *ngFor="let step of steps || []; let i = index"
      class="indicator"
      [class.visited]="selectedIndex >= i"
    ></span>
  </div>
</div>
<div class="stepper-content">
  <ng-container *ngIf="selected?.content; else noSteps" [ngTemplateOutlet]="selected?.content"></ng-container>
  <ng-template #noSteps> ... </ng-template>
</div>
