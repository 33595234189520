import { ResourcesService } from '../../services/resources.service';
import { ResourceRule } from '../rule/rule';
import { ResourceDescription } from './resource.types';

export class Resource {
  public readonly key: string;
  public readonly icon: string;
  public readonly title: string;
  public readonly endpoint: string;
  public readonly rules: { [field: string]: ResourceRule } = {};
  public readonly mapper: any;

  constructor(description: ResourceDescription) {
    const entries = Object.entries(description.rules);

    this.key = description.key;
    this.icon = description.icon || 'ri-book-3-line';
    this.endpoint = description.endpoint;
    this.title = description.title;
    this.rules = entries.reduce((acc, [k, r]) => {
      return { ...acc, [k]: new ResourceRule(r.params, k) };
    }, {});
    this.mapper = Object.values(this.rules)
      .filter((r) => !r.sentences?.includes('no-list'))
      .map(({ key, title, icon = 'information-line' }) => {
        return { key, title, icon, full: true };
      });
  }

  toOption(item: any) {
    const text = item.name || item.title || item.id;
    return { text, value: item.id };
  }

  getControls(service: ResourcesService) {
    return Object.values(this?.rules ?? {})
      .filter((r) => !r.sentences.includes('no-form'))
      .map((r) => r.getControl(service));
  }
}
