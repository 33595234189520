import { HttpClient } from '@angular/common/http';
import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import localforage from 'localforage';
import { from, Observable } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  tap,
  throwIfEmpty
} from 'rxjs/operators';
import { UXService } from 'src/app/ux/services/ux.service';
import { OnAppInit } from '../interfaces/init.interface';
import { Session } from '../models/session';
import { User } from '../models/user';
import { AppService } from './app.service';

@Injectable({ providedIn: 'root' })
export class AuthService implements OnAppInit {
  #destroyRef = inject(DestroyRef);
  app = inject(AppService);
  ux = inject(UXService);
  http = inject(HttpClient);
  router = inject(Router);

  public session?: Session;

  get user() {
    return this.session?.user;
  }

  get token() {
    return this.session?.token;
  }

  ngOnAppInit(): Observable<any> {
    const validTenant = this.app.globals.deep('tenancy.valid');

    this.app
      .on('app:inited')
      .pipe(
        takeUntilDestroyed(this.#destroyRef),
        filter(() => this.authenticated)
      )
      .subscribe(() => this.app.emit('app:login', this.session));

    return from(localforage.getItem('session')).pipe(
      filter((session) => session && validTenant && !this.session),
      throwIfEmpty(() => new Error('No Session')),
      mergeMap((session) => this.setSession(session)),
      mergeMap((session) => this.validate()),
      catchError(() => this.cleanSession())
    );
  }

  updateme(data: any) {
    return this.http.put(`//api/auth/updateme`, data);
  }

  login(credentials: any) {
    return this.http.post<any>('//api/auth/login', credentials).pipe(
      mergeMap((res) => this.setSession(res)),
      tap({
        // next: () => this.ux.notifier.success('¡Bienvenido!'),
        error: () => this.ux.notifier.error('¡Credenciales incorrectas!')
      })
    );
  }

  validate() {
    return this.http
      .get<any>(`//api/auth/validate`)
      .pipe(mergeMap((res) => this.setSession(res)));
  }

  logout() {
    this.http
      .delete(`//api/auth/logout`)
      .pipe(mergeMap(() => this.cleanSession()))
      .subscribe();
  }

  patchHeaders(data: any) {
    return from(localforage.getItem<object>('headers')).pipe(
      mergeMap((headers) =>
        from(localforage.setItem('headers', { ...headers, ...data }))
      )
    );
  }

  setSession(data: any): Observable<Session> {
    const token = data?.token || data?.access_token;
    const user = new User(data.user);
    const headers = { Authorization: `Bearer ${token}` };
    this.session = new Session(token, user);
    this.session.toLocalStore();
    this.app.globals.patch({ session: this.session });

    return this.session.toLocalForage().pipe(
      mergeMap(() => this.patchHeaders(headers)),
      map(() => this.session),
      tap(() => this.app.emit('app:login', this.session))
    );
  }

  cleanSession() {
    this.session = null;
    localStorage.removeItem('app:session');
    localforage.removeItem('session');

    return from(localforage.getItem('headers')).pipe(
      mergeMap((headers) => {
        headers = headers ?? {};
        delete headers['Authorization'];
        return from(localforage.setItem('headers', headers ?? {}));
      }),
      tap(() => this.app.emit('app:logout', this.session)),
      mergeMap(() => this.router.navigateByUrl('/auth/login'))
    );
  }

  public get authenticated() {
    return !!(this.token && this.user);
  }
}
