import {
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Renderer2
} from '@angular/core';

@Directive({ selector: '[draggable]', host: { class: 'draggable' } })
export class DraggableDirective implements OnInit {
  private static currentId: string;
  private dragger: HTMLElement;
  private point: { x: number; y: number } = { x: 0, y: 0 };
  private delta: { x: number; y: number } = { x: 0, y: 0 };

  @Input('draggable')
  data: any;

  @Input()
  anchor = false;

  @HostBinding('attr.draggable-id')
  id: string;

  @HostBinding('class.draggable--dragging')
  dragging: boolean = false;

  @HostBinding('style.transform')
  get transform() {
    return `translate3d(${this.delta.x}px, ${this.delta.y}px, 0)`;
  }

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    const el = this.el.nativeElement as HTMLElement;
    const str1 = Math.random().toString(36).substring(2, 15);
    const str2 = Math.random().toString(36).substring(2, 15);
    this.id = `${str1}-${str2}`;
    this.dragger = (el.querySelector('[dragger]') || el) as HTMLElement;

    this.renderer.setStyle(this.dragger, 'cursor', 'grab');
    this.renderer.setAttribute(this.dragger, 'draggable', 'true');
  }

  @HostListener('touchstart', ['$event'])
  @HostListener('touchend', ['$event'])
  @HostListener('dragstart', ['$event'])
  @HostListener('dragend', ['$event'])
  dragge(event: DragEvent & TouchEvent) {
    const bounds = event.targetTouches ? event.targetTouches[0] : event;
    this.dragging = event.type.endsWith('start');
    this.point = {
      x: bounds?.pageX || this.point?.x || 0,
      y: bounds?.pageY || this.point?.y || 0
    };

    DraggableDirective.currentId = this.dragging ? this.id : null;
    if (this.dragging) {
      event.dataTransfer?.setData('text', JSON.stringify(this.data));
      event.dataTransfer?.setDragImage(document.createElement('img'), 0, 0);
    } else {
      if (this.anchor) {
        this.point = { x: 0, y: 0 };
        this.delta = { x: 0, y: 0 };
      }
      event.dataTransfer?.clearData();
    }

    const cursor = this.dragging ? 'grabbing' : 'grab';
    this.renderer.setStyle(this.dragger, 'cursor', cursor);
  }

  @HostListener('document:touchmove', ['$event'])
  @HostListener('document:dragover', ['$event'])
  onDragOver(event: DragEvent & TouchEvent) {
    if (this.id !== DraggableDirective.currentId) return;
    const bounds = event.targetTouches ? event.targetTouches[0] : event;
    if (!bounds) return;

    this.delta.x += bounds.pageX - this.point!.x;
    this.delta.y += bounds.pageY - this.point!.y;
    this.point = { x: bounds.pageX, y: bounds.pageY };
  }
}
