<div #container class="tasks-container">
  <div class="task" *ngFor="let task of tasks">
    <span 
      class="flex-1 text-ellipsis overflow-hidden" 
      contenteditable="true" 
      [innerText]="task.title" 
      (blur)="onTaskTitleChange($event, task)">
    </span>
    <div class="flex items-center gap-2 ml-auto">
      <label
        class="swap swap-flip !m-0 hover:text-primary"
        [matTooltip]="task.evidence ? 'Con evidencia' : 'Sin evidencia'"
      >
        <input type="checkbox" checked="checked" [(ngModel)]="task.evidence" />
        <div class="swap-on">
          <i class="ri-file-shield-2-line text-primary"></i>
        </div>
        <div class="swap-off">
          <i class="ri-file-warning-line"></i>
        </div>
      </label>
      <button class="btn hover:text-error" [matTooltip]="'Eliminar'" (click)="remove(task)">
        <i class="ri-delete-bin-2-line"></i>
      </button>
    </div>
  </div>
  <span *ngIf="!tasks?.length" class="no-items"> No hay tareas </span>
</div>
<form #form class="flex flex-row items-stretch gap-2" (submit)="add(input.value); form.reset()">
  <input #input name="title" class="input !bg-base-200 flex-1 min-w-0" />
  <button type="submit" class="btn btn-primary">
    <i class="ri-play-list-add-line"></i>
  </button>
</form>
