import { Component, Input, OnInit, Optional, inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DialogRef } from '@mukhuve/ngx/dialog';
import { AccommodationsService } from '@pms/services/accommodations.service';
import { Observable } from 'rxjs';
import { Booking } from '../../models/booking';
import { format } from 'date-fns';

@Component({
  selector: 'app-booking-form',
  templateUrl: './booking-form.component.html',
  styleUrls: ['./booking-form.component.scss']
})
export class BookingFormComponent implements OnInit {
  @Input() data?: Partial<Booking>;

  now = format(new Date(), 'yyyy-MM-dd');
  fb = inject(UntypedFormBuilder);
  ref?: DialogRef = inject(DialogRef, { optional: true });
  accommodationsService = inject(AccommodationsService);
  accommodations$: Observable<any[]>;
  form: UntypedFormGroup;

  ngOnInit() {
    this.accommodations$ = this.accommodationsService.list(true);
    this.form = this.fb.group(Booking.formObject(this.data));
  }

  submit() {
    this.ref?.close(this.form.value);
  }
}
