import { Expense } from '@/pms/models/expense';
import { ExpensesService } from '@/pms/services/expenses.service';
import { DateComponent } from '@/ux/components/date/date.component';
import { MinputComponent } from '@/ux/components/minput/minput.component';
import { CommonModule } from '@angular/common';
import { Component, computed, ElementRef, inject, Input, OnInit, signal, ViewChild } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { DialogRef } from '@mukhuve/ngx/dialog';
import { endOfMonth, startOfMonth } from 'date-fns';
import { toBase64 } from '@/core/helpers/files-helper';

@Component({
  selector: 'app-expenses',
  standalone: true,
  imports: [ReactiveFormsModule, FormsModule, MinputComponent, CommonModule, DateComponent],
  templateUrl: './expenses.component.html',
  styleUrl: './expenses.component.scss'
})
export class ExpensesComponent implements OnInit {
  @Input() accommodationId: number;
  @Input() ticketId: number;
  @ViewChild('fileInput') fileInput: ElementRef; 

  ref?: DialogRef = inject(DialogRef, { optional: true });
  #service = inject(ExpensesService);
  isFile = signal(false);

  range = [startOfMonth(new Date()), endOfMonth(new Date())];
  expenses = signal<Expense[]>([]);
  total = computed(() => this.expenses().reduce((t, { amount }) => t + amount, 0));
  form = new FormGroup({
    amount: new FormControl(0, [Validators.required]),
    description: new FormControl('', [Validators.required]),
    date: new FormControl(new Date(), [Validators.required]),
    attachment: new FormControl('')
  });

  ngOnInit() {
    this.load();
  }

  load() {
    const [from, to] = this.range ?? [];
    const { accommodationId, ticketId } = this;

    this.#service.list({ accommodationId, ticketId, from, to }).subscribe((expenses) => {
      console.log(expenses);
      if (Array.isArray(expenses)) this.expenses.set(expenses);
      else this.expenses.set([]);
    });
  }

  add() {
    const values = this.form.value;
    const body = {
      date: values.date,
      accommodationId: this.accommodationId,
      ticketId: this.ticketId,
      amount: values.amount,
      description: values.description,
      attachment: values.attachment
    };

    this.#service.create(body).subscribe(() => {
      this.load();
      this.form.reset({ date: new Date() });
      this.isFile.set(false);
      this.fileInput.nativeElement.value = ''
    });
  }

  file($event: any) {
    const file = $event.target.files[0];
    if (file) {
      this.isFile.set(true);
      toBase64(file).subscribe((b64: any) => (
        this.form.patchValue({ attachment: b64 })
      ));
    }
  }

  remove(expense: Expense) {
    this.#service.delete(expense.id).subscribe(() => this.load());
  }
}
