import { startOfDay } from 'date-fns';
import { Subject } from 'rxjs';
import { TicketEvent } from './ticket-event';

export class CalendarEvents extends Array<TicketEvent> {
  counts: Record<string, any[]> = {};
  onRefresh: Subject<any> = new Subject();

  constructor(...items: TicketEvent[]) {
    super(...items);
    this.counts = this.reduce((prev, current) => {
      const { action_id: id, color, start } = current;
      const key = `${+startOfDay(start)}`;
      const day = prev[key] || [];
      const count = day.find((i) => i.id === id);
      if (count) count.qty++;
      else day.push({ id, qty: 1, color });

      prev[key] = day.sort((a, b) => a.id - b.id);

      return prev;
    }, {});
    this.onRefresh.next(undefined);
  }

  countsOf(date: Date) {
    const key = +startOfDay(date);
    return this.counts[key];
  }
}
