import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { fix } from '@core/helpers/object-helpers';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { mergeHeaders } from '../helpers/http.helpers';
import { AppService } from '../services/app.service';
import { ErrorHandlerService } from '../services/error-handler.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  #app = inject(AppService);
  errorHandler = inject(ErrorHandlerService);

  public error(error: HttpErrorResponse) {
    this.errorHandler.handleError(error, 'http');

    return throwError(() => error.error);
  }

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.startsWith('//')) {
      const url = `${environment.apiUrl}${request.url.slice(1)}`;
      const tenant =
        request.headers.get('x-tenant') ??
        this.#app.globals.deep('tenancy.tenant.id:tenancy.subdomain');

      if (tenant) {
        const select =
          request.headers.get('x-tenant-select') ??
          this.#app.globals.deep('tenancy.select.id');

        const tenancyHeaders = fix({
          'x-tenant': tenant,
          'x-tenant-select': select
        });
        const headers = mergeHeaders(request.headers, tenancyHeaders);
        request = request.clone({ url, headers });
      }
    }

    return next.handle(request).pipe(catchError((error) => this.error(error)));
  }
}
