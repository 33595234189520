import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Expense } from '../models/expense';
import { fix } from '@/core/helpers/object-helpers';
import { ExpensesOptions } from '../types/finance.types';
import { format } from 'date-fns';

@Injectable({ providedIn: 'root' })
export class ExpensesService {
  constructor(private http: HttpClient) {}

  list(filter?: ExpensesOptions) {
    const params = fix({
      ...filter,
      from: filter?.from ? format(filter.from, 'yyyy-MM-dd HH:mm:ss') : undefined,
      to: filter?.to ? format(filter.to, 'yyyy-MM-dd HH:mm:ss') : undefined
    });

    return this.http.get<any>(`//api/gateway/expenses`, { params });
  }

  create(data: Partial<Expense>) {
    const date = format(data.date, 'yyyy-MM-dd HH:mm:ss');
    const body = fix({ ...data, date });

    return this.http.post<any>(`//api/gateway/expenses`, body);
  }

  update(id: number | string, data: Partial<Expense>) {
    const body = fix(data);
    return this.http.put<any>(`//api/gateway/expenses/${id}`, body);
  }

  delete(id: number | string) {
    if (!id) return;
    return this.http.delete<any>(`//api/gateway/expenses/${id}`);
  }
}
