import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, catchError, tap } from 'rxjs/operators';
import { User } from 'src/app/core/models/user';
import { fix } from 'src/app/core/helpers/object-helpers';
import { UXService } from 'src/app/ux/services/ux.service';

interface UserListOptions {
  search?: string;
  exiles?: boolean;
  role_id?: number;
  role_code?: string;
  mode?: 'sm' | 'lg';
}

@Injectable({ providedIn: 'root' })
export class UsersService {
  constructor(private http: HttpClient, private ux: UXService) {}

  list(options?: UserListOptions) {
    const params = fix(options);

    return this.http
      .get<any[]>('//api/auth/users', { params })
      .pipe(map((items) => items.map((o) => new User(o))));
  }

  create(user: Partial<User>) {
    return this.http.post(`//api/auth/users`, fix(user)).pipe(
      tap({
        next: () => this.ux.notifier.success('¡Usuario creado correctamente!'),
        error: (error) => {
          let message = 'Error del sistema, no se pudo crear el usuario';
          if (error?.status === 409) {
            const role = error?.headers?.get('x-role');
            message = `Ya existe un usuario '${role}' con el correo '${user.email}'`;
          }
          this.ux.notifier.error(message);
        }
      })
    );
  }

  update(id: number | string, user: Partial<User & { ai: any }>) {
    return this.http.put(`//api/auth/users/${id}`, fix(user)).pipe(
      tap({
        next: () => this.ux.notifier.success('¡Usuario actualizado!'),
        error: () => this.ux.notifier.error('¡Error al actualizar usuario!')
      })
    );
  }

  hosts() {
    return this.http.get('//api/auth/users/hosts');
  }

  delete(id: number | string) {
    return this.http.delete(`//api/auth/users/${id}`);
  }
}
