import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  Signal,
  forwardRef
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject, debounceTime, tap } from 'rxjs';
import { AppControl } from 'src/app/ux/models/control';

const useExisting = forwardRef(() => ChecklistComponent);
const multi = true;

@Component({
  selector: 'app-checklist',
  templateUrl: './checklist.component.html',
  styleUrls: ['./checklist.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting, multi },
    { provide: NG_VALIDATORS, useExisting, multi }
  ]
})
export class ChecklistComponent extends AppControl<any[]> {
  changeSignal = new Subject<void>();
  completed: number = 0;

  @Input()
  @HostBinding('class.editable')
  editable: boolean;

  @Input()
  ticketId: any;

  @Output()
  check: EventEmitter<any> = new EventEmitter();

  @Output()
  created: EventEmitter<any> = new EventEmitter();

  @Output()
  updated: EventEmitter<any> = new EventEmitter();

  @Output()
  deleted: EventEmitter<any> = new EventEmitter();

  constructor() {
    super();

    this.calc();

    this.check.pipe(takeUntilDestroyed()).subscribe(() => this.changeSignal.next());

    this.changeSignal
      .pipe(
        tap(() => this.calc()),
        takeUntilDestroyed(),
        debounceTime(1000)
      )
      .subscribe(() => this.writeValue(this.value));
  }

  calc() {
    this.completed = this.value?.filter((t) => t.done).length || 0;
  }

  save(event: Event, task: any) {
    const span = event.target as HTMLSpanElement;
    const title = span.innerText.trim();

    event.preventDefault();
    event.stopPropagation();

    if (title && title !== task.task_title) {
      task.task_title = title;
      this.updated.emit(task);
      if (!task.id) {
        this.created.emit(task);
      }
    }

    if (event instanceof KeyboardEvent && event.key === 'Enter') {
      this.move(event, 'next');
    } else if (!title && !task.id) this.remove(task);
  }

  add(title?: string) {
    const task = {
      id: null,
      task_title: title?.trim() || '',
      ticket_id: this.ticketId,
      done: 0,
      ignored: 0,
      evidence: 0
    };
    this.writeValue([...this.value, task]);
  }

  move(event: any, rel: 'prev' | 'next' = 'next') {
    const span = event.target as HTMLSpanElement;
    const length = span.innerText.length;
    const offset = window.getSelection();
    let target: HTMLElement;

    if (rel === 'prev' && offset.anchorOffset === 0) {
      target = span.parentElement.previousElementSibling as HTMLElement;
    }

    if (rel === 'next' && (offset.anchorOffset === length || event.key === 'Enter')) {
      target = span.parentElement.nextElementSibling as HTMLElement;
      if (!target && !!span.innerText.trim() && event.key === 'Enter') {
        this.add();
        setTimeout(() => this.move(event, 'next'), 100);
      }
    }

    if (target) {
      const span = target.querySelector('span[contenteditable]') as HTMLSpanElement;
      span?.focus();
    }
  }

  remove(task: any) {
    this.writeValue(this.value.filter((t) => t !== task));
    if (task.id) {
      this.deleted.emit(task);
    }
  }
}
