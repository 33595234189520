import {
  AfterContentInit,
  Directive,
  Input,
  OnDestroy,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { Observable, Subscription, isObservable, of } from 'rxjs';
import { LoadingComponent } from '../components/loading/loading.component';

/**
 * @summary
 * Displays LoadingComponent or passed template while waiting for the observable
 * @example
 * ```html
 * <div *wait="observable$; value as post"> {{post.summary}} </div>
 * ```
 * or for more customization
 *
 * ```html
 * <ng-template #empty> no items </ng-template>
 * <ng-template #loading> Loading... </ng-template>
 * <mycomponent *wait="observable$; value as post; loader: loading; empty: empty" > {{ post.summary }} </mycomponent>
 * ```
 */
@Directive({ standalone: true, selector: '[wait]' })
export class WaitDirective implements AfterContentInit, OnDestroy {
  #state: 'empty' | 'loader' | 'content' = 'loader';
  #observable$?: Observable<any>;
  #subscription$?: Subscription;

  @Input()
  waitEmpty?: TemplateRef<any>;

  @Input()
  waitLoader?: TemplateRef<any>;

  @Input() set wait(value$: Observable<any>) {
    this.to('loader');
    this.#observable$ = value$;
    if (!isObservable(this.#observable$))
      this.#observable$ = of(this.#observable$);

    this.#subscription$ = this.#observable$?.subscribe((value) => {
      const isEmpty = Array.isArray(value) ? value.length === 0 : !value;
      const state = isEmpty && this.waitEmpty ? 'empty' : 'content';
      this.to(state, { state, value });
    });
  }

  constructor(private tref: TemplateRef<any>, private vref: ViewContainerRef) {}

  ngAfterContentInit() {
    if (this.#state !== 'content')
      this.to(this.#observable$ ? 'loader' : 'empty');
  }

  ngOnDestroy() {
    this.#subscription$?.unsubscribe();
  }

  to(state: 'empty' | 'loader' | 'content', context?: any) {
    this.vref.clear();
    this.#state = state;

    switch (this.#state) {
      case 'empty':
        this.vref.createEmbeddedView(this.waitEmpty || this.tref);
        break;
      case 'loader':
        if (this.waitLoader) this.vref.createEmbeddedView(this.waitLoader);
        else this.vref.createComponent(LoadingComponent);
        break;
      default:
        this.vref.createEmbeddedView(this.tref, context);
        break;
    }
  }
}
