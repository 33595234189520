<section class="flex flex-row gap-2">
  <button class="btn btn-icon shadow-none bg-transparent md:hidden" (click)="ref?.close()">
    <i class="ri-arrow-left-s-line text-primary !text-3xl"></i>
  </button>
  <h2 class="hidden sm:flex font-semibold text-primary text-xl">Gastos</h2>
  <app-date placeholder="Rango de fechas" class="ml-auto" [range]="true" [(ngModel)]="range" (ngModelChange)="load()" />
  <button class="btn btn-primary" (click)="load()">
    <i class="ri-refresh-line"></i>
  </button>
</section>
<section class="flex flex-col gap-2 flex-nowrap py-4">
  @for (expense of expenses(); track expense.id) {
  <div class="expense-row">
    <span class="hidden sm:flex items-center"> {{ expense.date | date: 'dd/MM/yyyy' }} </span>
    <span class="flex items-center"> {{ expense.description }} </span>
    <span class="hidden sm:flex items-center">
      @if (expense.attachment) {
        <i class="ri-file-check-fill text-xl text-primary"></i>
      }@else {
        <i class="ri-file-close-fill text-xl text-gray-400"></i>
      }
    </span>
    <span class="flex items-center text-lg font-semibold text-right text-primary">
      {{ expense.amount | number: '1.2-2' }} €
    </span>
    <button class="btn btn-error text-white ml-auto" (click)="remove(expense)">
      <i class="ri-delete-bin-line"></i>
    </button>
  </div>
  } @empty {
  <div class="flex flex-col gap-2 flex-nowrap py-4">
    <span class="text-center text-base-200">No hay gastos</span>
  </div>
  }
</section>
<div class="divider divider-base-200"></div>
<section class="flex flex-row gap-2 self-end items-center">
  <span class="font-semibold text-xl">Total</span>
  <span class="font-semibold text-2xl text-right text-success">
    {{ total() | number: '1.2-2' }} €
  </span>
</section>
<form [formGroup]="form" (ngSubmit)="add()"
  class="flex flex-col sm:flex-row items-stretch justify-end gap-2 w-full pt-8">
  <app-date placeholder="Fecha" formControlName="date" />
  <minput type="text" placeholder="Descripción" formControlName="description" class="sm:!flex-1" />
  <minput type="number" placeholder="Monto (Sin IVA)" formControlName="amount" />
  <input #fileInput type="file" hidden (change)="file($event)" />

  <button class="btn" [ngClass]="{'btn-base-200': !isFile(), 'btn-success': isFile()}"
    (click)="fileInput.click(); $event.preventDefault()">
    <i [ngClass]="{'ri-file-info-line text-primary': !isFile(), 'ri-file-check-line text-white': isFile()}"></i>
  </button>

  <button type="submit" class="btn btn-primary" [disabled]="form.invalid">
    <i class="ri-add-line"></i>
  </button>
</form>