import { Component, Input, OnInit, inject } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { forkJoin, map, mergeMap, of, toArray } from 'rxjs';
import {
  fileIsImage,
  optimizeImage,
  toBase64
} from 'src/app/core/helpers/files-helper';
import { RuleControl, RuleControlOption } from '../../objects/rule/rule.types';

@Component({
  selector: 'app-resource-form',
  templateUrl: './resource-form.component.html',
  styleUrls: ['./resource-form.component.scss']
})
export class ResourceFormComponent implements OnInit {
  #sanitizer = inject(DomSanitizer);

  @Input()
  values: object = {};

  @Input()
  controls: RuleControl[] = [];

  form: UntypedFormGroup;
  options: { [key: string]: RuleControlOption[] } = {};

  get visibles(): RuleControl[] {
    return this.controls?.filter((c) => c.visible(this.form));
  }

  ngOnInit() {
    const controls = {};
    this.values = this.values ?? {};

    for (const control of this.controls) {
      const { key, options, validators } = control;
      const value = control.valueReader(this.values);
      options?.subscribe((opts) => (this.options[key] = opts));
      controls[key] = new UntypedFormControl(value, validators);
    }

    this.form = new UntypedFormGroup(controls, Validators.required);
    this.controls
      .filter((r) => (this.values[r.key] ?? null) !== null)
      .forEach((control) => this.emitChange(control));
  }

  emitChange(control?: RuleControl) {
    this.controls
      .map((c) => c.onChangeRule)
      .forEach((h) => h && h(control, this.form));
  }

  parseFile(file: File, optimize?: boolean) {
    if (fileIsImage(file) && optimize) {
      return optimizeImage(file).pipe(mergeMap((file) => toBase64(file)));
    }

    return toBase64(file);
  }

  getSubmitData() {
    const optimizedReducer = (v, c) => ({ ...v, [c.key]: c.optimize });
    const optimized = this.controls.reduce(optimizedReducer, {});
    const entries = Object.entries(this.form.value);

    return of(...entries).pipe(
      mergeMap(([k, v]) => {
        const optimize = optimized[k] ?? false;

        if (v instanceof FileList) {
          const array = Array.from(v).map((f) => this.parseFile(f, optimize));
          return forkJoin(array).pipe(map((value) => [k, value]));
        }

        if (v instanceof File)
          return this.parseFile(v, optimize).pipe(map((value) => [k, value]));

        return of([k, v]);
      }),
      toArray<[string, any]>(),
      map((values) => {
        const reducer = (v: any, [k, value]) => ({ ...v, [k]: value });
        return values.reduce(reducer, {});
      })
    );
  }

  parseSafeHtml(value: string, prependIcon?: string) {
    if (prependIcon)
      value = `
        <i class="mat-icon notranslate material-icons mat-icon-no-color">${prependIcon}</i>
        &nbsp;${value || ''}
      `;
    return this.#sanitizer.bypassSecurityTrustHtml(value || '');
  }
}
