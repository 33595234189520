import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
  inject
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { filter } from 'rxjs';
import { WaitDirective } from '../../directives/wait.directive';
import { ListDatasource } from './list.datasource';
import { CdkMenu, CdkMenuTrigger } from '@angular/cdk/menu';
import { LoadingComponent } from '../loading/loading.component';

@Component({
  standalone: true,
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  imports: [
    CommonModule,
    WaitDirective,
    FormsModule,
    CdkMenu,
    CdkMenuTrigger,
    LoadingComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListComponent implements AfterViewInit {
  private chd = inject(ChangeDetectorRef);

  @ViewChild('container')
  private container!: ElementRef;

  @ViewChildren('item', { read: ElementRef })
  private items!: QueryList<ElementRef>;
  tracker = (i: number, el: any) => el.id;

  @Input()
  template!: TemplateRef<any>;

  @Input()
  datasource: ListDatasource = new ListDatasource();

  @Input()
  hideSearch?: boolean;

  @Input()
  hideMenu?: boolean;

  ngAfterViewInit() {
    const root = this.container.nativeElement;
    const options = { root, rootMargin: '300px', threshold: 1 };
    const observer = new IntersectionObserver((entries) => {
      const intersecting = entries.some((entry) => entry.isIntersecting);
      if (intersecting) {
        entries.forEach((entry) => observer.unobserve(entry.target));
        this.datasource?.load();
        this.chd.markForCheck();
      }
    }, options);

    this.items.changes
      .pipe(filter((items) => items.length))
      .subscribe((items) => observer.observe(items.last.nativeElement));

    this.datasource.items$.subscribe(() => this.chd.markForCheck());
    this.datasource?.load();
  }
}
