<div class="dropdown-content" cdkMenu>
  <li *can="'su'" (click)="bookings.form({ accommodation_id: item.id }).subscribe()">
    <a>
      <i class="ri-draft-line"></i>
      <span> Crear reserva </span>
    </a>
  </li>
  <ng-container *tenancy="'universal:root'">
    <li *can="'control+3,operation+2,vision+2'" (click)="ui.expenses(item?.id)">
      <a>
        <i class="ri-money-euro-circle-line"></i>
        <span> Gastos </span>
      </a>
    </li>
  </ng-container>
  <li (click)="ui.goMap(item)">
    <a>
      <i class="ri-map-pin-line"></i>
      <span> Ver en maps </span>
    </a>
  </li>
  <li (click)="formsDialog()">
    <a>
      <i class="ri-survey-line"></i>
      <span> Formularios</span>
    </a>
  </li>
  <li (click)="ui.instructions(item.id, item.instructions)">
    <a>
      <i class="ri-file-list-3-line"></i>
      <span> Instrucciones </span>
    </a>
  </li>
  <li *can="'control+3,operation+3'" (click)="ui.config(item).subscribe()">
    <a>
      <i class="ri-tools-line"></i>
      <span> Parametros </span>
    </a>
  </li>
  <ng-container *tenancy="'universal:root'">
    <li *can="'control+3,vision+2'" (click)="ui.settlement(item).subscribe()">
      <a>
        <i class="ri-file-list-3-line"></i>
        <span> Generar Liquidacion </span>
      </a>
    </li>
  </ng-container>
  <ng-container *tenancy="'universal:root'">
    <li *can="'control+3,vision+2'" (click)="ui.invoice(item).subscribe()">
      <a>
        <i class="ri-money-euro-circle-line" (click)="ui.invoice(item).subscribe()"></i>
        <span> Generar Factura </span>
      </a>
    </li>
  </ng-container>
  <li
    [cdkMenuTriggerFor]="menuEdit"
    #editTrigger="cdkMenuTriggerFor"
    (cdkMenuOpened)="subOpened(editTrigger)"
  >
    <a>
      <i class="ri-pencil-line"></i>
      <span> Editar </span>
      <i class="ri-arrow-right-s-line ml-auto"></i>
    </a>
  </li>
  <!-- <li
    [cdkMenuTriggerFor]="menuReports"
    #reportsTrigger="cdkMenuTriggerFor"
    (cdkMenuOpened)="subOpened(reportsTrigger)"
  >
    <a>
      <i class="ri-file-excel-2-line"></i>
      <span> Reportes </span>
      <i class="ri-arrow-right-s-line ml-auto"></i>
    </a>
  </li> -->
  <li *ngIf="item.active; else noactive" class="text-error" (click)="toggleActive()">
    <a>
      <i class="ri-lock-line"></i>
      <span> Desactivar </span>
    </a>
  </li>
  <ng-template #noactive>
    <button class="bg-transparent compound text-success" (click)="toggleActive()">
      <a>
        <i class="ri-lock-unlock-line"></i>
        <span> Activar </span>
      </a>
    </button>
  </ng-template>
</div>
<ng-template #menuReports>
  <div class="dropdown-content" cdkMenu>
    <li (click)="generateExpenseReport()">
      <a>
        <i class="ri-file-excel-2-line"></i>
        <span> Reporte gastos </span>
      </a>
    </li>
  </div>
</ng-template>
<ng-template #menuEdit>
  <div class="dropdown-content" cdkMenu>
    <li (click)="ui.form(item).subscribe()">
      <a>
        <i class="ri-pencil-line"></i>
        <span> Editar todo </span>
      </a>
    </li>
    <li (click)="ui.editTrick(item).subscribe()">
      <a>
        <i class="ri-file-text-line"></i>
        <span> Nota </span>
      </a>
    </li>
    <li (click)="ui.links(item)">
      <a>
        <i class="ri-gallery-line"></i>
        <span> Enlaces </span>
      </a>
    </li>
    <li (click)="ui.editPack(item).subscribe()">
      <a>
        <i class="ri-shopping-bag-3-line"></i>
        <span> Pack </span>
      </a>
    </li>
  </div>
</ng-template>
<ng-template #formTemplate let-list="instance" let-item="item" let-i="index">
  <app-form-card [data]="item" [editable]="false" (remove)="list.emitExec('delete', item)">
  </app-form-card>
</ng-template>
