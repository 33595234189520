<form [formGroup]="form">
  <app-stepper #stepper>
    <cdk-step>
      <div class="form">
        <div class="form__control">
          <label for="title"> Título </label>
          <input class="input" formControlName="title" type="text" placeholder="Título" />
        </div>
        <div>
          @if (!hidden?.action_id) {
            <div class="form__control">
              <label for="actions"> Acción </label>
              <app-select formControlName="action_id">
                @for (action of auxs.actions || []; track action.id) {
                  <app-option [value]="action.id">
                    {{ action?.name }}
                  </app-option>
                }
              </app-select>
            </div>
          }
          @if (!hidden?.accommodation_id) {
            <div class="form__control">
              <label for="accommodations"> Alojamiento </label>
              <app-select formControlName="accommodation_id">
                @for (accommodation of auxs.accommodations || []; track accommodation.id) {
                  <app-option [value]="accommodation.id">
                    {{ accommodation?.name }}
                  </app-option>
                }
              </app-select>
            </div>
          }
        </div>
        <div>
          @if(!hidden?.booking_id){
            <div class="form__control">
              <label for="bookings"> Reserva </label>
              <app-select formControlName="booking_id">
                <app-option [value]="null">
                  <span> Sin reserva </span>
                </app-option>
                @for (booking of bookings || []; track booking.id) {
                  <app-option [value]="booking.id">
                    {{ booking.info }}
                  </app-option>
                }
              </app-select>
            </div>
          }
        </div>
        <div>
          @if(!hidden?.date){
            <div class="form__control">
              <label for="date"> Fecha </label>
              <input class="input" type="datetime-local" formControlName="date" />
            </div>
          }
          @if (!hidden?.assigned_id) {
            <div class="form__control">
              <label for="assignments"> Asignar </label>
              <app-select formControlName="assigned_id">
                <app-option [value]="null">
                  <span> Sin asignar </span>
                </app-option>
                @for (assignment of auxs.assignments || []; track assignment.id) {
                  <app-option [value]="assignment.id">
                    {{ assignment?.name }}
                  </app-option>
                }
              </app-select>
            </div>
          }
        </div>
      </div>
    </cdk-step>
    <cdk-step>
      <div class="form">
        <div class="form__control">
          <label for="priority"> Prioridad</label>
          <app-select formControlName="priority">
            @for (priority of ['Muy Alta', 'Alta', 'Media', 'Baja']; track i; let i = $index) {
              <app-option [value]="i">
                {{ priority }}
              </app-option>
            }
          </app-select>
        </div>
        <div class="form__control">
          <label for="description"> Descripción</label>
          <textarea
            class="textarea"
            formControlName="description"
            placeholder="Descripcion del ticket..."
          ></textarea>
        </div>
      </div>
    </cdk-step>
  </app-stepper>
  <div class="form__actions">
    @if (stepper.selectedIndex > 0) {
      <button class="btn btn-neutral-200" (click)="stepper.previous()">
        <i class="ri-arrow-left-line"></i>
      </button>
    }
    @if (stepper.selectedIndex < stepper.steps?.length - 1) {
      <button
        class="btn btn-info text-base-100"
        matTooltip="Siguiente"
        [disabled]="form.invalid"
        (click)="stepper.next()"
      >
        <i class="ri-arrow-right-line"></i>
      </button>
    }
    <app-button
      class="btn-primary text-base-100"
      matTooltip="Guardar"
      [block]="true"
      [disabled]="form.invalid"
      (click)="submit()"
    >
      <i class="ri-save-3-line"></i>
    </app-button>
    <app-button
      class="btn-success text-base-100"
      matTooltip="Guardar y abrir"
      [block]="true"
      [disabled]="form.invalid"
      (click)="submit(true)"
    >
      <i class="ri-save-3-line"> </i>
      <i class="ri-external-link-line"></i>
    </app-button>
  </div>
</form>
