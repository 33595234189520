import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import localforage from 'localforage';
import { Observable } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { mergeHeaders } from 'src/app/core/helpers/http.helpers';
import { environment } from 'src/environments/environment';

@Injectable()
export class HeadersInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!req.url.startsWith(environment.apiUrl)) return next.handle(req);
    return this.pre(req)
      .pipe(mergeMap((req) => next.handle(req)))
      .pipe(tap((event) => this.pos(event)));
  }

  pre(request: HttpRequest<any>) {
    return new Observable<HttpRequest<any>>((observer) => {
      localforage
        .getItem<any>('headers')
        .then((headers) => {
          if (request.headers.get('auth') === 'none') {
            headers = headers || {};
            delete headers['Authorization'];
          }

          const merged = mergeHeaders(request.headers, headers || {});
          observer.next(request.clone({ headers: merged }));
        })
        .catch((e) => {
          console.warn(`Skip merge headers: ${e}`);
          observer.next(request);
        })
        .finally(() => observer.complete());
    });
  }

  pos(event: HttpEvent<any>) {
    if (event instanceof HttpResponse && event.headers) {
      const headers =
        event.headers instanceof HttpHeaders
          ? event.headers
          : new HttpHeaders(event.headers);

      localforage
        .getItem<any>('headers')
        .then((record) => {
          headers.keys()?.forEach((k) => {
            if (k.toLowerCase().startsWith('set-'))
              record[k.toLowerCase().replace('set-', '')] = headers.get(k);
          });
          localforage.setItem('headers', record);
        })
        .catch((e) => console.warn(`Skip store headers: ${e}`));
    }
  }
}
