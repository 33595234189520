<section class="flex items-center gap-2 p-4" *ngIf="ref">
  <i class="ri-file-list-3-line text-2xl text-primary"></i>
  <h2 class="flex items-center text-primary">Instrucciones de alojamiento</h2>
  <button class="btn btn-close btn-icon ml-auto" (click)="ref?.close()">
    <i class="ri-close-line"></i>
  </button>
</section>
<form class="form p-4 !pb-8 flex-1" [formGroup]="form">
  <div class="form__control" *ngIf="actions$ | async as actions">
    <label for="actions"> Acciones </label>
    <app-tags-input formControlName="actions" [placeholder]="'Ingrese acciones aquí...'">
      <app-option *ngFor="let action of actions || []" [color]="action.color" [value]="action.id">
        {{ action.name }}
      </app-option>
    </app-tags-input>
  </div>
  <div class="form__control flex-1" *ngIf="actions$ | async as actions">
    <label for="template"> Instrucciones </label>
    <quill-editor formControlName="template" [modules]="quillModules"></quill-editor>
  </div>
  <div class="form__actions">
    <button class="btn btn-primary" type="button" [disabled]="form.invalid" (click)="save()">
      <i class="ri-save-line"></i>
      Guardar
    </button>
  </div>
</form>
