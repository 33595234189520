import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { fix } from '@core/helpers/object-helpers';
import { Form } from '@pms/models/form';
import { filter, map, mergeMap, tap } from 'rxjs';
import { UXService } from 'src/app/ux/services/ux.service';

@Injectable({ providedIn: 'root' })
export class AccommodationFormsService {
  private readonly endpoint = '//api/pms/accommodations/forms';

  constructor(private http: HttpClient, private ux: UXService) {}

  list(accommodationId: number, search?: string) {
    const params: any = fix({ accommodation_id: accommodationId, search });

    return this.http
      .get<any>(this.endpoint, { params })
      .pipe(map((items) => items.map((item: any) => new Form(item))));
  }

  create(form: Form) {
    const { id: form_id, accommodationId: accommodation_id, content } = form;

    return this.http
      .post<any>(this.endpoint, { accommodation_id, form_id, content })
      .pipe(
        tap({
          next: () => this.ux.notifier.success('¡Formulario asignado!'),
          error: () => this.ux.notifier.error('¡Error al asignar formulario!')
        })
      );
  }

  update(id: string | number, form: Form) {
    return this.http
      .put<any>(`${this.endpoint}/${id}`, { content: form.content })
      .pipe(
        tap({
          next: () => this.ux.notifier.success('¡Formulario actualizado!'),
          error: () => this.ux.notifier.error('¡Error al actualizar!')
        })
      );
  }

  store(form: Form) {
    const id = form.assignmentId;

    return id ? this.update(id, form) : this.create(form);
  }

  delete(id: string | number) {
    return this.ux.confirm(`¿Está seguro eliminar el formulario?`).pipe(
      filter((result) => !!result),
      mergeMap(() => this.http.delete(`${this.endpoint}/${id}`)),
      tap({
        next: () => this.ux.notifier.success('Formulario eliminado!'),
        error: () => this.ux.notifier.error('Error al eliminar formulario!')
      })
    );
  }
}
