import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Input,
  Optional
} from '@angular/core';

@Directive({ selector: '[magnifier]' })
export class MagnifierDirective implements AfterViewInit {
  @Optional()
  @Input('magnifier')
  zoom = 3;

  img: HTMLImageElement;
  glass: HTMLElement;

  constructor(private el: ElementRef) {
    this.img = this.el.nativeElement as HTMLImageElement;
    this.glass = document.createElement('DIV');
  }

  ngAfterViewInit(): void {
    const { src, width: w, height: h } = this.img;
    this.zoom = this.zoom || 3;

    this.glass.setAttribute('class', 'magnifier-glass');
    this.glass.style.backgroundImage = `url('${src}')`;
    this.glass.style.backgroundRepeat = 'no-repeat';
    this.glass.style.backgroundSize = `${w * this.zoom}px ${h * this.zoom}px`;
    this.glass.addEventListener('mousemove', (e) => this.move(e, 'glass'));
    this.glass.addEventListener('touchmove', (e) => this.move(e, 'glass'));
    this.img.parentElement.insertBefore(this.glass, this.img);
    this.img.parentElement.classList.add('magnifier-parent');
  }

  @HostListener('mousemove', ['$event'])
  @HostListener('touchmove', ['$event'])
  move(e: any, from: string = 'host') {
    e = e || (window.event as any);
    e.preventDefault();

    const cx = this.glass.offsetWidth / 2;
    const ch = this.glass.offsetHeight / 2;

    const rect = this.img.getBoundingClientRect();
    let x = e.clientX - rect.left;
    let y = e.clientY - rect.top;

    if (x > rect.width - cx / this.zoom) x = rect.width - cx / this.zoom;
    if (x < cx / this.zoom) x = cx / this.zoom;
    if (y > rect.height - ch / this.zoom) y = rect.height - ch / this.zoom;
    if (y < ch / this.zoom) y = ch / this.zoom;

    const left = x - cx;
    const top = y - ch;

    this.glass.style.left = `${left}px`;
    this.glass.style.top = `${top}px`;

    const xp = x * this.zoom - cx;
    const yp = y * this.zoom - ch;
    this.glass.style.backgroundPosition = `-${xp}px -${yp}px`;
  }
}
