import { APP_INITIALIZER, InjectionToken, Injector, Type } from '@angular/core';
import localforage, { INDEXEDDB, LOCALSTORAGE, WEBSQL } from 'localforage';
import packag from 'package.json';
import { Observable, concat, defer, from, of, tap } from 'rxjs';
import { OnAppInit } from './core/interfaces/init.interface';
import { AppService } from './core/services/app.service';

export const CORE_SERVICES = new InjectionToken<OnAppInit[]>('CORE_SERVICES');

// TODO: move to service
function initializeDatabase(): Observable<any> {
  const driver = [INDEXEDDB, WEBSQL, LOCALSTORAGE];
  localforage.config({ name: 'kairos', version: 1, driver });

  return from(localforage.ready()).pipe(
    tap(() => {
      if (packag.version === localStorage.getItem('app:version')) return;

      localStorage.clear();
      localforage.removeItem('cache');
      localStorage.setItem('app:version', packag.version);
    })
  );
}

export function initService(service: OnAppInit) {
  return new Observable((o) => service.ngOnAppInit().subscribe(o));
}

export function initializer(injector: Injector) {
  const services = injector.get(CORE_SERVICES);
  const app = injector.get(AppService);

  return () =>
    concat(
      initializeDatabase(),
      ...services.map(initService),
      defer(() => of(app.emit('app:inited')))
    );
}

export function provideInitializer(services: Type<OnAppInit>[]) {
  return [
    services.map((service) => ({
      provide: CORE_SERVICES,
      useExisting: service,
      multi: true
    })),
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      deps: [Injector],
      multi: true
    }
  ];
}
