import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Entity } from 'src/app/core/models/base';

export class Accommodation extends Entity<Accommodation> {
  name: string;
  city: string;
  tricks: string;
  address: string;
  active: boolean;
  pictures: string[];
  coords: any;
  distribution: any;
  provider_data: any;
  configs: any;

  meta: number;
  host_id: number;
  pack_id: number;
  channels: any;
  instructions: any;

  static formObject(data?: Partial<Accommodation>) {
    const { distribution } = data || {};
    const [address, address_detail] = (data?.address || '').split(',');

    return {
      name: [data?.name, [Validators.required]],
      city: [data?.city],
      address: [address?.trim(), [Validators.required]],
      address_detail: [address_detail?.trim()],
      coords: [data?.coords],
      tricks: [data?.tricks],
      host_id: [data?.host_id],
      meta: [data?.meta],
      pack_id: [data?.pack_id],
      channels: [data?.channels],
      distribution: new UntypedFormGroup({
        capacity: new UntypedFormControl(distribution?.capacity || 0),
        toilets: new UntypedFormControl(distribution?.toilets || 0),
        bedrooms: new UntypedFormControl(distribution?.bedrooms || 0),
        doublebeds: new UntypedFormControl(distribution?.doublebeds || 0),
        singlebeds: new UntypedFormControl(distribution?.singlebeds || 0),
        sofabeds: new UntypedFormControl(distribution?.sofabeds || 0)
      }),
      pictures: [data?.pictures]
    };
  }
}
