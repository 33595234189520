import { CalendarEvent } from 'angular-calendar';
import { parse } from 'date-fns';
import { autoTextColor } from 'src/app/core/helpers/colors';

type OmitedKeys =
  | 'title'
  | 'end'
  | 'allDay'
  | 'draggable'
  | 'resizable'
  | 'actions'
  | 'isValid'
  | 'allTags';

export class TicketEvent implements CalendarEvent {
  id?: string | number;
  start: Date;
  color?: any;
  meta?: any;
  icon: string;
  action_id: string | number;

  // CalendarEvent
  end?: Date;
  title: string = '';
  cssClass?: string = 'data-event';

  constructor(data?: Omit<TicketEvent, OmitedKeys>) {
    Object.assign(this, data);
    this.title = `Ticket #${this.id}`;
  }

  // TODO: Create ticket entity and move this method to there
  public static fromResponse(data: any): TicketEvent {
    const { date, color: hex, meta } = data;
    const color = { primary: hex, secondary: autoTextColor(hex) };
    const start = parse(date, 'yyyy-MM-dd HH:mm:ss', new Date());
    const event = { ...data, color, start, meta };

    return new TicketEvent(event);
  }
}
