import { CommonModule } from '@angular/common';
import {
  Component,
  ContentChildren,
  HostBinding,
  Input,
  OnChanges,
  QueryList,
  SimpleChanges,
  forwardRef
} from '@angular/core';
import { FormsModule, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { toBase64 } from '@core/helpers/files-helper';
import { SelectComponent } from '../../controls/select/select.component';
import { Option } from '../../directives/option.directive';
import { AppControl } from '../../models/control';
import { SanitizePipe } from '../../pipes/sanitize.pipe';
import { DateComponent } from '../date/date.component';

export type MinputType =
  | 'text'
  // | 'search'
  | 'tel'
  | 'email'
  | 'file'
  | 'number'
  | 'password'
  // | 'url'
  | 'select'
  | 'time'
  // | 'week'
  // | 'month'
  | 'textarea'
  | 'checkbox'
  | 'switch'
  | 'date'
  | 'daterange'
  | 'datetime-local';

@Component({
  standalone: true,
  selector: 'minput',
  templateUrl: './minput.component.html',
  styleUrls: ['./minput.component.scss'],
  imports: [FormsModule, CommonModule, SelectComponent, DateComponent, Option, SanitizePipe],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MinputComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MinputComponent),
      multi: true
    }
  ]
})
export class MinputComponent<T = any> extends AppControl<T> implements OnChanges {
  @HostBinding('attr.type') @Input() type?: string;
  @Input() label?: string = '';
  @Input() placeholder?: string = '';
  @Input() config: { [key: string]: any } = {};
  @ContentChildren(Option) options?: QueryList<Option> = new QueryList<Option>();

  control?: string;

  override set value(value: T) {
    super.value = value;
  }

  override get value() {
    return super.value;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['type']) {
      const map: Partial<Record<MinputType, string>> = {
        text: 'input',
        password: 'input',
        email: 'input',
        number: 'input',
        tel: 'input',
        // url: 'input',
        // search: 'input',
        date: 'date',
        file: 'file',
        select: 'select',
        time: 'input',
        textarea: 'textarea',
        checkbox: 'checkbox',
        switch: 'switch',
        daterange: 'daterange',
        'datetime-local': 'input'
      };
      this.control = map[this.type] || 'input';
    }
  }

  fileChange($event: any) {
    const files = $event.target.files;
    if (files.length > 0) {
      toBase64(files[0]).subscribe((b64: any) => (this.value = b64));
    }
  }
}
