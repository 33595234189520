import { Pipe, PipeTransform, QueryList } from '@angular/core';

@Pipe({ standalone: true, name: 'filter' })
export class FilterPipe implements PipeTransform {
  pathValue(path: string, obj: any) {
    return path.split('.').reduce((prev, curr) => prev && prev[curr], obj);
  }

  transform(items: any[] | QueryList<any>, search: string, paths?: string) {
    if (!items) return [];
    if (!search) return items;
    search = search?.toLowerCase();
    const result = items.filter((it) => {
      let str = '';
      if (typeof it === 'string') str = it || '';
      if (typeof it === 'object') {
        if (paths) {
          const values = paths.split(',').map((p) => this.pathValue(p, it));
          str = values.join('•') || '';
        } else str = Object.values(it).join('•');
      }
      return str.toLowerCase().search(search) !== -1;
    });
    return result;
  }
}
