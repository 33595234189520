import { Entity } from 'src/app/core/models/base';
import { FormControl } from './form-control';

export class Form extends Entity {
  title: string;
  controls: FormControl[];
  content?: Record<string, any>;
  accommodationId?: number | string;
  assignmentId?: number | string;

  constructor(data?: Partial<Form | any>) {
    super(data);

    this.title = data?.title;
    this.content = data?.content;
    this.controls = data?.controls || [];
    this.assignmentId = data?.assignmentId ?? data?.assignment_id;
    this.accommodationId = data?.accommodationId ?? data?.accommodation_id;
  }

  add(control?: Partial<FormControl>) {
    this.controls.push(new FormControl(control));
  }

  remove(control: FormControl) {
    this.controls = this.controls.filter((item) => item.id !== control.id);
  }
}
