import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Output
} from '@angular/core';

@Directive({ selector: '[placesAutocomplete]' })
export class PlacesAutocompleteDirective implements AfterViewInit {
  @Output() place: EventEmitter<any> = new EventEmitter();

  private element: HTMLInputElement;

  constructor(private el: ElementRef) {
    this.element = el.nativeElement;
  }

  ngAfterViewInit() {
    const { places, event } = google.maps;
    const params = {
      componentRestrictions: { country: 'es' },
      fields: ['address_components', 'formatted_address', 'geometry', 'name'],
      types: ['address']
    };

    const autocomplete = new places.Autocomplete(this.element, params);

    event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      this.place.emit(place);
    });
  }
}
