import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { filter, mergeMap } from 'rxjs';
import { UXService } from 'src/app/ux/services/ux.service';

@Injectable({
  providedIn: 'root'
})
export class TagsService {
  http = inject(HttpClient);
  ux = inject(UXService);

  add(target: any, tag: any) {
    const body = {
      record_type: target.type,
      record_id: target.id,
      tag_id: tag.id,
      tag_name: tag.name
    };
    return this.http.post(`//api/assignments`, body);
  }

  remove(assignmentId: number | string) {
    return this.ux.confirm('¿Está seguro de eliminar el tag?').pipe(
      filter((result) => !!result),
      mergeMap(() => this.http.delete(`//api/assignments/${assignmentId}`))
    );
  }
}
