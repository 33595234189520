import { Attachment } from '@/core/models/attachment';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule, DOCUMENT } from '@angular/common';
import {
  Component,
  computed,
  HostBinding,
  HostListener,
  inject,
  input,
  OnInit,
  signal,
  viewChild
} from '@angular/core';
import SwiperCore, { FreeMode, Thumbs } from 'swiper';
import { SwiperComponent, SwiperModule } from 'swiper/angular';
import defaultConfig from './swiper.config';

SwiperCore.use([FreeMode, Thumbs]);
@Component({
  standalone: true,
  selector: 'app-files-dialog',
  imports: [CommonModule, SwiperModule],
  templateUrl: './files-dialog.component.html',
  styleUrls: ['./files-dialog.component.scss']
})
export class FilesDialogComponent implements OnInit {
  document = inject(DOCUMENT);
  swiper = viewChild(SwiperComponent);

  ref = inject(DialogRef);
  data = inject(DIALOG_DATA);

  files = input<Attachment[]>(this.data?.files ?? []);
  interface = input<boolean>(true);

  ui = signal<boolean>(false);
  index = computed(() => this.swiper()?.swiperRef?.activeIndex || 0);
  current = computed(() => this.files()[this.index()]);

  config: any;
  autohideTimeout: any;

  @HostBinding('class.active')
  get isActive() {
    return this.ui();
  }

  ngOnInit() {
    this.config ??= defaultConfig;
    const selected = this.data?.selected;
    const index = this.files().findIndex((a) => a?.id === selected?.id);
    this.config.main.initialSlide = index;
  }

  open(event: any) {
    event.preventDefault();
    event.stopImmediatePropagation();
    const file = this.current();
    if (file) {
      window.open(file.src, '_blank');
    }
  }

  @HostListener('click', ['$event'])
  toggleInterface(event?: MouseEvent) {
    event?.stopImmediatePropagation();
    if (!this.interface()) {
      return;
    }

    this.ui.set(!this.ui());
    this.hideInterface();
  }

  @HostListener('window:mousemove', ['$event'])
  hideInterface() {
    clearTimeout(this.autohideTimeout);
    this.autohideTimeout = setTimeout(() => this.ui.set(false), 3000);
  }

  @HostListener('document:keydown', ['$event'])
  keyPress(event: KeyboardEvent) {
    switch (event.key) {
      case 'ArrowRight':
        this.swiper().swiperRef?.slideNext(300);
        break;
      case 'ArrowLeft':
        this.swiper().swiperRef?.slidePrev(300);
        break;
    }
  }
}
