<section
  *ngIf="form?.title && !hideHeader"
  class="flex flex-row flex-nowrap items-center justify-between"
>
  <h2 class="text-xl text-primary">{{ form.title }}</h2>
  <i *ngIf="ref" class="btn-close text-2xl ri-close-line ml-auto" (click)="ref?.close()"></i>
</section>
<section class="form flex flex-col gap-2">
  <minput
    *ngFor="let control of form?.controls ?? []"
    [type]="control.type"
    [label]="control.title"
    [placeholder]="control.placeholder"
    [(ngModel)]="form.content[control.title]"
  >
    <ng-container *ngIf="control.type === 'select'">
      <app-option *ngFor="let option of control.options ?? []" [value]="option">
        {{ option }}
      </app-option>
    </ng-container>
  </minput>
</section>
<section *ngIf="!hideFooter" class="flex items-center justify-end gap-2">
  <button class="btn btn-neutral-200" (click)="ref?.close()">
    <i class="ri-close-line"></i>
  </button>
  <button class="btn btn-success text-white" (click)="ref?.close()">
    <i class="ri-send-plane-line"></i>
  </button>
</section>
