<form (submit)="selectDates()" style="position: relative; display: block; width: 450px;">
    <mat-form-field style="width: 100%;">
        <mat-label>Rango de fechas</mat-label>
        <mat-date-range-input [formGroup]="range" [rangePicker]="picker" disabled>
          <input matStartDate placeholder="Desde" formControlName="from" formControlName="from" />
          <input matEndDate placeholder="Hasta" formControlName="to" formControlName="to" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker disabled="false"></mat-date-range-picker>
    </mat-form-field>
    <div class="text-center">
        <button type="submit" class="btn btn-info">Seleccionar</button>
    </div>
</form>