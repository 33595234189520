import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  inject
} from '@angular/core';
import { TasksService } from '@operations/services/tasks.service';
import { filter, mergeMap, Observable, tap } from 'rxjs';
import { TagsService } from 'src/app/core/services/tags.service';
import { UXService } from 'src/app/ux/services/ux.service';
import { ChecklistComponent } from '../checklist/checklist.component';
import { Attachment } from '@/core/models/attachment';

@Component({
  selector: 'app-ticket-detail',
  templateUrl: './ticket-detail.component.html',
  styleUrls: ['./ticket-detail.component.scss']
})
export class TicketDetailComponent implements AfterViewInit {
  tasks = inject(TasksService);
  http = inject(HttpClient);
  tagsService = inject(TagsService);
  ux = inject(UXService);
  ref = inject(ElementRef);

  @ViewChild('checklist', { static: false })
  checklist?: ChecklistComponent;

  @Output()
  modified: EventEmitter<void> = new EventEmitter();

  @Input()
  ticket!: any;

  @Input()
  readonly?: boolean = false;

  formated: any = {};
  tab: string = 'checklist';
  attachmentLoading = false;
  trackById = (_: number, item: any) => item.id;
  editable: boolean = false;
  task: any;

  ngAfterViewInit(): void {
    this.formated = {
      pack: this.ticket?.accommodation?.pack?.name ?? 'n/a',
      creator_id: this.ticket?.creator?.name ?? 'Automatico (Sistema)',
      localizator: this.ticket?.booking?.localizator ?? 'n/a',
      accommodation: this.ticket?.accommodation?.name ?? 'n/a',
      client: this.ticket?.booking?.client?.name ?? 'n/a',
      address: this.ticket?.accommodation?.address ?? 'n/a',
      origin: this.ticket?.booking?.origin?.name ?? 'n/a',
      web: this.ticket?.booking?.provider_data?.web ?? 'n/a',
      phone:
        this.ticket?.booking?.client?.phone ||
        this.ticket?.booking?.client?.phones?.replace(/[\[\]"]/g, '') ||
        'n/a',
      arrival_date: this.ticket?.booking?.arrival_date ?? 'n/a',
      departure_date: this.ticket?.booking?.departure_date ?? 'n/a'
    };

    const keychild = Object.keys(this.ticket?.booking?.occupants ?? {});
    this.formated.adults = this.ticket?.booking?.occupants?.AdultsNumber ?? 0;
    this.formated.childs = keychild.filter((k) => k.startsWith('Child')).length;
  }

  loadTasks(): void {
    this.tasks.list(this.ticket.id).subscribe((tasks: any) => {
      this.ticket.tasks = tasks;
    });
  }

  patchTask(task: any): void {
    for (const t of this.ticket.tasks) {
      if (t.id === task.id || (!t.id && t.task_title === task.task_title)) {
        t.task_title = task.task_title;
        t.done = task.done;
        t.id = task.id;
        t.evidence = task.evidence;
        break;
      }
    }
  }

  deleteTask(task: any): void {
    this.tasks.delete(task).subscribe(() => {
      this.loadTasks();
    });
  }

  removeTag(tag: any) {
    const { id } = tag.pivot;
    this.tagsService.remove(id).subscribe(() => {
      this.ticket.tags = this.ticket.tags.filter((t: any) => t.id !== tag.id);
    });
  }

  addAttachment(items: FileList | File[]) {
    const { id } = this.ticket;
    const body = new FormData();
    if (items instanceof FileList) items = Array.from(items);
    items.forEach((file) => body.append('files[]', file));
    this.attachmentLoading = true;
    this.http.post(`//api/tickets/${id}/observation`, body).subscribe(() => {
      this.modified.emit();
      this.attachmentLoading = false;
    });
  }

  removeAttachment(attachment: Attachment) {
    const id = attachment.id;
    const url = `//api/tickets/${this.ticket.id}/observation/${id}`;
    this.attachmentLoading = true;

    this.ux
      .confirmUpdateOrDelete()
      .pipe(
        filter((confirm) => !!confirm),
        mergeMap(() => this.http.delete(url)),
        tap(() => (this.attachmentLoading = false))
      )
      .subscribe(() => this.modified.emit());
  }

  toggleEditable() {
    this.editable = !this.editable;
  }

  filesChange(event) {
    let input: HTMLInputElement = event.target as HTMLInputElement;
    this.addAttachment(input.files);
  }

  download() {
    this.http
      .get(`//api/tickets/${this.ticket.id}/attachments`, {
        responseType: 'blob'
      })
      .subscribe((response: any) => {
        const blob = new Blob([response], { type: 'application/zip' });
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.click();
        URL.revokeObjectURL(objectUrl);
      });
  }
}
