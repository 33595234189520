import { AppService } from '@/core/services/app.service';
import { Component, Input, OnInit, Optional, inject } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { DialogRef } from '@mukhuve/ngx/dialog';
import { User } from 'src/app/core/models/user';
import { Tenant } from '../../models/tenant';

@Component({
  selector: 'app-tenant-form',
  templateUrl: './tenant-form.component.html',
  styleUrls: ['./tenant-form.component.scss']
})
export class TenantFormComponent implements OnInit {
  #app = inject(AppService);

  @Input() data?: Tenant;
  form: UntypedFormGroup;
  preview: string;
  host: User;

  get tenant() {
    return this.#app.globals.deep('tenancy.select:tenancy.tenant');
  }

  constructor(
    private fb: UntypedFormBuilder,
    @Optional() public ref?: DialogRef
  ) {}

  ngOnInit() {
    const { id, name, host = null, demo /* , parent */ } = this.data || {};
    const { logo, color, slogan } = this.data?.customization || {};

    this.host = host;
    this.form = this.fb.group({
      id: [id, [Validators.required, Validators.pattern(/^[a-z0-9-]+$/)]],
      name: [name, [Validators.required]],
      customization: this.fb.group({
        slogan: [slogan, []],
        logo: [logo, []],
        color: [color, []]
      }),
      demo: [demo, []],
      host: [host]
    });
  }

  updateImage(input: any) {
    var reader = new FileReader();
    reader.onload = () => {
      const logo = (this.preview = String(reader.result));
      this.form.patchValue({ customization: { logo } });
    };
    reader.readAsDataURL((input.files || [])[0]);
  }

  submit() {
    let values = this.form.value;
    values.parent = this.tenant.id;

    if (this.data?.id) {
      delete values.demo;
      delete values.host;
      delete values.active;
      delete values.seeded;
    }

    this.ref?.close(values);
  }
}
