import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable, OnInit, inject } from '@angular/core';
import { addSeconds } from 'date-fns';
import localforage from 'localforage';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { headersToRecord } from '../helpers/http.helpers';
import { AppService } from '../services/app.service';

@Injectable()
export class CachingInterceptor implements HttpInterceptor, OnInit {
  #app = inject(AppService);
  private values: Record<string, any>;
  private timeout: any;

  ngOnInit() {
    localforage.getItem('cache').then((cache) => (this.values = cache));
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const tenant = this.#app.globals.deep('tenancy.tenant.id');
    const userId = this.#app.globals.deep('session.user.id');

    const method = req.method.toUpperCase();
    const config = req.headers.get('app-cache');
    const url = req.urlWithParams.replace(environment.apiUrl, '');

    if (!config || method != 'GET') {
      return next.handle(req);
    }

    const defaults = [`${tenant}:${userId}:${url}`];
    const [key, timeStr, command] =
      config === 'default' ? defaults : config.split(':');
    const time = +(timeStr ?? 320);
    const value = this.validValue(key, command);
    const cachePipe = tap<any>((e) => this.save(key, e, time));

    return value ? of(value) : next.handle(req).pipe(cachePipe);
  }

  validValue(key: string, command?: string) {
    const value = this.values ? this.values[key] : null;
    const valid = value && value?.expire > Date.now() && command !== 'reload';

    return valid ? new HttpResponse(value?.response) : null;
  }

  save(key, value: HttpEvent<any>, time = 320) {
    if (value instanceof HttpResponse && value.ok) {
      if (!this.values) this.values = {};
      this.values[key] = {
        expire: +addSeconds(Date.now(), time),
        response: {
          url: value.url,
          body: value.body,
          status: value.status,
          statusText: value.statusText,
          headers: headersToRecord(value.headers)
        }
      };
      this.commit();
    }
  }

  commit() {
    if (!this.values) return;

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      localforage.setItem('cache', this.values).then();
    }, 2000);
  }
}
