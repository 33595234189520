<div class="resume">
  <div class="image">
    <img
      class="rounded-full w-full h-full object-cover"
      [src]="auth.user?.image"
      onerror="this.src='/assets/images/default-profile.jpg';this.onerror=null;"
    />
  </div>
  <div class="resume__info">
    <h3>{{ auth.user?.fullname }}</h3>
    <span>{{ auth.user?.role?.name }}</span>
  </div>
  <div class="actions">
    <button class="color-error rounded" (click)="changePassword()">
      <i class="ri-lock-password-line"></i>
    </button>
    <button class="color-info rounded" (click)="edit()">
      <i class="ri-pencil-line"></i>
    </button>
  </div>
</div>
<div class="divider"></div>
<div class="info overflow-y-auto">
  <div class="field">
    <i class="field__icon ri-mail-line"></i>
    <b class="field__title">Email</b>
    <span class="field__value">{{ auth?.user?.email || '-' }}</span>
  </div>
  <div class="field">
    <i class="field__icon ri-mail-line"></i>
    <b class="field__title">Nombre</b>
    <span class="field__value">{{ auth?.user?.name || '-' }}</span>
  </div>
  <div class="field">
    <i class="field__icon ri-mail-line"></i>
    <b class="field__title">Apellido</b>
    <span class="field__value">{{ auth?.user?.surname || '-' }}</span>
  </div>
  <div class="field">
    <i class="field__icon ri-profile-line"></i>
    <b class="field__title">DNI</b>
    <span class="field__value">{{ auth?.user?.dni || '-' }}</span>
  </div>
  <div class="field">
    <i class="field__icon ri-phone-line"></i>
    <b class="field__title">Telefono</b>
    <span class="field__value">{{ auth?.user?.phone || '-' }}</span>
  </div>
</div>
<div class="divider"></div>
